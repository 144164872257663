import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DatoValorCalculadoraService extends BaseService<any, any, any, any> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'dato-valor-calculadora';
	}

	getResumenCostos(anioId): Observable<any[]> {
		let params = new HttpParams();

		params = params.append("anioId", anioId);

		return this.http.get<any[]>(`${this.getUrl()}/resumen-costos`, { params: params, headers: this.getHeader() });
	}

	getResumenPreciosCombustibles(anioId, mesId): Observable<any[]> {
		let params = new HttpParams();

		params = params.append("anioId", anioId);
		params = params.append("mesId", mesId);

		return this.http.get<any[]>(`${this.getUrl()}/resumen-precios-combustible`, { params: params, headers: this.getHeader() });
	}

	update(tipoDatoCalculadoraId, regionalId, anioId, mesId, valor): Observable<any> {
		let data = {
			regionalId: regionalId,
			anioId: anioId,
			mesId: mesId,
			tipoDatoCalculadoraId: tipoDatoCalculadoraId,
			valor: valor
		}

		return this.http.put<any>(`${this.getUrl()}/update`, data, { headers: this.getHeader() });
	}
}
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataDeposit } from '../model/match/metadata-deposit';
import { MetadataResumenInventoryMovement } from '../model/match/metadata-resumen-inventory-movement';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '../../shared/components/search/search.component';
import { Deposit } from '../model/dto/deposit';
import { ResumenDeposit } from '../model/dto/resumen/resumen-deposit';
import { MetadataResumenDeposit } from '../model/match/metadata-resumen-deposit';

@Injectable({
	providedIn: 'root'
})
export class DepositService extends BaseService<Deposit, ResumenDeposit, MetadataDeposit, MetadataResumenDeposit> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'deposit';
	}
	
	getResumenDeposit(): Observable<Deposit[]> {
		return this.http.get<Deposit[]>(`${this.getUrl()}/resumen-all-my-deposits`, {headers: this.getHeader()});
	}

	/*getMetadataResumenListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen`, { params: search.params, headers: this.getHeader() });
	}*/
}

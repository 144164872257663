import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { CondicionPago } from '../model/dto/condicion-pago';
import { MetadataCondicionPago } from '../model/match/metadata-condicion-pago';

@Injectable({
	providedIn: 'root'
})
export class CondicionPagoService extends BaseService<CondicionPago, CondicionPago, MetadataCondicionPago, MetadataCondicionPago> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'condicion-pago';
	}
}

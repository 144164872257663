import { Component } from '@angular/core';
import { UserService } from './api/rest/user.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { HttpErrorResponse } from '@angular/common/http';
import { InterfaceService } from './api/rest/interface.service';

declare var require;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  try_ = 0;

  googleMapAPiKey: string;

  constructor(private userService: UserService,
    private ngxPermissionsService: NgxPermissionsService,
    private interfaceService: InterfaceService) {

    this.interfaceService.getGoogleMapApiKey().subscribe((data) => {
      this.googleMapAPiKey = "https://maps.googleapis.com/maps/api/js?key=" + data.paramValue + "&libraries=places";

      this.loadScript();
    });

    if (localStorage.getItem('isLoggedin')) {
      if (localStorage.getItem('permissions') == null || localStorage.getItem('permissions') == undefined) {
        localStorage.setItem('permissions', "");
      }

      this.ngxPermissionsService.loadPermissions(localStorage.getItem('permissions').split(","));

      this.userService.getPermissions().subscribe((data: any) => {

        if (data.permissions != null && data.permissions != undefined && data.permissions != '') {
          this.ngxPermissionsService.loadPermissions(data.permissions.split(","));
        }

      }, (httpErrorResponse: HttpErrorResponse) => {

      });

      setInterval(function () {
        if (localStorage.getItem('isLoggedin')) {
          if (
            require('moment')(new Date(localStorage.getItem('refreshBefore'))).subtract(3, 'minutes').toDate()
            <
            new Date()
          ) {
            userService.refreshToken().subscribe((data: any) => {
              localStorage.setItem('eTag', data.token);
              localStorage.setItem('refreshToken', data.refreshToken);
              localStorage.setItem('refreshBefore', data.refreshBefore);
              localStorage.setItem('tokenReporte', data.tokenReporte);

              this.try_ = 0;
            }, (httpErrorResponse: HttpErrorResponse) => {
              if (httpErrorResponse.status == 401) {
                localStorage.clear();

                localStorage.setItem("token_expired", "true");

                location.href = "/";

                return;
              }

              this.try_++;

              if (this.try_ == 3) {
                this.try_ = 0;

                localStorage.clear();

                localStorage.setItem("token_expired", "true");

                location.href = "/";

                return;
              }
            });
          }

        }
      }, 30000);
    }
  }

  loadScript() {
    let node = document.createElement('script');

    node.src = this.googleMapAPiKey;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';

    document.getElementsByTagName('head')[0].appendChild(node);
  }
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AparatoAConectar } from '../model/dto/aparato-a-conectar';
import { MetadataAparatoAConectar } from '../model/match/metadata-aparato-a-conectar';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})

export class AparatoAConectarService extends BaseService<AparatoAConectar, AparatoAConectar, MetadataAparatoAConectar, MetadataAparatoAConectar> {
	
	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'aparato-a-conectar';
	}
}
export const environment = {

  production: true,

  API_BASE_URL: 'https://pentest.uatfenix.tutenlabs.dev/TutenREST/rest/',
  API_BASE_URL_REPORTS: 'https://reportesuatfenix.tutenlabs.dev/FenixReportsREST/rest/',
  SOCKET_BASE_URL: 'https://pentest.uatfenix.tutenlabs.dev',

  // API_BASE_URL: 'http://localhost:8080/TutenREST/rest/',
  /*API_BASE_URL_REPORTS: 'https://reportesuatfenix.tutenlabs.dev/FenixReportsREST/rest/',
  SOCKET_BASE_URL: 'http://localhost:8080',*/
  

  MATERIAL_OFERTA_COMERCIAL_COMERCIAL: '8039',
  MATERIAL_OFERTA_RESIDENCIAL_RESIDENCIAL: '8038',
};

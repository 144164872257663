import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { DireccionCliente } from '../model/dto/direccion-cliente';
import { MetadataDireccionCliente } from '../model/match/metadata-direccion-cliente';
import { MetadataInstaladora } from '../model/match/metadata-instaladora';
import { Observable } from 'rxjs';
import { MetadataVendedor } from '../model/match/metadata-vendedor';
import { Search } from '../../shared/components/search/search.component';
import { Instaladora } from '../model/dto/instaladora';


@Injectable({
	providedIn: 'root'
})
export class DireccionClienteService extends BaseService<DireccionCliente, DireccionCliente, MetadataDireccionCliente, MetadataDireccionCliente> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'direccion-cliente';
	}

	getDireccionCliente(direccionId: number): Observable<DireccionCliente> {
		return this.http.get<DireccionCliente>(`${this.getUrl()}/${direccionId}/`, { headers: this.getHeader() });
	}

	getInstaladorasRelated(direccionId: number): Observable<Instaladora[]> {
		return this.http.get<Instaladora[]>(`${this.getUrl()}/${direccionId}/instaladoras-related`, { headers: this.getHeader() });
	}

	getInstaladorasRelatedLessInstaladora(direccionId: number, serviceId: number): Observable<Instaladora[]> {
		return this.http.get<Instaladora[]>(`${this.getUrl()}/${direccionId}/instaladoras-related/less-instaladora/${serviceId}`, { headers: this.getHeader() });
	}
}
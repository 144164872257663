import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class AuthLoginGuard implements CanActivate {
	constructor(private router: Router) {}

	canActivate() {
		if (localStorage.getItem('isLoggedin') == null || localStorage.getItem('isLoggedin') == undefined || localStorage.getItem('isLoggedin') == "false") {
			return true;
		}

		this.router.navigate(['office/home/dashboard']);
		return false;
	}
}

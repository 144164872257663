import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { Prioridad } from '../model/dto/prioridad';
import { MetadataPrioridad } from '../model/match/metadata-prioridad';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class PrioridadService extends BaseService<Prioridad, Prioridad, MetadataPrioridad, MetadataPrioridad> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'prioridad';
	}
	getPrioridad(): Observable<Prioridad> {
		return this.http.get<Prioridad>(`${this.getUrl()}`, { headers: this.getHeader() });
	}
}

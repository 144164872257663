import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InternetConnectionService {

  connected: boolean;

  constructor() {
    this.connected = navigator.onLine;
  }
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { TipoPES } from '../model/dto/tipo-PES';
import { MetadataTipoPES } from '../model/match/metadata-tipo-pes';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class TipoPESService extends BaseService<TipoPES, TipoPES, MetadataTipoPES, MetadataTipoPES> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tipo-pes';
	}
}
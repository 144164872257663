import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Giro } from '../model/dto/giro';
import { MetadataGiro } from '../model/match/metadata-giro';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class GiroService extends BaseService<Giro, Giro, MetadataGiro, MetadataGiro> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'giro';
	}

}

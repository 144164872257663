import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from './base.service';
import { MetadataRegionales } from '../model/match/metadata-regionales';
import { MetadataResumenRegional } from '../model/match/metadata-resumen-regional';
import { MatchCreateRegionales } from '../model/match/match-create-regionales';
import { Observable } from 'rxjs';
import { MatchUpdateRegionales } from '../model/match/match-update-regionales';
import { Regionales } from '../model/dto/regionales';
import { Municipio } from '../model/dto/municipio';
import { Instaladora } from '../model/dto/instaladora';
import { ResumenReportMatenedoresTabla1 } from '../model/dto/resumen/resumen-report-mantenedores-tabla1';
import { Search } from '../../shared/components/search/search.component';
import { MetadataResumenInstaladoraToPersistZona } from '../model/match/metadata-resumen-instaladora-to-persist-zona';
import { Zona } from '../model/dto/zona';
import {MatchCreateObjetivoRegional} from '../model/match/match-create-objetivo-regional';

@Injectable({
	providedIn: 'root'
})
export class RegionalService extends BaseService<Regionales, Regionales, MetadataRegionales, MetadataRegionales> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'regional';
	}

	create(matchCreateRegionales: MatchCreateRegionales): Observable<Regionales> {

		return this.http.post<Regionales>(`${this.getUrl()}/`, matchCreateRegionales, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateRegionales: MatchUpdateRegionales): Observable<Regionales> {

		return this.http.put<Regionales>(`${this.getUrl()}/${id}`, matchUpdateRegionales, { headers: this.getHeader() });
	}

	delete(id: number): Observable<Regionales> {

		return this.http.delete<Regionales>(`${this.getUrl()}/${id}`, { headers: this.getHeader() });
	}

	getListFullData(page: number = -1, limit: number = -1): Observable<MetadataResumenRegional> {

		return this.http.get<MetadataResumenRegional>(`${this.getUrl()}/resumen?page=${page}&max=${limit}`, { headers: this.getHeader() });
	}

	getRegionalesByID(id: number): Observable<Regionales[]> {
		return this.http.get<Regionales[]>(`${this.getUrl()}/${id}`, { headers: this.getHeader() });
	}

	getMunicipios(id: number): Observable<Municipio[]> {
		return this.http.get<Municipio[]>(`${this.getUrl()}/${id}/municipios`, { headers: this.getHeader() });
	}

	getInstaladorasByRegional(id: number): Observable<Regionales[]> {
		return this.http.get<Regionales[]>(`${this.getUrl()}/${id}/intaladora`, { headers: this.getHeader() });
	}

	getInstaladoras(id: number): Observable<Instaladora[]> {
		return this.http.get<Instaladora[]>(`${this.getUrl()}/${id}/instaladoras`, { headers: this.getHeader() });
	}

	getInstaladorasByRegionales(regionalesId: number[]): Observable<Instaladora[]> {

		return this.http.post<Instaladora[]>(`${this.getUrl()}/instaladoras-by-regionales`, {
			regionalesId
		}, { headers: this.getHeader() });
	}

	getRegionalesAvailableToAssign(regionalId: number = null): Observable<Regionales[]> {

		let urlAdm = "";
		let preAdm = "";

		if (regionalId != null) {
			urlAdm = "/" + regionalId;
		}

		if (urlAdm != "") {
			preAdm = "/admin";
		}

		return this.http.get<Regionales[]>(`${this.getUrl()}${preAdm}/availables-to-assign-gas-meter${urlAdm}`, { headers: this.getHeader() });
	}

		//********************************************* */REPORTE 9: MANTENEDORES*****************************************************

	//TABLA 1: ESTRUCTURA REGIONAL
	getReportMantenedoresTabla1(regionalesId: string, estadoId: string, municipioId: string,
		zipCodeId: string,coloniaId: string, streetId: string): Observable<ResumenReportMatenedoresTabla1[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("estadoId", estadoId);
		params = params.append("municipioId", municipioId);
		params = params.append("zipCodeId", zipCodeId);
		params = params.append("coloniaId", coloniaId);
		params = params.append("streetId", streetId);

		return this.http.get<ResumenReportMatenedoresTabla1[]>(`${this.getUrlReports()}/reporte-mantenedores-tabla1`, { params: params, headers: this.getHeader() });
	}

	getMetadataInstaladorasPersistZona(regionalId: number, instaladorasIdToExclude: string, search: Search): Observable <MetadataResumenInstaladoraToPersistZona>{
		return this.http.get<MetadataResumenInstaladoraToPersistZona>(`${this.getUrl()}/${regionalId}/instaladoras-resumen-to-persist-zona/exclude/${instaladorasIdToExclude}`, {params: search.params, headers: this.getHeader()});
	}

	getListToOptions(): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader() });
	}

	getInstaladoraListToOptions(id: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${id}/instaladoras/list-to-options`, { headers: this.getHeader() });
	}

	getZonasListToOptions(id: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${id}/zonas/list-to-options`, { headers: this.getHeader() });
	}

	getMunicipiosListToOptions(id: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${id}/municipios/list-to-options`, { headers: this.getHeader() });
	}

	getZonasParaDibujar(id: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${id}/zonas-para-dibujar`, { headers: this.getHeader() });
	}

	getMetadataProyectosCrearZona(regionalId: number, search: Search): Observable <any> {
		return this.http.get<any>(`${this.getUrl()}/${regionalId}/crear-zona/proyectos`, {params: search.params, headers: this.getHeader()});
	}

	getSupervisoresVentasListToOptions(regionalId: number): Observable <any> {
		return this.http.get<any>(`${this.getUrl()}/${regionalId}/supervisores-ventas/list-to-options`, { headers: this.getHeader()});
	}

  getSupervisoresVentasListToOptionsByUser(userlId: number): Observable <any> {
		return this.http.get<any>(`${this.getUrl()}/${userlId}/supervisores-ventas--by-user/list-to-options`, { headers: this.getHeader()});
	}

	getElementosPepListToOptions(regionalId: number): Observable <any> {
		return this.http.get<any>(`${this.getUrl()}/${regionalId}/elementos-pep/list-to-options`, { headers: this.getHeader()});
	}

	getRegionalProyectosListToOptions(regionalId: number): Observable <any> {
		return this.http.get<any>(`${this.getUrl()}/${regionalId}/proyectos/list-to-options`, { headers: this.getHeader()});
	}

	getRegionalProyectosReporteListToOptions(regionalId: number): Observable <any> {
		return this.http.get<any>(`${this.getUrl()}/${regionalId}/proyectos/list-to-options/reporte`, { headers: this.getHeader()});
	}

	getElementosPeps(regionalId): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${regionalId}/elementos_pep`, { headers: this.getHeader() });
	}

	getEstadoAsociadoGeocoding(id, extra): Observable<any> {
		let params = new HttpParams();

		if (extra) {
			params = params.append("extra", extra);
		}

		return this.http.get<any>(`${this.getUrl()}/${id}/estado-asociado-geocoding`, { params: params, headers: this.getHeader() });
	}

	getFindListToObjectives(id: number, search: Search): Observable<any>{
    return this.http.get(`${this.getUrl()}/list-to-objectives/${id}`, {params: search.params, headers: this.getHeader()});
  }

  createObjective(body: MatchCreateObjetivoRegional): Observable<any> {
	  return this.http.post(`${this.getUrl()}/objetivo-regional`, body, {headers: this.getHeader()})
  }

  updateObjetiveRegional(id: number, body: MatchCreateObjetivoRegional): Observable<any> {
    return this.http.put(`${this.getUrl()}/objetivo-regional/${id}`, body, {headers: this.getHeader()})
  }

  deleteObjetivoRegional(id: number): Observable<any> {
    return this.http.delete(`${this.getUrl()}/objetivo-regional/${id}`, {headers: this.getHeader()})
  }

  findObjetivoRegional(): Observable<any> {
	  return this.http.get(`${this.getUrl()}/regional-objetivos`, {headers: this.getHeader()})
  }

}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataInventoryMovement } from '../model/match/metadata-inventory-movement';
import { MetadataResumenInventoryMovement } from '../model/match/metadata-resumen-inventory-movement';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResumenService } from '../model/dto/resumen/resumen-service';
import { MetadataResumenService } from '../model/match/metadata-resumen-service';
import { Search } from '../../shared/components/search/search.component';
import { InventoryMovement } from '../model/dto/inventory-movement';
import { MatchAssignGasMeterRegionalToRegional } from '../model/match/match-assign-gas-meter-regional-to-regional';
import { MatchAssignGasMeterRegionalToInstaladora } from '../model/match/match-assign-gas-meter-regional-to-instaladora';
import { GasMeter } from '../model/dto/gas-meter';
import { MatchAssignGasMeterInstaladoraToRegional } from '../model/match/match-assign-gas-meter-instaladora-to-regional';
import { ResumenInventoryMovement } from '../model/dto/resumen/resumen-inventory-movement';
import { MetadataResumenGasMeter } from '../model/match/metadata-resumen-gas-meter';
import { MatchProcessInventoryMovement } from '../model/match/match-process-inventory-movement';
import { MatchGasMeterCambioBodega } from '../model/match/match-gas-meter-cambio-bogeda';
import { MatcgGasMeterCambioStatus } from '../model/match/match-gas-meter-cambio-status';

@Injectable({
	providedIn: 'root'
})
export class InventoryMovementService extends BaseService<InventoryMovement, ResumenInventoryMovement, MetadataInventoryMovement, MetadataResumenInventoryMovement> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'inventory-movement';
	}

	assignGasMeterRegionalToRegional(matchAssignGasMeterRegionalToRegional: MatchAssignGasMeterRegionalToRegional): Observable<any[]> {

		let preAdm = "";

		if(matchAssignGasMeterRegionalToRegional.adminRegionalId != null && matchAssignGasMeterRegionalToRegional.adminRegionalId != undefined) {
			preAdm = "/admin";
		}

		return this.http.post<any[]>(`${this.getUrl()}${preAdm}/assign-gas-meter-regional-to-regional`, matchAssignGasMeterRegionalToRegional, { headers: this.getHeader() });
	}

	assignGasMeterRegionalToInstaladora(matchAssignGasMeterRegionalToInstaladora: MatchAssignGasMeterRegionalToInstaladora): Observable<any[]> {

		let preAdm = "";

		if(matchAssignGasMeterRegionalToInstaladora.adminRegionalId != null && matchAssignGasMeterRegionalToInstaladora.adminRegionalId != undefined) {
			preAdm = "/admin";
		}

		return this.http.post<any[]>(`${this.getUrl()}${preAdm}/assign-gas-meter-regional-to-instaladora`, matchAssignGasMeterRegionalToInstaladora, { headers: this.getHeader() });
	}

	assignGasMeterInstaladoraToRegional(matchAssignGasMeterInstaladoraToRegional: MatchAssignGasMeterInstaladoraToRegional): Observable<any[]> {

		let preAdm = "";

		if(matchAssignGasMeterInstaladoraToRegional.adminRegionalId != null && matchAssignGasMeterInstaladoraToRegional.adminRegionalId != undefined) {
			preAdm = "/admin";
		}
		return this.http.post<any[]>(`${this.getUrl()}${preAdm}/assign-gas-meter-instaladora-to-regional`, matchAssignGasMeterInstaladoraToRegional, { headers: this.getHeader() });
	}

	getMetadataResumenBandejaListSearch(search: Search): Observable<MetadataResumenInventoryMovement> {
		return this.http.get<MetadataResumenInventoryMovement>(`${this.getUrl()}/bandeja/resumen`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataResumenBusquedaListSearch(search: Search): Observable<MetadataResumenInventoryMovement> {
		return this.http.get<MetadataResumenInventoryMovement>(`${this.getUrl()}/busqueda/resumen`, { params: search.params, headers: this.getHeader() });
	}

	aprobarMedidores(id: number, matchProcessInventoryMovement: MatchProcessInventoryMovement): Observable<InventoryMovement> {
		return this.http.post<InventoryMovement>(`${this.getUrl()}/${id}/approve-gas-meter`, matchProcessInventoryMovement, { headers: this.getHeader() });
	}

	rechazarMedidores(id: number, matchProcessInventoryMovement: MatchProcessInventoryMovement): Observable<InventoryMovement> {
		return this.http.post<InventoryMovement>(`${this.getUrl()}/${id}/reject-gas-meter`, matchProcessInventoryMovement, { headers: this.getHeader() });
	}

	getDetailInventoryMovement(inventoryMovementId: number): Observable<ResumenInventoryMovement> {
		return this.http.get<ResumenInventoryMovement>(`${this.getUrl()}/${inventoryMovementId}/resumen-to-detail`, { headers: this.getHeader() });
	}

	getMetadataResumenGasMeters(inventoryMovementId: number, search: Search): Observable<MetadataResumenGasMeter> {
		return this.http.get<MetadataResumenGasMeter>(`${this.getUrl()}/${inventoryMovementId}/gas-meters`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataResumenGasMetersToApprove(inventoryMovementId: number): Observable<MetadataResumenGasMeter> {
		return this.http.get<MetadataResumenGasMeter>(`${this.getUrl()}/${inventoryMovementId}/gas-meters-to-approve`, { headers: this.getHeader() });
	}

	cambiarBodega(matchGasMeterCambioBodega: MatchGasMeterCambioBodega): Observable<any[]> {
		return this.http.post<any[]>(`${this.getUrl()}/admin/cambiar-bodega`, matchGasMeterCambioBodega, { headers: this.getHeader() });
	}

  cambiarStatus(matchGasMeterCambioStatus: MatcgGasMeterCambioStatus): Observable<any[]> {
		return this.http.post<any[]>(`${this.getUrl()}/admin/cambio-estatus`, matchGasMeterCambioStatus, { headers: this.getHeader() });
	}
}

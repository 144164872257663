import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CostoInstalacion } from '../model/dto/costo-instalacion';
import { MatchUpdateCostoInstalacion } from '../model/match/match-update-costo-instalacion';
import { MetadataCostoInstalacion } from '../model/match/metadata-costo-instalacion';

@Injectable({
  providedIn: 'root'
})
export class CostoInstalacionService extends BaseService<CostoInstalacion, CostoInstalacion, MetadataCostoInstalacion, MetadataCostoInstalacion> {

  constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'costo-instalacion';
  }
  
  update(id: number, matchUpdateCostoInstalacion: MatchUpdateCostoInstalacion): Observable<CostoInstalacion> {
	return this.http.put<CostoInstalacion>(`${this.getUrl()}/${id}`, matchUpdateCostoInstalacion, {headers: this.getHeader()});
  }
  getListFullData(): Observable<MetadataCostoInstalacion> {
	return this.http.get<MetadataCostoInstalacion>(`${this.getUrl()}`, {headers: this.getHeader()});
	}
}

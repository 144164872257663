import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { DepositType } from '../model/dto/deposit-type';
import { MetadataPrioridad } from '../model/match/metadata-prioridad';
import { HttpClient } from '@angular/common/http';
import { MetadataDepositType } from '../model/match/metadata-deposit-type';

@Injectable({
	providedIn: 'root'
})
export class DepositTypeService extends BaseService<DepositType, DepositType, MetadataDepositType, MetadataDepositType> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'deposit-type';
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataPresupuesto } from '../model/match/metadata-presupuesto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Presupuesto } from '../model/dto/presupuesto';
import { Observable } from 'rxjs';
import { ResumenPresupuesto } from '../model/dto/resumen/resumen-presupuesto';
import { MetadataResumenPresupuesto } from '../model/match/metadata-resumen-presupuesto';
import { MatchCreatePresupuesto } from '../model/match/match-create-presupuesto';
import { Search } from '../../shared/components/search/search.component';
import { MatchBusquedaPresupuesto } from '../model/match/match-busqueda-presupuesto';
import { ResumenReportOfertasComercialesTabla5 } from '../model/dto/resumen/resumen-report-ofertas-comerciales-tabla5';

@Injectable({
	providedIn: 'root'
})
export class PresupuestoService extends BaseService<Presupuesto, ResumenPresupuesto, MetadataPresupuesto, MetadataResumenPresupuesto> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'presupuesto';
	}

	create(matchCreatePresupuesto: MatchCreatePresupuesto) {
		return this.http.post<Presupuesto>(`${this.getUrl()}`, matchCreatePresupuesto, { headers: this.getHeader() });
	}

	update(matchCreatePresupuesto: MatchCreatePresupuesto, idPresupuesto: number) {
		return this.http.put<Presupuesto>(`${this.getUrl()}/update/${idPresupuesto}`, matchCreatePresupuesto, { headers: this.getHeader() });
	}

	delete(idPresupuesto: number): Observable<Presupuesto> {
		return this.http.put<Presupuesto>(`${this.getUrl()}/delete/${idPresupuesto}`, null, { headers: this.getHeader() });
	}

	getPresupuestos() {
		return this.http.get<Presupuesto>(`${this.getUrl()}`, { headers: this.getHeader() });
	}

	getTipoInmuebleById(idPresupuesto: number) {
		return this.http.get(`${this.getUrl()}/${idPresupuesto}/inmueble`, { headers: this.getHeader() });
	}

	getFormaPagoById(idPresupuesto: number) {
		return this.http.get(`${this.getUrl()}/${idPresupuesto}/forma-pago`, { headers: this.getHeader() });
	}

	getTamanoComercioById(idPresupuesto: number) {
		return this.http.get(`${this.getUrl()}/${idPresupuesto}/comercio`, { headers: this.getHeader() });
	}

	getInstaladorasById(idPresupuesto: number) {
		return this.http.get(`${this.getUrl()}/${idPresupuesto}/instaladora`, { headers: this.getHeader() });
	}

	getTiposPESById(idPresupuesto: number) {
		return this.http.get(`${this.getUrl()}/${idPresupuesto}/tipo-pes`, { headers: this.getHeader() });
	}

	getPresupuestoToContrato(matchBusquedaPresupuesto: MatchBusquedaPresupuesto): Observable<Presupuesto[]> {
		return this.http.post<Presupuesto[]>(`${this.getUrl()}/presupuesto-to-contrato`, matchBusquedaPresupuesto, { headers: this.getHeader() });
	}

	//********************************************** */REPORTE 4: OFERTAS COMERCIALES*******************************************
	
	//TABLA 5: PRESUPUESTO
	getReportOfertasComercialesTabla5(regionalesId: string, instaladorasId: string, nombrePresupuesto: string, 
		descripcionPresupuesto: string, statusId: string, susceptibleAPromocion: number, 
		tipoClienteId: string,tipoPESId: string, tipoInmuebleId: string, naceConGas: number,
		instalacionConGarantia: number, pagoAtravesDeId: string, formaDePagoId: string,
		 tipoDeFinanciamientoId: string, importePresupuesto: string,nroMaterial: string): Observable<ResumenReportOfertasComercialesTabla5[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("nombrePresupuesto", nombrePresupuesto);
		params = params.append("descripcionPresupuesto", descripcionPresupuesto);
		params = params.append("statusId", statusId);
		params = params.append("susceptibleAPromocion", susceptibleAPromocion + "");
		params = params.append("tipoClienteId", tipoClienteId);
		params = params.append("tipoPESId", tipoPESId);
		params = params.append("tipoInmuebleId", tipoInmuebleId);
		params = params.append("naceConGas", naceConGas + "");
		params = params.append("instalacionConGarantia", instalacionConGarantia + "");
		params = params.append("pagoAtravesDeId", pagoAtravesDeId);
		params = params.append("formaDePagoId", formaDePagoId);
		params = params.append("tipoDeFinanciamientoId", tipoDeFinanciamientoId);
		params = params.append("importePresupuesto", importePresupuesto);
		params = params.append("nroMaterial", nroMaterial);
	
		return this.http.get<ResumenReportOfertasComercialesTabla5[]>(`${this.getUrlReports()}/reporte-ofertas-comerciales-tabla5`, { params: params, headers: this.getHeader() });
	}
}

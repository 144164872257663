import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProveedorMedidor } from '../model/dto/proveedor-medidor';
import { MetadataProveedorMedidor } from '../model/match/metadata-proveedor-medidor';
import { Observable } from 'rxjs';
import { MatchCreateProveedor } from '../model/match/match-create-proveedor';
import { ResumenReportMantenedoresTabla4 } from '../model/dto/resumen/resumen-report-mantenedores-tabla4';

@Injectable({
	providedIn: 'root'
})
export class ProveedorMedidorService extends BaseService<ProveedorMedidor, ProveedorMedidor, MetadataProveedorMedidor, MetadataProveedorMedidor> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'gas-meter-provider';
	}

	create(MatchCreateProveedor: MatchCreateProveedor): Observable<ProveedorMedidor> {
		return this.http.post<ProveedorMedidor>(this.getUrl(), MatchCreateProveedor, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateProveedor: MatchCreateProveedor): Observable<ProveedorMedidor> {
		return this.http.put<ProveedorMedidor>(`${this.getUrl()}/${id}/update`, matchUpdateProveedor, { headers: this.getHeader() });
	}

	//******************************************************* */REPORTE 9: MANTENEDORES***************************************************

	//TABLA 4: FABRICANTES
	getReportMantenedoresTabla4(fabricanteId: string,
		codigo: string, statusGeneralId: string): Observable<ResumenReportMantenedoresTabla4[]> {

		let params = new HttpParams();

		params = params.append("fabricanteId", fabricanteId);
		params = params.append("codigo", codigo);
		params = params.append("statusGeneralId", statusGeneralId);

		return this.http.get<ResumenReportMantenedoresTabla4[]>(`${this.getUrlReports()}/reporte-mantenedores-tabla4`, { params: params, headers: this.getHeader() });
	}

}

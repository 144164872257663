import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { TipoFinanciamiento } from '../model/dto/tipo-financiamiento';
import { MetadataTipoFinanciamiento } from '../model/match/metadata-tipo-financiamiento';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatchCreateTipoFinanciamiento } from '../model/match/match-create-tipo-financiamiento';
import { ResumenReportOfertasComercialesTabla2 } from '../model/dto/resumen/resumen-report-ofertas-comerciales-tabla2';

@Injectable({
	providedIn: 'root'
})
export class TipoFinanciamientoService extends BaseService<TipoFinanciamiento, TipoFinanciamiento, MetadataTipoFinanciamiento, MetadataTipoFinanciamiento> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tipo-financiamiento';
	}

	getTipoDeFinanciamiento(): Observable<TipoFinanciamiento> {
		return this.http.get<TipoFinanciamiento>(`${this.getUrl()}`, { headers: this.getHeader() });
	}

	create(matchCreateTipoFinanciamiento: MatchCreateTipoFinanciamiento): Observable<TipoFinanciamiento> {
		return this.http.post<TipoFinanciamiento>(`${this.getUrl()}`, matchCreateTipoFinanciamiento, { headers: this.getHeader() });
	}

	update(matchCreateTipoFinanciamiento: MatchCreateTipoFinanciamiento, idTipoFinanciamiento: number): Observable<TipoFinanciamiento> {
		return this.http.put<TipoFinanciamiento>(`${this.getUrl()}/update/${idTipoFinanciamiento}`, matchCreateTipoFinanciamiento, { headers: this.getHeader() });
	}

	delete(idTipoFinanciamiento: number): Observable<TipoFinanciamiento> {
		return this.http.put<TipoFinanciamiento>(`${this.getUrl()}/delete/${idTipoFinanciamiento}`, null, { headers: this.getHeader() });
	}

	//********************************************** */REPORTE 4: OFERTAS COMERCIALES*******************************************
	
	//TABLA 2: TIPO DE FINANCIAMIENTO
	getReportOfertasComercialesTabla2( statusId: string): Observable<ResumenReportOfertasComercialesTabla2[]> {

		let params = new HttpParams();

		params = params.append("statusId", statusId);
		
		params = params.append("minutes", new Date().getTimezoneOffset() + "");

		return this.http.get<ResumenReportOfertasComercialesTabla2[]>(`${this.getUrlReports()}/reporte-ofertas-comerciales-tabla2`, { params: params, headers: this.getHeader() });
	}
}

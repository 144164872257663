import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataReasonUnfeasibilityServiceType } from '../model/match/metadata-reason-unfeasibility-service-type';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MetadataRadix } from '../model/match/metadata-radix';
import { ReasonUnfeasibilityServiceType } from '../model/dto/reason-unfeasibility-service-type';

@Injectable({
	providedIn: 'root'
})
export class ReasonUnfeasibilityServiceTypeService extends BaseService<ReasonUnfeasibilityServiceType, ReasonUnfeasibilityServiceType, MetadataReasonUnfeasibilityServiceType, MetadataReasonUnfeasibilityServiceType> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'reason-unfeasibility-service-type';
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { MatchCreateUsuario } from '../model/match/match-create-usuario';
import { User } from '../model/dto/user';
import { Login } from '../model/match/login';
import { Observable } from 'rxjs';
import { ResumenUser } from '../model/dto/resumen/resumen-user';
import { MetadataUser } from '../model/match/metadata-user';
import { MetadataResumenUser } from '../model/match/metadata-resumen-user';
import { MatchRoleSubdomain } from '../model/match/match-role-subdomain';
import { MatchUpdateUsuario } from '../model/match/match-update-usuario';
import { Search } from '../../shared/components/search/search.component';
import { MetadataResumenRelations } from '../model/match/metadata-resumen-relations';
import { MatchCreateRelation } from '../model/match/match-create-relation';
import { MatchUpdateRelation } from '../model/match/match-update-relation';
import { Regionales } from '../model/dto/regionales';
import { Rol } from '../model/dto/rol';
import { Instaladora } from '../model/dto/instaladora';
import { MatchDesactivateUsuario } from '../model/match/match-desactivate-usuario';
import { ResumenReportMantenedoresTabla2 } from '../model/dto/resumen/resumen-report-mantenedores-tabla2';

@Injectable({
	providedIn: 'root'
})
export class UserService extends BaseService<User, ResumenUser, MetadataUser, MetadataResumenUser> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'user';
	}

	create(matchCreateUsuario: MatchCreateUsuario): Observable<User> {
		return this.http.post<User>(this.getUrl(), matchCreateUsuario, { headers: this.getHeader() });
	}

	createRelation(matchCreateRelation: MatchCreateRelation): Observable<User> {
		return this.http.post<User>(`${this.getUrl()}/relations`, matchCreateRelation, { headers: this.getHeader() });
	}

	updateRelation(id: number, matchUpdateRelation: MatchUpdateRelation): Observable<User> {
		return this.http.put<User>(`${this.getUrl()}/relations/${id}`, matchUpdateRelation, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateUsuario: MatchUpdateUsuario): Observable<User> {
		return this.http.put<User>(`${this.getUrl()}/${id}`, matchUpdateUsuario, { headers: this.getHeader() });
	}

	getDataToDesactivate(id: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${id}/data-to-desactivate`, { headers: this.getHeader() });
	}

	getPermissions(): Observable<User> {
		return this.http.get<User>(`${this.getUrl()}/permissions`, { headers: this.getHeader() });
	}

	getRegionalesOfRelations(id: number): Observable<Regionales[]> {
		return this.http.get<Regionales[]>(`${this.getUrl()}/relations/${id}/regionales`, { headers: this.getHeader() });
	}

	getInstaladoraOfRelation(id: number): Observable<Instaladora> {
		return this.http.get<Instaladora>(`${this.getUrl()}/relations/${id}/instaladora`, { headers: this.getHeader() });
	}

	getRoles(id: number): Observable<Rol[]> {
		return this.http.get<Rol[]>(`${this.getUrl()}/${id}/roles`, { headers: this.getHeader() });
	}

	login(login: Login): Observable<User> {
		let headers = new HttpHeaders({
			'login': login.login.toUpperCase(),
			'password': login.password
		});

		return this.http.post<User>(`${this.getUrl()}/login`, {}, {
			headers: headers
		});
	}

	validateCode(code: string, token: string, captcha: string): Observable<any> {
		let headers = new HttpHeaders({
			'code': code,
      'captcha': captcha,
      'jwt': token
		});

		return this.http.post<User>(`${this.getUrl()}/login/validate-code`, {}, {
			headers: headers
		});
	}

	refreshToken(): Observable<User> {
		let headers = new HttpHeaders({
			'jwt': localStorage.getItem("eTag"),
			'refreshToken': localStorage.getItem("refreshToken")
		});

		return this.http.post<User>(`${this.getUrl()}/refresh-token`, {}, {
			headers: headers
		});
	}

	recuperarPassword(email: string, captcha: string): Observable<any> {
		let headers = new HttpHeaders({
			'email': email,
			'captcha': captcha
		});

		return this.http.post<any>(`${this.getUrl()}/reset/password`, {}, { headers: headers })
	}

	recuperarPasswordByAdmin(matchResetPassword,captcha:string): Observable<any> {
    let headers = new HttpHeaders({
      'jwt': localStorage.getItem("eTag"),
			'captcha': captcha
		});
		return this.http.post<any>(`${this.getUrl()}/admin/reset/password`, matchResetPassword, { headers: headers })
	}

	cambiarPassword(password: string, jwt: string, captcha: string): Observable<any> {
		let headers = new HttpHeaders({
			'password': password,
			'captcha': captcha,
			'jwt': jwt
		});

		return this.http.put<any>(`${this.getUrl()}/change/password`, {}, { headers: headers })
	}

	getMetadataResumenRelationsListSearch(search: Search): Observable<MetadataResumenRelations> {
		return this.http.get<MetadataResumenRelations>(`${this.getUrl()}/relations`, { params: search.params, headers: this.getHeader() });
	}

	getUsuariosSinRelaciones(): Observable<User[]> {
		return this.http.get<User[]>(`${this.getUrl()}/relations/user-without-relations`, { headers: this.getHeader() });
	}

	getUserWithoutSellerRelated(search: Search, vendedorId: number = null): Observable<MetadataResumenUser> {
		let params = search.params;

		if (vendedorId != null) {
			params = params.append("vendedorId", vendedorId.toString());
		}

		return this.http.get<MetadataResumenUser>(`${this.getUrl()}/without-seller-related`, { params: params, headers: this.getHeader() });
	}

	getUserRelated(search: Search, userId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${userId}/related-to-transfer`, { params: search.params, headers: this.getHeader() });
	}

	getUserWithoutSellerRelatedFilterBySeller(search: Search, userId: number): Observable<MetadataResumenUser> {
		return this.http.get<MetadataResumenUser>(`${this.getUrl()}/without-seller-related-filter-by-seller/${userId}`, { params: search.params, headers: this.getHeader() });
	}

	getUserWithoutCuadrillaRelatedFilterByCuadrilla(search: Search, userId: number = null): Observable<MetadataResumenUser> {
		return this.http.get<MetadataResumenUser>(`${this.getUrl()}/without-cuadrilla-related-filter-by-cuadrilla/${userId}`, { params: search.params, headers: this.getHeader() });
	}

	getUserWithSellerRelatedFilterByNegocio(negocioId: number): Observable<MetadataResumenUser> {
		return this.http.get<MetadataResumenUser>(`${this.getUrl()}/with-seller-related-filter-by-negocio/${negocioId}`, { headers: this.getHeader() });
	}

	//SUPERVISION
	getUserSupervisoresFilterByService(serviceId: number): Observable<ResumenUser[]> {
		return this.http.get<ResumenUser[]>(`${this.getUrl()}/supervisores-filter-by-service/${serviceId}`, { headers: this.getHeader() });
	}

	desactivate(id: number, matchDesactivateUsuario: MatchDesactivateUsuario): Observable<User> {
		return this.http.put<User>(`${this.getUrl()}/${id}/desactivate`, matchDesactivateUsuario, { headers: this.getHeader() });
	}

	activate(id: number, matchDesactivateUsuario: MatchDesactivateUsuario): Observable<User> {
		return this.http.put<User>(`${this.getUrl()}/${id}/activate`, matchDesactivateUsuario, { headers: this.getHeader() });
	}

  sendEnmailWithQr(id: number,captcha:string): Observable<any> {
    let headers = new HttpHeaders({
      'jwt': localStorage.getItem("eTag"),
			'captcha': captcha
		});
		return this.http.get<any>(`${this.getUrl()}/${id}/qr`, { headers:headers});
	}

	getUserWithoutCuadrillaRelated(search: Search, cuadrillaId: number = null): Observable<MetadataResumenUser> {
		let params = search.params;

		if (cuadrillaId != null) {
			params = params.append("cuadrillaId", cuadrillaId.toString());
		}

		return this.http.get<MetadataResumenUser>(`${this.getUrl()}/without-cuadrilla-related`, { params: params, headers: this.getHeader() });
	}

	getUserWithSellerRelated(page: number = -1, limit: number = -1): Observable<MetadataResumenUser> {
		let params = new HttpParams();

		params = params.append("page", page.toString());
		params = params.append("max", limit.toString());

		return this.http.get<MetadataResumenUser>(`${this.getUrl()}/with-seller-related`, { params: params, headers: this.getHeader() });
	}

	//******************************************************* */REPORTE 9: MANTENEDORES***************************************************

	//TABLA 2: USUARIOS
	getReportMantenedoresTabla2(regionalesId: string, instaladorasId: string,
		usuario: string, tipoRolId: string, rolId: string, statusGeneralId: string, sinRelacion: boolean): Observable<ResumenReportMantenedoresTabla2[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("usuario", usuario);
		params = params.append("tipoRolId", tipoRolId);
		params = params.append("rolId", rolId);
		params = params.append("statusGeneralId", statusGeneralId);
		if(sinRelacion){
			params = params.append("sinRelacion", sinRelacion.toString());
		}

		return this.http.get<ResumenReportMantenedoresTabla2[]>(`${this.getUrlReports()}/reporte-mantenedores-tabla2`, { params: params, headers: this.getHeader() });
	}

	getSupervisoresVentasListToOptions(): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/supervisores-ventas/list-to-options`, { headers: this.getHeader() });
	}

	desvincular(id): Observable<any>{
		return this.http.delete<any>(`${this.getUrl()}/${id}/desvincular`, { headers: this.getHeader() });
	}
}

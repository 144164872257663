import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Folios } from '../model/dto/folios';
import { MetadataFolios } from '../model/match/metadata-folio';
import { Observable } from 'rxjs';
import { MatchUpdateFolio } from '../model/match/match-update-folio';
import { ResumenReportGeneralDeFoliosTabla2 } from '../model/dto/resumen/resumen-report-general-de-folios-tabla2';

@Injectable({
	providedIn: 'root'
})
export class FoliosService extends BaseService<Folios, Folios, MetadataFolios, MetadataFolios> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'folios';
	}

	getFolioByDireccion(id: number): Observable<Folios[]> {
		return this.http.get<Folios[]>(`${this.getUrl()}/folio-by-direccion/${id}`, { headers: this.getHeader() });
	}

	getFolioByContrato(id: number): Observable<Folios[]> {
		return this.http.get<Folios[]>(`${this.getUrl()}/folio-by-contrato/${id}`, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateFolio: MatchUpdateFolio): Observable<Folios> {
		return this.http.put<Folios>(`${this.getUrl()}/${id}`, matchUpdateFolio, { headers: this.getHeader() });
	}

	updateEstatusFolio(id: number): Observable<Folios> {
		return this.http.put<Folios>(`${this.getUrl()}/${id}/cambiar-a-disponible`, {}, { headers: this.getHeader() });
	}

		//********************************************** */REPORTE 5: GENERAL DE FOLIOS *******************************************

	//TABLA 2: LISTADO DE FOLIOS
	getReportGeneralDeFoliosTabla2(regionalesId: string, instaladorasId: string, folio: string, folio2: string,
		statusFolioId: string): Observable<ResumenReportGeneralDeFoliosTabla2[]> {

		let params = new HttpParams();

		params = params.append("folio", folio);
		params = params.append("folio2", folio2);
		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("statusFolioId", statusFolioId);

		return this.http.get<ResumenReportGeneralDeFoliosTabla2[]>(`${this.getUrlReports()}/reporte-general-de-folios-tabla2`, { params: params, headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { TamanioComercio } from '../model/dto/tamanio-comercio';
import { MetadataTamanioComercio } from '../model/match/metadata-tamanio-comercio';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class TamanioComercioService extends BaseService<TamanioComercio, TamanioComercio, MetadataTamanioComercio, MetadataTamanioComercio> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tamanio-comercio';
	}
	getTamanioComercio(): Observable<TamanioComercio> {
		return this.http.get<TamanioComercio>(`${this.getUrl()}`, { headers: this.getHeader() });
	}
}

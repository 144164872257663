import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Instaladora, PesBySupervisor, PesBySupervisorResponse } from '../model/dto/instaladora';
import { MetadataInstaladora } from '../model/match/metadata-instaladora';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MetadataVendedor } from '../model/match/metadata-vendedor';
import { MetadataResumenRegional } from '../model/match/metadata-resumen-regional';
import { Search } from '../../shared/components/search/search.component';
import { MetadataResumenCliente } from '../model/match/metadata-resumen-cliente';
import { Regionales } from '../model/dto/regionales';
import { TipoServicio } from '../model/dto/tipo-servicio';
import { ResumenInstaladora } from '../model/dto/resumen/resumen-instaladora';
import { MetadataResumenInstaladora } from '../model/match/metadata-resumen-instaladora';
import { MatchCreateInstaladora } from '../model/match/match-create-instaladora';
import { MatchUpdateInstaladora } from '../model/match/match-update-instaladora';
import { File } from '../model/dto/file';
import { MetadataResumenVendedor } from '../model/match/metadata-resumen-vendedor';
import { ResumenReportMantenedoresTabla3 } from '../model/dto/resumen/resumen-report-mantenedores-tabla3';
import { MetadataResumenInstaladoraToPersistZona } from '../model/match/metadata-resumen-instaladora-to-persist-zona';
import { MetadataResumenVendedorToPersistMicrozona } from '../model/match/metadata-resumen-vendedor-to-persist-microzona';

@Injectable({
	providedIn: 'root'
})
export class InstaladoraService extends BaseService<Instaladora, ResumenInstaladora, MetadataInstaladora, MetadataResumenInstaladora> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'instaladora';
	}

	getMetadataVendedoresAvailablesToAssignToAddress(id: number, direccionClienteId: number, search: Search): Observable<MetadataResumenVendedor> {
		return this.http.get<MetadataResumenVendedor>(`${this.getUrl()}/${id}/vendedores-availables-to-assign-to-address/${direccionClienteId}`, { params: search.params, headers: this.getHeader() });
	}

	create(matchCreateInstaladora: MatchCreateInstaladora): Observable<Instaladora> {
		return this.http.post<Instaladora>(`${this.getUrl()}/`, matchCreateInstaladora, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateInstaladora: MatchUpdateInstaladora): Observable<Instaladora> {
		return this.http.put<Instaladora>(`${this.getUrl()}/${id}`, matchUpdateInstaladora, { headers: this.getHeader() });
	}

	delete(id: number): Observable<Instaladora> {
		return this.http.delete<Instaladora>(`${this.getUrl()}/${id}`, { headers: this.getHeader() });
	}

	deleteProfilePicture(id: number) {
		return this.http.delete(`${this.getUrl()}/${id}/profile-picture`, { headers: this.getHeader() });
	}

	getListFullData(): Observable<MetadataResumenRegional> {
		return this.http.get<MetadataResumenRegional>(`${this.getUrl()}/resumen`, { headers: this.getHeader() });
	}

	getRegionales(id: number): Observable<Regionales[]> {
		return this.http.get<Regionales[]>(`${this.getUrl()}/${id}/regionales`, { headers: this.getHeader() });
	}

	getInstaladorasByID(id: number): Observable<Instaladora[]> {
		return this.http.get<Instaladora[]>(`${this.getBaseUrl()}/${id}`, { headers: this.getHeader() });
	}

	getMetadataInstaladorasSearch(search: Search): Observable<MetadataInstaladora> {
		return this.http.get<MetadataInstaladora>(`${this.getUrl()}`, { params: search.params, headers: this.getHeader() });
	}

	getFiles(id: number): Observable<File[]> {
		return this.http.get<File[]>(`${this.getUrl()}/${id}/file`, { params: null, headers: this.getHeader() });
	}

	deleteFile(id: number, idInstaladora) {
		return this.http.delete(`${this.getUrl()}/${idInstaladora}/file/${id}`, { headers: this.getHeader() });
	}

	getTiposServicios(id: number): Observable<TipoServicio[]> {
		return this.http.get<TipoServicio[]>(`${this.getUrl()}/${id}/tipos-servicios`, { headers: this.getHeader() });
	}

	getInstaladorasAvailableToAssign(regionalId: number = null): Observable<Instaladora[]> {

		let urlAdm = "";
		let preAdm = "";

		if (regionalId != null) {
			urlAdm = "/" + regionalId;
		}

		if (urlAdm != "") {
			preAdm = "/admin";
		}

		return this.http.get<Instaladora[]>(`${this.getUrl()}${preAdm}/availables-to-assign-gas-meter${urlAdm}`, { headers: this.getHeader() });
	}

	getUsuariosCreadoresDeContratos(id: number, search: Search): Observable<MetadataResumenCliente> {
		return this.http.get<MetadataResumenCliente>(`${this.getUrl()}/${id}/users-creaters-contract`, { params: search.params, headers: this.getHeader() });
	}

	//******************************************************* */REPORTE 9: MANTENEDORES***************************************************

	//TABLA 3: SOCIOS COMERCIALES
	getReportMantenedoresTabla3(regionalesId: string, instaladorasId: string,
		codigoSAP: string, rfc: string, statusGeneralId: string): Observable<ResumenReportMantenedoresTabla3[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("codigoSAP", codigoSAP);
		params = params.append("rfc", rfc);
		params = params.append("statusGeneralId", statusGeneralId);

		return this.http.get<ResumenReportMantenedoresTabla3[]>(`${this.getUrlReports()}/reporte-mantenedores-tabla3`, { params: params, headers: this.getHeader() });
	}

	getCantidadVendedoresTotalesPorRegional(id: number, regionalId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${id}/cantidad-vendedores-totales/${regionalId}`, { headers: this.getHeader() });
	}

	getCantidadVendedoresTotales(id: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${id}/cantidad-vendedores-totales`, { headers: this.getHeader() });
	}

	getVendedoresListToOptions(id: number): Observable<any[]> {
	 	return this.http.get<any[]>(`${this.getUrl()}/${id}/vendedores/list-to-options`, { headers: this.getHeader() });
	}

	getVendedoresListToOptionsByRegional(id: number, regionalId: number): Observable<any[]> {
	 	return this.http.get<any[]>(`${this.getUrl()}/${id}/vendedores/list-to-options/${regionalId}`, { headers: this.getHeader() });
	}

	getMetadataInstaladorasPersistZona(instaladorasIdToExclude: string,search: Search): Observable <MetadataResumenInstaladoraToPersistZona>{
		return this.http.get<MetadataResumenInstaladoraToPersistZona>(`${this.getUrl()}/instaladoras-resumen-to-persist-zona/exclude/${instaladorasIdToExclude}`, {params: search.params, headers: this.getHeader()});
	}

	getMetadataVendedoresPersistMicrozona(instaladoraId: number,regionalId: number, vendedoresIdToExclude: string,search: Search): Observable <MetadataResumenVendedorToPersistMicrozona>{
		return this.http.get<MetadataResumenVendedorToPersistMicrozona>(`${this.getUrl()}/${instaladoraId}/vendedores-resumen-to-persist-microzona/${regionalId}/exclude/${vendedoresIdToExclude}`, {params: search.params, headers: this.getHeader()});
	}

	getMetadataVendedoresPersistMicrozonaAddMicrozona(instaladoraId: number,regionalId: number, vendedoresIdToExclude: string, microzonaId: number,search: Search): Observable <MetadataResumenVendedorToPersistMicrozona>{
    return this.http.get<MetadataResumenVendedorToPersistMicrozona>(`${this.getUrl()}/${instaladoraId}/vendedores-resumen-to-persist-microzona/${regionalId}/exclude/${vendedoresIdToExclude}/add/${microzonaId}`, {params: search.params, headers: this.getHeader()});
  }

  getPesByRegionalAndSupervisor(search: Search, id: number, supervisorId: number, fechaInicio: number, fechaFin: number): Observable<PesBySupervisorResponse> {
    let params = search.params;

		if (fechaInicio && fechaFin) {
			params = params.append("fechaInicio", fechaInicio.toString());
      params = params.append("fechaFin", fechaFin.toString());

		}
    return this.http.get<PesBySupervisorResponse>(`${this.getUrl()}/${id}/get-pes-by-supervisor/${supervisorId}`, { params, headers: this.getHeader() });
 }
}

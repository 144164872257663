import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataZipCode } from '../model/match/metadata-zip-code';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Colonia } from '../model/dto/colonia';
import { ZipCode } from '../model/dto/zip-code';
import { MetadataResumenZipCode } from '../model/match/metadata-resumen-zip-code';
import { ResumenZipCode } from '../model/dto/resumen/resumen-zip-code';
import { MatchUpdateZipCode } from '../model/match/match-update-zip-code';
import { MatchCreateZipCode } from '../model/match/match-create-zip-code';

@Injectable({
	providedIn: 'root'
})
export class ZipCodeService extends BaseService<ZipCode, ResumenZipCode, MetadataZipCode, MetadataResumenZipCode> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'zip-code';
	}

	create(matchCreateZipCode: MatchCreateZipCode): Observable<Colonia> {
		return this.http.post<Colonia>(this.getUrl(), matchCreateZipCode, {headers: this.getHeader()});
	}

	update(id: number, matchUpdateZipCode: MatchUpdateZipCode): Observable<ZipCode> {
		return this.http.put<ZipCode>(`${this.getUrl()}/${id}`, matchUpdateZipCode, {headers: this.getHeader()});
	}

	getColonias(id: number): Observable<Colonia[]> {
		return this.http.get<Colonia[]>(`${this.getUrl()}/${id}/colonias`, { headers: this.getHeader() });
	}

	getColoniasWithRegionalFilter(id: number, comercial = false): Observable<Colonia[]> {
		let params = new HttpParams();
		if(comercial){
			params = params.append("comercial", "true");
		}
		return this.http.get<Colonia[]>(`${this.getUrl()}/${id}/colonias-with-regional-filter`, { params: params, headers: this.getHeader() });
	}
}

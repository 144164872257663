import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { CostoEspecial } from '../model/dto/costo-especial';
import { MetadataCostoEspecial } from '../model/match/metadata-costo-especial';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CostoEspecialService extends BaseService<CostoEspecial, CostoEspecial, MetadataCostoEspecial, MetadataCostoEspecial> {
	
	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'costo-especial';
	}

	getListFullData(): Observable<CostoEspecial[]> {
		return this.http.get<CostoEspecial[]>(`${this.getUrl()}`,{headers: this.getHeader()});
	}
}

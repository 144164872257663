import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '../../shared/components/search/search.component';
import { Categoria } from '../model/dto/categoria';
import { MetadataCategoria } from '../model/match/metadata-categoria';
import { MatchUpdateCategoria } from '../model/match/match-update-categoria';
import { MatchCreateCategoria } from '../model/match/match-create-categoria';

@Injectable({
	providedIn: 'root'
})
export class CategoriaService extends BaseService<Categoria, Categoria, MetadataCategoria, MetadataCategoria> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'categoria';
	}

	create(matchCreateCategoria: MatchCreateCategoria): Observable<any> {
		return this.http.post<any>(this.getUrl(), matchCreateCategoria, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateCategoria: MatchUpdateCategoria): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}`, matchUpdateCategoria, { headers: this.getHeader() });
	}

	delete(categoriaId: number): Observable<any> {
		return this.http.delete<any>(`${this.getUrl()}/${categoriaId}`, {headers: this.getHeader()});
	}

	getListToOptions(): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader() });
	}

	getMetadataResumenListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen/v2`, { params: search.params, headers: this.getHeader() });
	}
}

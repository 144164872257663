import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Comentario } from '../model/dto/comentario';
import { ResumenComentario } from '../model/dto/resumen/resumen-comentario';
import { MetadataComentario } from '../model/match/metadata-comentario';
import { MetadataResumenComentario } from '../model/match/metadata-resumen-comentario';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ComentarioService extends BaseService<Comentario, ResumenComentario, MetadataComentario, MetadataResumenComentario> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'comentario';
	}

	create(data:any): Observable <Comentario>{
		
		return this.http.post<Comentario>(`${this.getUrl()}`,data, {headers: this.getHeader()});
	}
}

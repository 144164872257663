import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataReasonFailureServiceType } from '../model/match/metadata-reason-failure-service-type';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MetadataRadix } from '../model/match/metadata-radix';
import { ReasonUnfeasibilityServiceType } from '../model/dto/reason-unfeasibility-service-type';
import { ReasonFailureServiceType } from '../model/dto/reason-failure-service-type';

@Injectable({
	providedIn: 'root'
})
export class ReasonFailureServiceTypeService extends BaseService<ReasonFailureServiceType, ReasonFailureServiceType, MetadataReasonFailureServiceType, MetadataReasonFailureServiceType> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'reason-failure-service-type';
	}
}

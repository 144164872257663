import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup,FormBuilder,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../../api/rest/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-recuperar-contrasena',
	templateUrl: './recuperar-contrasena.component.html',
	styleUrls: ['./recuperar-contrasena.component.css']
})
export class RecuperarContrasenaComponent implements OnInit {

	@ViewChild("reCaptcha")
	reCaptcha: any;

	captchaToken: string = null;
	formRecuperacion: FormGroup
	email: string;
	send: boolean = false;
	KEY: string = "6Ldu_3kkAAAAAAgclpiQTgo3NRSndyQHUewxp5Xu"; //6Ldu_3kkAAAAAAgclpiQTgo3NRSndyQHUewxp5Xu

	title: string;
	content: string;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private userService: UserService,
		private toastr: ToastrService
	) {

		this.initForms();
	}

	ngOnInit() {

	}

	recuperarContrasenia() {
		this.email = this.formRecuperacion.value.email;

		this.userService.recuperarPassword(this.email, this.captchaToken).subscribe( (res) => {
			if(res == null){
        this.title = "Email enviado con Exito!";
				this.content = "Enviamos un correo a la dirección asociada al usuario , revisa tu buzón para recuperar tu contraseña.";
			}else{
				this.email = res.email;
				this.title = "Email enviado con Exito!";
				this.content = "Enviamos un correo a la dirección asociada al usuario , revisa tu buzón para recuperar tu contraseña.";
			}

			this.send = true;

		}, (erro) => {
      console.log(erro)
			this.title = "Ocurrio un problema!";
			this.content = "Por favor, vuelva a intentar, si el problema persiste contacte con Soporte.";
			this.send = true;
		});
	}

	initForms(): void {
		this.formRecuperacion = this.formBuilder.group({
			email: this.formBuilder.control('', [Validators.required]),
			captcha : this.formBuilder.control('', [Validators.required]),
		});
	}

	cancelarRecuperacion() {
		this.router.navigate(['/login']);
	}

	handleCorrectCaptcha(response) {
		this.captchaToken = response;
	}

	onSearchChange(event) {
		if (this.captchaToken) {
			this.reCaptcha.reset();
			this.captchaToken = null;
		}
	}

	volver() {
		this.captchaToken = null;
		this.send = false;
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { StatusFolio } from '../model/dto/status-folio';
import { MetadataStatusFolio } from '../model/match/metadata-status-folio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatusFolioService extends BaseService<StatusFolio, StatusFolio, MetadataStatusFolio, MetadataStatusFolio> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'status-folio';
	}

	getList(): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}`, { headers: this.getHeader() });
	}
}

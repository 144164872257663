import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataCombinacionFinanciamiento } from '../model/match/metadata-combinacion-financiamiento';
import { CombinacionFinanciamiento } from '../model/dto/combinacion-financiamiento';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatchCreateCombinacionFinanciamiento } from '../model/match/match-create-combinacion-financiamiento';
import { Observable } from 'rxjs';
import { MatchBusquedaCombinacion } from '../model/match/match-busqueda-combinacion';
import { TipoFinanciamiento } from '../model/dto/tipo-financiamiento';
import { ResumenReportOfertasComercialesTabla3 } from '../model/dto/resumen/resumen-report-ofertas-comerciales-tabla3';

type TiposFinanciamientosFilter = 'ACTIVO' | 'INACTIVO'
@Injectable({
  providedIn: 'root'
})
export class CombinacionFinanciamientoService extends BaseService<CombinacionFinanciamiento, CombinacionFinanciamiento, MetadataCombinacionFinanciamiento, MetadataCombinacionFinanciamiento> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'combinacion-financiamiento';
	}

	getCombinacion(){
		return this.http.get(`${this.getUrl()}/combinacion`, {headers: this.getHeader()});
	}

	create(matchCreateCombinacionFinanciamiento:MatchCreateCombinacionFinanciamiento): Observable <CombinacionFinanciamiento>{
		return this.http.post<CombinacionFinanciamiento>(`${this.getUrl()}`,matchCreateCombinacionFinanciamiento, {headers: this.getHeader()});
	}

	update(matchCreateCombinacionFinanciamiento:MatchCreateCombinacionFinanciamiento, idCombinacionFinanciamiento:number): Observable <CombinacionFinanciamiento>{
		return this.http.put<CombinacionFinanciamiento>(`${this.getUrl()}/update/${idCombinacionFinanciamiento}`,matchCreateCombinacionFinanciamiento, {headers: this.getHeader()});
	}

	delete(idCombinacionFinanciamiento:number):Observable <CombinacionFinanciamiento>{
		return this.http.put<CombinacionFinanciamiento>(`${this.getUrl()}/delete/${idCombinacionFinanciamiento}`,null, {headers: this.getHeader()});
	}

	getTipoInmuebleById( idCombinacion:number ){
		return this.http.get(`${this.getUrl()}/${idCombinacion}/tipo-inmueble`, {headers: this.getHeader()});
	}

	getTiposFinanciamientos(idCombinacion:number, filter?:TiposFinanciamientosFilter): Observable<TipoFinanciamiento[]>{
    let params = new HttpParams();

      switch (filter) {
        case 'ACTIVO':
          params = params.append("filter", '1');
          break;

        case 'INACTIVO':
          params = params.append("filter", '2');
          break;

        default:
          break;
    }

		return this.http.get<TipoFinanciamiento[]>(`${this.getUrl()}/${idCombinacion}/tipo-financiamiento`, {headers: this.getHeader(), params});
	}

	getTamanoComercioById( idCombinacion:number ){
		return this.http.get(`${this.getUrl()}/${idCombinacion}/tamano-comercio`, {headers: this.getHeader()});
	}

	getInstaladorasById(idCombinacion: number) {
		return this.http.get(`${this.getUrl()}/${idCombinacion}/instaladora`, { headers: this.getHeader() });
	}

	getCombinacionToContrato(matchBusquedaCombinacion: MatchBusquedaCombinacion){
		return this.http.post(`${this.getUrl()}/combinacion-to-contrato`, matchBusquedaCombinacion, { headers: this.getHeader() });
	}

	//********************************************** */REPORTE 4: OFERTAS COMERCIALES*******************************************

	//TABLA 3: COMBINACION DE FINANCIAMIENTO
	getReportOfertasComercialesTabla3(regionalesId: string, instaladorasId: string, nombreCombinacion: string, descripcionCombinacion: string,
		statusId: string,
		tipoFinanciamiento: string,tipoClienteId: string, tipoPESId: string,tipoInmuebleId: string, tamanioComercioId: string):
		Observable<ResumenReportOfertasComercialesTabla3[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("nombreCombinacion", nombreCombinacion);
		params = params.append("descripcionCombinacion", descripcionCombinacion);
		params = params.append("statusId", statusId);
		params = params.append("tipoFinanciamiento", tipoFinanciamiento);
		params = params.append("tipoClienteId", tipoClienteId);
		params = params.append("tipoPESId", tipoPESId);
		params = params.append("tipoInmuebleId", tipoInmuebleId);
		params = params.append("tamanioComercioId", tamanioComercioId);

		return this.http.get<ResumenReportOfertasComercialesTabla3[]>(`${this.getUrlReports()}/reporte-ofertas-comerciales-tabla3`, { params: params, headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StatusProyecto} from '../model/dto/status-proyecto';
import { MetadataStatusProyecto } from '../model/match/metadata-status-proyecto';

@Injectable({
  providedIn: 'root'
})
export class StatusProyectoService extends BaseService<StatusProyecto, StatusProyecto, MetadataStatusProyecto, MetadataStatusProyecto> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getClassUrl(): string {
    return 'status-proyecto';
  }

  getProyectosStatusListToOptions(): Observable <any> {
    return this.http.get<any>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader()});
  }

}

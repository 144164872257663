import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IncidenceType } from '../../api/model/dto/incidence-type';
import { MetadataIncidenceType } from '../model/match/metadata-incidence-type';
import { HttpClient } from '@angular/common/http';
@Injectable({
	providedIn: 'root'
})
export class IncidenceTypeService extends BaseService<IncidenceType, IncidenceType, MetadataIncidenceType, MetadataIncidenceType> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'incidence-type';
	}
}

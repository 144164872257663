import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { TipoPresupuestoEspecial } from '../model/dto/tipo-presupuesto-especial';
import { MetadataTipoPresupuestoEspecial } from '../model/match/metadata-tipo-presupuesto-especial';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Colonia } from '../model/dto/colonia';
import { ZipCode } from '../model/dto/zip-code';

@Injectable({
	providedIn: 'root'
})
export class TipoPresupuestoEspecialService extends BaseService<TipoPresupuestoEspecial, TipoPresupuestoEspecial, MetadataTipoPresupuestoEspecial, MetadataTipoPresupuestoEspecial> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tipo-presupuesto-especial';
	}

	update(id: number, matchUpdateTipoPresupuestoEspecial): Observable<TipoPresupuestoEspecial> {
		return this.http.put<TipoPresupuestoEspecial>(`${this.getUrl()}/${id}`, matchUpdateTipoPresupuestoEspecial, {headers: this.getHeader()});
	}

	delete(id: number): Observable<TipoPresupuestoEspecial> {
		return this.http.delete<TipoPresupuestoEspecial>(`${this.getUrl()}/${id}`, {headers: this.getHeader()});
	}

	create(matchCreateTipoPresupuestoEspecial): Observable<TipoPresupuestoEspecial> {
		return this.http.post<TipoPresupuestoEspecial>(this.getUrl(), matchCreateTipoPresupuestoEspecial, {headers: this.getHeader()});
	}

	getMetadataListToContrato(page: number = -1, limit: number = -1, order = -1, field = "id"): Observable<MetadataTipoPresupuestoEspecial> {
		let params = new HttpParams();

		params = params.append("page", page.toString());
		params = params.append("max", limit.toString());
		params = params.append("field", field.toString());
		params = params.append("order", order.toString());

		return this.http.get<MetadataTipoPresupuestoEspecial>(`${this.getUrl()}/to-contrato`, { params: params, headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { StatusContrato } from '../model/dto/status-contrato';
import { MetadataStatusContrato } from '../model/match/metadata-status-contrato';

@Injectable({
	providedIn: 'root'
})
export class StatusContratoService extends BaseService<StatusContrato, StatusContrato, MetadataStatusContrato, MetadataStatusContrato> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'status-contrato';
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '../../shared/components/search/search.component';
import { ListaImpresion } from '../model/dto/lista-impresion';
import { MetadataListaImpresion } from '../model/match/metadata-lista-impresion';

@Injectable({
	providedIn: 'root'
})
export class ListaImpresionService extends BaseService<ListaImpresion, ListaImpresion, MetadataListaImpresion, MetadataListaImpresion> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'lista-impresion';
	}

	getMetadataResumenListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen/v2`, { params: search.params, headers: this.getHeader() });
	}

	marcarComoImpreso(id: number): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}/marcar-como-impreso`, {}, { headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ObjetivoVendedor } from '../model/dto/objetivo-vendedor';
import { MetadataObjetivoVendedor } from '../model/match/metadata-objetivo-vendedor';
import { MatchCreateObjetivo } from '../model/match/match-create-objetivo';
import { MatchUpdateObjetivoVendedor } from '../model/match/match-update-objetivo-vendedor';
@Injectable({
	providedIn: 'root'
})
export class ObjetivoVendedorService extends BaseService<ObjetivoVendedor, ObjetivoVendedor, MetadataObjetivoVendedor, MetadataObjetivoVendedor> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'objetivo-vendedor';
	}

	create(matchCreateObjetivo: MatchCreateObjetivo): Observable<any> {
		return this.http.post<any>(this.getUrl(), matchCreateObjetivo, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateObjetivoVendedor: MatchUpdateObjetivoVendedor): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}`, matchUpdateObjetivoVendedor, { headers: this.getHeader() });
	}

	delete(objetivoId: number): Observable<any> {
		return this.http.delete<any>(`${this.getUrl()}/${objetivoId}`, {headers: this.getHeader()});
	}

	getObjetivoEstadisticas(objetivoId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${objetivoId}/estadisticas`, { headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { CaracteristicasInmueble } from '../model/dto/caracteristicas-inmueble';
import { MetadataCaracteristicasInmueble } from '../model/match/metadata-caracteristicas-inmueble';

@Injectable({
	providedIn: 'root'
})
export class CaracteristicasInmuebleService extends BaseService<CaracteristicasInmueble, CaracteristicasInmueble, MetadataCaracteristicasInmueble, MetadataCaracteristicasInmueble> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'caracteristicas-inmueble';
	}

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DomainService} from './rest/domain.service';
import {ClienteService} from './rest/cliente.service';
import {AparatoAConectarService} from './rest/aparato-a-conectar.service';
import {CuadrillaService} from './rest/cuadrilla.service';
import {StatusService} from './rest/status.service';
import {RolService} from './rest/rol.service';
import {TipoClienteService} from './rest/tipo-cliente.service';
import {TipoDireccionService} from './rest/tipo-direccion.service';
import {GiroService} from './rest/giro.service';
import {TipoServicioService} from './rest/tipo-servicio.service';
import {EstadoService} from './rest/estado.service';
import {MunicipioService} from './rest/municipio.service';
import {VendedorService} from './rest/vendedor.service';
import {SubdomainService} from './rest/subdomain.service';
import {UserService} from './rest/user.service';
import {InstaladoraService} from './rest/instaladora.service';
import {PermissionService} from './rest/permission.service';
import {RegionalService} from './rest/regional.service';
import {ContratoService} from './rest/contrato.service';
import {NegocioService} from './rest/negocio.service';
import {TipoMotivoDesactivacionService} from './rest/tipo-motivo-desactivacion.service';
import {ActividadNegocioService} from './rest/actividad-negocio.service';
import {TipoActividadNegocioService} from './rest/tipo-actividad-negocio.service';
import {StatusActividadNegocioService} from './rest/status-actividad-negocio.service';
import {TipoPersonaService} from './rest/tipo-persona.service';
import {TipoDocumentoService} from './rest/tipo-documento.service';
import {DescuentoService} from './rest/descuento.service';
import {FormaPagoService} from './rest/forma-pago.service';
import {MetodoPagoService} from './rest/metodo-pago.service';
import {MaterialService} from './rest/material.service';
import {PrioridadService} from './rest/prioridad.service';
import {TamanioComercioService} from './rest/tamanio-comercio.service';
import {TipoDeInmuebleService} from './rest/tipo-de-inmueble.service';
import {TipoFinanciamientoService} from './rest/tipo-financiamiento.service';
import {DireccionClienteService} from './rest/direccion-cliente.service';
import {CostoEspecialService} from './rest/costo-especial.service';
import {ServiceService} from './rest/service.service';
import {RangeHoraryServiceService} from './rest/range-horary-service.service';
import {PresupuestoService} from './rest/presupuesto.service';
import {TipoComercioService} from './rest/tipo-comercio.service';
import {TipoPrecioCostoService} from './rest/tipo-precios-costos.service';
import {CombinacionFinanciamientoService} from './rest/combinacion-financiamiento.service';
import {ServiceFeasibilityService} from './rest/service-feasibility.service';
import {ServiceHighReasonMeterService} from './rest/service-high-reason-meter.service';
import {ServiceLocationMeterService} from './rest/service-location-meter.service';
import {ServiceMeasuredPressureService} from './rest/service-measured-pressure.service';
import {RadixService} from './rest/radix.service';
import {ReasonUnfeasibilityServiceTypeService} from './rest/reason-unfeasibility-service-type.service';
import {ReasonFailureServiceTypeService} from './rest/reason-failure-service-type.service';
import {GasMeterService} from './rest/gas-meter.service';
import {CostoInstalacionService} from './rest/costo-instalacion.service';
import {RoleTypeService} from './rest/role-type.service';
import {IncidenceTypeService} from './rest/incidence-type.service';
import {ComentarioService} from './rest/comentario.service';
import {InventoryMovementService} from './rest/inventory-movement.service';
import {WarehouseService} from './rest/warehouse.service';
import {HistorialService} from './rest/historial.service';
import {TipoMedidorService} from './rest/tipo-medidor.service';
import {ProveedorMedidorService} from './rest/proveedor-medidor.service';
import {StatusMedidorService} from './rest/status-medidor.service';
import {DepositService} from './rest/deposit.service';
import {ColoniaService} from './rest/colonia.service';
import {StreetService} from './rest/street.service';
import {ZipCodeService} from './rest/zip-code.service';
import {GasMeterTypeService} from './rest/gas-meter-type.service';
import {FoliosService} from './rest/folios.service';
import {StatusFolioService} from './rest/status-folio.service';
import {PermissionGroupService} from './rest/permission-group.service';
import {DashboardService} from './rest/dashboard.service';
import {ReportService} from './rest/report.service';
import {NotificacionService} from './rest/notificacion.service';
import {InterfaceService} from './rest/interface.service';
import {OrigenDatosService} from './rest/origen-datos.service';
import {TratamientoService} from './rest/tratamiento.service';
import {CfdiService} from './rest/cfdi.service';
import {CondicionPagoService} from './rest/condicion-pago.service';
import {ElementoEstrRegionalPolService} from './rest/elemento-estr-regional-pol.service';
import {TipoPESService} from './rest/tipo-de-puesta-en-servicio.service';
import {CitygateService} from './rest/citygate.service';
import {StatusContratoService} from './rest/status-contrato.service';
import {StatusServiceService} from './rest/status-service.service';
import {ElementoPEPService} from './rest/elemento-pep.service';
import {SupervisionServiceService} from './rest/supervision-service.service';
import {DepositTypeService} from './rest/deposit-type.service';
import {TipoPresupuestoEspecialService} from './rest/tipo-presupuesto-especial.service';
import {StatusClienteService} from './rest/status-cliente.service';
import {ZonaService} from './rest/zona.service';
import {AcometidaService} from './rest/acometida.service';
import {LeadService} from './rest/lead.service';
import {ClienteActivoService} from './rest/cliente-activo.service';
import {AdeudoService} from './rest/adeudo.service';
import {OrigenDatosLeadService} from './rest/origen-datos-lead.service';
import {MicrozonaService} from './rest/microzona.service';
import {ObjetivoMicrozonaService} from './rest/objetivo-microzona.service';
import {RiesgoMicrozonaService} from './rest/riesgo-microzona.service';
import {NivelSocioeconomicoMicrozonaService} from './rest/nivel-socioeconomico-microzona.service';
import {RutaService} from './rest/ruta.service';
import {ProyectoService} from './rest/proyecto.service';
import {RecursoService} from './rest/recurso.service';
import {CategoriaService} from './rest/categoria.service';
import {ObjetivoVendedorService} from './rest/objetivo-vendedor.service';
import {EstadoProyectoService} from './rest/estado-proyecto.service';
import {ListaImpresionService} from './rest/lista-impresion.service';
import {EspecializacionesService} from './rest/especializaciones.service';
import {StatusProyectoService} from './rest/status-proyecto.service';
import {TipoProyectoService} from './rest/tipo-proyecto.service';
import {StatusSolicitudAcometidaService} from './rest/status-solicitud-acometida.service';
import {TipoAcometidaService} from './rest/tipo-acometida.service';
import {TipoViviendaService} from './rest/tipo-vivienda.service';
import {AnioCalculadoraService} from './rest/anio-calculadora.service';
import {MesCalculadoraService} from './rest/mes-calculadora.service';
import {DatoValorCalculadoraService} from './rest/dato-valor-calculadora.service';
import {MedidorReutilizableService} from './rest/medidor-reutilizable.service';
import {BajasService} from './rest/bajas.service';
import {RegimenFiscalService} from './rest/regimen_fiscal.service';
import { ObjetivoSupervisorService } from './rest/objetivo-supervisor.service';
import { GasMeterProviderService } from './rest/gas-meter-provider.service';
import { CaracteristicasInmuebleService } from './rest/caracteristicas-inmueble.service';

@NgModule({
  imports: [
    CommonModule
  ],

  providers: [
    DomainService,
    DescuentoService,
    AparatoAConectarService,
    MetodoPagoService,
    MaterialService,
    PrioridadService,
    ClienteService,
    CuadrillaService,
    CostoInstalacionService,
    StatusService,
    FormaPagoService,
    RolService,
    RegionalService,
    SubdomainService,
    UserService,
    TamanioComercioService,
    TipoFinanciamientoService,
    TipoDeInmuebleService,
    TipoDocumentoService,
    TipoClienteService,
    TipoDireccionService,
    MunicipioService,
    VendedorService,
    GiroService,
    EstadoService,
    PermissionService,
    NegocioService,
    InstaladoraService,
    TipoMotivoDesactivacionService,
    TipoServicioService,
    ActividadNegocioService,
    TipoActividadNegocioService,
    StatusActividadNegocioService,
    ContratoService,
    ServiceService,
    TipoPersonaService,
    DireccionClienteService,
    CostoEspecialService,
    RangeHoraryServiceService,
    DomainService,
    DescuentoService,
    MetodoPagoService,
    MaterialService,
    PrioridadService,
    ClienteService,
    CuadrillaService,
    StatusService,
    FormaPagoService,
    RolService,
    RegionalService,
    SubdomainService,
    UserService,
    TamanioComercioService,
    TipoFinanciamientoService,
    TipoDeInmuebleService,
    TipoDocumentoService,
    TipoClienteService,
    TipoDireccionService,
    MunicipioService,
    VendedorService,
    GiroService,
    EstadoService,
    PermissionService,
    NegocioService,
    InstaladoraService,
    TipoMotivoDesactivacionService,
    TipoServicioService,
    ActividadNegocioService,
    TipoActividadNegocioService,
    StatusActividadNegocioService,
    ContratoService,
    TipoPersonaService,
    DireccionClienteService,
    CostoEspecialService,
    PresupuestoService,
    TipoComercioService,
    CombinacionFinanciamientoService,
    TipoPrecioCostoService,
    ServiceFeasibilityService,
    ServiceHighReasonMeterService,
    ServiceLocationMeterService,
    ServiceMeasuredPressureService,
    RadixService,
    ReasonUnfeasibilityServiceTypeService,
    ReasonFailureServiceTypeService,
    GasMeterService,
    ComentarioService,
    HistorialService,
    RoleTypeService,
    IncidenceTypeService,
    ComentarioService,
    InventoryMovementService,
    WarehouseService,
    TipoMedidorService,
    ProveedorMedidorService,
    StatusMedidorService,
    DepositService,
    ColoniaService,
    StreetService,
    ZipCodeService,
    GasMeterTypeService,
    GasMeterProviderService,
    FoliosService,
    StatusFolioService,
    PermissionGroupService,
    DashboardService,
    ReportService,
    NotificacionService,
    InterfaceService,
    OrigenDatosService,
    TratamientoService,
    CfdiService,
    CondicionPagoService,
    ElementoEstrRegionalPolService,
    TipoPESService,
    CitygateService,
    StatusContratoService,
    StatusServiceService,
    ElementoPEPService,
    SupervisionServiceService,
    DepositTypeService,
    TipoPresupuestoEspecialService,
    StatusClienteService,
    ZonaService,
    AcometidaService,
    ClienteActivoService,
    AdeudoService,
    LeadService,
    OrigenDatosLeadService,
    MicrozonaService,
    ObjetivoMicrozonaService,
    RiesgoMicrozonaService,
    NivelSocioeconomicoMicrozonaService,
    RutaService,
    ProyectoService,
    RecursoService,
    CategoriaService,
    ObjetivoVendedorService,
    EstadoProyectoService,
    ListaImpresionService,
    EspecializacionesService,
    StatusProyectoService,
    TipoProyectoService,
    StatusSolicitudAcometidaService,
    TipoAcometidaService,
    TipoViviendaService,
    AnioCalculadoraService,
    MesCalculadoraService,
    DatoValorCalculadoraService,
    MedidorReutilizableService,
    BajasService,
    RegimenFiscalService,
    ObjetivoSupervisorService,
    CaracteristicasInmuebleService
  ]
})

export class ApiModule {
}

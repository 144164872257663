import { Injectable } from '@angular/core';
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { ResumenColonia } from '../model/dto/resumen/resumen-colonia';
import { Observable } from 'rxjs';
import { MetadataResumenColonia } from '../model/match/metadata-resumen-colonia';
import { MetadataColonia } from '../model/match/metadata-colonia';
import { Search } from '../../shared/components/search/search.component';
import { Colonia } from '../model/dto/colonia';
import { ResumenStreet } from '../model/dto/resumen/resumen-street';
import { MatchCreateColonia } from '../model/match/match-create-colonia';
import { MatchUpdateColonia } from '../model/match/match-update-colonia';
import { Street } from '../model/dto/street';

@Injectable({
	providedIn: 'root'
})
export class ColoniaService extends BaseService<Colonia, ResumenColonia, MetadataColonia, MetadataResumenColonia> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'colonia';
	}
	
	getStreetsWithRegionalFilter(id: number, comercial = false): Observable<Street[]> {
		let params = new HttpParams();

		if(comercial) {
			params = params.append("comercial", "true");
		}

		return this.http.get<Street[]>(`${this.getUrl()}/${id}/streets-with-regional-filter`, { params: params,headers: this.getHeader() });
	}
	
	create(matchCreateColonia: MatchCreateColonia): Observable<Colonia> {
		return this.http.post<Colonia>(this.getUrl(), matchCreateColonia, {headers: this.getHeader()});
	}

	update(id: number, matchUpdateColonia: MatchUpdateColonia): Observable<Colonia> {
		return this.http.put<Colonia>(`${this.getUrl()}/${id}`, matchUpdateColonia, { headers: this.getHeader()});
	}

}
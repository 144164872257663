import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Historial } from '../model/dto/historial';
import { ResumenHistorial } from '../model/dto/resumen/resumen-historial';
import { MetadataHistorial } from '../model/match/metadata-historial';
import { MetadataResumenHistorial } from '../model/match/metadata-resumen-historial';
import { Search } from '../../shared/components/search/search.component';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HistorialService extends BaseService<Historial, ResumenHistorial, MetadataHistorial, MetadataResumenHistorial> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'historial';
	}

	getMetadataHistorialSearch(search: Search): Observable<MetadataHistorial> {
		return this.http.get<MetadataHistorial>(`${this.getUrl()}/resumen`, { params: search.params, headers: this.getHeader() });
	}
}

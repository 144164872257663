import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '../../shared/components/search/search.component';
import { Zona } from '../model/dto/zona';
import { MetadataZona } from '../model/match/metadata-zona';
import { MatchCreateZona } from '../model/match/match-create-zona';
import { MatchUpdateZona } from '../model/match/match-update-zona';
import { ResumenBajas } from '../model/dto/resumen/resumen-bajas';
import { MetadataResumenBajas} from '../model/match/metadata-resumen-bajas';
import { ResumenDetailBaja } from '../model/dto/resumen/resumen-detail-bajas';
import { ResumenBajaDesmontajeCambioEscenario } from '../model/dto/resumen/resumen-cambio-escenario-baja-desmontaje';
import { MatchCambioEscenarioBajaDesmontaje } from '../model/match/match-cambio-escenario-baja-desmontaje';

@Injectable({
	providedIn: 'root'
})
export class BajasService extends BaseService<any, ResumenBajas, any, MetadataResumenBajas> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'bajas';
	}

    getMetadataResumenBajas(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen`, { params: search.params, headers: this.getHeader() });
	}

	getDetailBaja(bajaId: number, origen:string, contrato: any): Observable<ResumenDetailBaja> {
		return this.http.get<ResumenDetailBaja>(`${this.getUrl()}/${bajaId}/detail-baja/${origen}/contrato/${contrato}`, { headers: this.getHeader() });
	}

	generateReporteBajas(enviarA: string, regionalId: string, desdefechaValidacionServicioADV: string, hastafechaValidacionServicioADV: string, desdefechaDesmontaje: string, hastafechaDesmontaje: string, desdefechaBaja: string, hastafechaBaja: string, motivoDeBaja: string, motivoDeLectura: string, desdefechaEstatusSap: string, hastafechaEstatusSap: string, instaladorasIds: string): Observable<any[]> {
		      let params = new HttpParams();
		      params = params.append('enviarA', enviarA);
		      params = params.append('regional', regionalId);
		      params = params.append('desdefechaADV', desdefechaValidacionServicioADV);
		      params = params.append('hastafechaADV', hastafechaValidacionServicioADV);
		      params = params.append('desdefechaDesmontaje', desdefechaDesmontaje);
		      params = params.append('hastafechaDesmontaje', hastafechaDesmontaje);
		      params = params.append('desdefechabaja', desdefechaBaja);
		      params = params.append('hastafechabaja', hastafechaBaja);
		      params = params.append('motivoBaja', motivoDeBaja);
		      params = params.append('motivoLectura', motivoDeLectura);
		      params = params.append('desdefechaStatusSAP', desdefechaEstatusSap);
		      params = params.append('hastafechaStatusSAP', hastafechaEstatusSap);
		      params = params.append('instaladorasId', instaladorasIds);
		      return this.http.get<any[]>(`${this.getUrl()}/reporte-bajas-general`, {params: params, headers: this.getHeader()});
	}

	getBajaDesmontCambioEscenario(businessPartner: string, contractAccount: string, instalation: string): Observable<ResumenBajaDesmontajeCambioEscenario> {
		let params = new HttpParams();
		params = params.append('businessPartner', businessPartner);
		params = params.append('contractAccount', contractAccount);
		params = params.append('instalation', instalation);
		return this.http.get<ResumenBajaDesmontajeCambioEscenario>(`${this.getUrl()}/cambio-escenario-baja-desmont`, { params: params, headers: this.getHeader() });
	  }

	getMotivoLectura(): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/motivo-lectura`, { params: null, headers: this.getHeader() });
	}

	getClaseLectura(): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/clase-lectura`, { params: null, headers: this.getHeader() });
	}

	getMotivoBaja(): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/motivo-baja`, { params: null, headers: this.getHeader() });
	}

	cambiarAMontaje(matchCambioEscenario: MatchCambioEscenarioBajaDesmontaje, idNegocio: number): Observable<any> {
		return this.http.post<any>(`${this.getUrl()}/cambio-montaje/${idNegocio}`, matchCambioEscenario, {headers: this.getHeader()});
	}

	cambiarADesmontaje(matchCambioEscenario: MatchCambioEscenarioBajaDesmontaje, idNegocio: number): Observable<any> {
		return this.http.post<any>(`${this.getUrl()}/cambio-desmontaje/${idNegocio}`, matchCambioEscenario, {headers: this.getHeader()});
	}

	cambiarABaja(matchCambioEscenario: MatchCambioEscenarioBajaDesmontaje, idNegocio: number): Observable<any> {
		return this.http.post<any>(`${this.getUrl()}/cambio-baja/${idNegocio}`, matchCambioEscenario, {headers: this.getHeader()});
	}

	cambiarARecontratado(matchCambioEscenario: MatchCambioEscenarioBajaDesmontaje, idNegocio: number): Observable<any> {
		return this.http.post<any>(`${this.getUrl()}/cambio-recontratado/${idNegocio}`, matchCambioEscenario, {headers: this.getHeader()});
	}
}

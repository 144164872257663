import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataWarehouse } from '../model/match/metadata-warehouse';
import { HttpClient } from '@angular/common/http';
import { Warehouse } from '../model/dto/warehouse';
import { Observable } from 'rxjs';
import { ResumenWarehouseToReturn } from '../model/dto/resumen/resumen-warehouse-to-return';
import { MetadataResumenWarehouseToReturn } from '../model/match/metadata-resumen-warehouse-to-return';
import { Search } from 'src/app/shared/components/search/search.component';

@Injectable({
	providedIn: 'root'
})
export class WarehouseService extends BaseService<Warehouse, ResumenWarehouseToReturn, MetadataWarehouse, MetadataResumenWarehouseToReturn> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'warehouse';
	}

	getWarehouseRelationedToUser(regionalId: number = null): Observable<Warehouse> {

		let urlAdm = "";
		let preAdm = "";

		if (regionalId != null) {
			urlAdm = "/" + regionalId;
		}

		if (urlAdm != "") {
			preAdm = "/admin";
		}
		return this.http.get<Warehouse>(`${this.getUrl()}${preAdm}/of-regional-relationed-to-user${urlAdm}`, { headers: this.getHeader() });
	}

	getResumenWarehouseToReturn(search: Search): Observable<MetadataResumenWarehouseToReturn> {
		return this.http.get<MetadataResumenWarehouseToReturn>(`${this.getUrl()}/resumen-to-return-gas-meter`, { params: search.params, headers: this.getHeader() });
	}

	darDeBaja(id: number): Observable<Warehouse> {
		return this.http.post<Warehouse>(`${this.getUrl()}/${id}/dar-de-baja-medidores`, {} , {headers: this.getHeader()});
	}
}

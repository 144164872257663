import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Instaladora } from '../model/dto/instaladora';
import { MetadataInstaladora } from '../model/match/metadata-instaladora';
import { MetadataCuadrilla } from '../model/match/metadata-cuadrilla';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MetadataVendedor } from '../model/match/metadata-vendedor';
import { MetadataResumenCuadrilla } from '../model/match/metadata-resumen-cuadrilla';
import { ResumenCuadrilla } from '../model/dto/resumen/resumen-cuadrilla';
import { MatchCreateCuadrilla } from '../model/match/match-create-cuadrilla';
import { MatchUpdateCuadrilla } from '../model/match/match-update-cuadrilla';
import { Search } from '../../shared/components/search/search.component';
import { Cuadrilla } from '../model/dto/cuadrilla';
import { Regionales } from '../model/dto/regionales';
import { File } from '../model/dto/file';
import { ResumenReportAgendamientoDeCuadrillaTabla2 } from '../model/dto/resumen/resumen-report-agendamiento-de-cuadrillas-tabla2';

@Injectable({
	providedIn: 'root'
})
export class CuadrillaService extends BaseService<Cuadrilla, ResumenCuadrilla, MetadataCuadrilla, MetadataResumenCuadrilla> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'cuadrilla';
	}

	create(matchCreateCuadrilla: MatchCreateCuadrilla): Observable<Cuadrilla> {
		return this.http.post<Cuadrilla>(`${this.getUrl()}/`, matchCreateCuadrilla, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateCuadrilla: MatchUpdateCuadrilla): Observable<Cuadrilla> {
		return this.http.put<Cuadrilla>(`${this.getUrl()}/${id}`, matchUpdateCuadrilla, { headers: this.getHeader() });
	}

	delete(id: number): Observable<Cuadrilla> {
		return this.http.delete<Cuadrilla>(`${this.getUrl()}/${id}`, { headers: this.getHeader() });
	}

	getListFullData(): Observable<MetadataResumenCuadrilla> {
		return this.http.get<MetadataResumenCuadrilla>(`${this.getUrl()}`, { headers: this.getHeader() });
	}

	getTipoServicioInstaladora(id: number): Observable<Regionales[]> {
		return this.http.get<Regionales[]>(`${this.getUrl()}/${id}/tiposervicio`, { headers: this.getHeader() });
	}

	getCuadrillaByID(id: number): Observable<Cuadrilla[]> {
		return this.http.get<Cuadrilla[]>(`${this.getUrl()}/${id}`, { headers: this.getHeader() });
	}

	getTiposServicios(id: number): Observable<Cuadrilla[]> {
		return this.http.get<Cuadrilla[]>(`${this.getUrl()}/${id}/tipos-servicios`, { headers: this.getHeader() });
	}

	getCuadrillaRegional(id: number): Observable<Cuadrilla[]> {
		return this.http.get<Cuadrilla[]>(`${this.getUrl()}/${id}/regionales`, { headers: this.getHeader() });
	}

	getCuadrillaTipoServicioInstaladora(id: number): Observable<Cuadrilla[]> {
		return this.http.get<Cuadrilla[]>(`${this.getUrl()}/${id}/tipo-servicio-instaladora`, { headers: this.getHeader() });
	}

	getMetadataInstaladorasSearch(search: Search): Observable<MetadataInstaladora> {
		return this.http.get<MetadataInstaladora>(`${this.getUrl()}`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataCuadrillaSearch(search: any): Observable<MetadataCuadrilla> {
		return this.http.get<MetadataCuadrilla>(`${this.getUrl()}`, { params: search, headers: this.getHeader() });
	}

	getFiles(id: number): Observable<File[]> {
		return this.http.get<File[]>(`${this.getUrl()}/${id}/file`, { params: null, headers: this.getHeader() });
	}

	deleteFile(id: number, idCuadrilla) {
		return this.http.delete(`${this.getUrl()}/${idCuadrilla}/file/${id}`, { headers: this.getHeader() });
	}

		//**************************************** */REPORTE 8: AGENDAMIENTO DE CUADRILLAS***************************************

	//TABLA 2: MANTENEDOR DE CUADRILLAS
	getReportAgendamientoDeCuadrillaTabla2(regionalesId: string, instaladorasId: string,
		nombreCuadrilla: string, responsableCuadrilla: string, statusGeneralId: string): Observable<ResumenReportAgendamientoDeCuadrillaTabla2[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("nombreCuadrilla", nombreCuadrilla);
		params = params.append("responsableCuadrilla", responsableCuadrilla);
		params = params.append("statusGeneralId", statusGeneralId);

		return this.http.get<ResumenReportAgendamientoDeCuadrillaTabla2[]>(`${this.getUrlReports()}/reporte-agendamiento-de-cuadrillas-tabla2`, { params: params, headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { ResumenColonia } from '../model/dto/resumen/resumen-colonia';
import { Observable } from 'rxjs';
import { MetadataResumenColonia } from '../model/match/metadata-resumen-colonia';
import { MetadataStreet } from '../model/match/metadata-street';
import { Search } from '../../shared/components/search/search.component';
import { Street } from '../model/dto/street';
import { ResumenStreet } from '../model/dto/resumen/resumen-street';
import { MatchCreateStreet } from '../model/match/match-create-street';
import { MatchUpdateStreet } from '../model/match/match-update-street';
import { MetadataResumenStreet } from '../model/match/metadata-resumen-street';

@Injectable({
	providedIn: 'root'
})
export class StreetService extends BaseService<Street, ResumenStreet, MetadataStreet, MetadataResumenStreet> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'street';
	}
	
	create(matchCreateStreet: MatchCreateStreet): Observable<Street> {
		return this.http.post<Street>(this.getUrl(), matchCreateStreet, {headers: this.getHeader()});
	}

	update(id: number, matchUpdateStreet: MatchUpdateStreet): Observable<Street> {
		return this.http.put<Street>(`${this.getUrl()}/${id}`, matchUpdateStreet, {headers: this.getHeader()});
	}

	delete(streetId: number): Observable<Street> {
		return this.http.delete<Street>(`${this.getUrl()}/${streetId}`, {headers: this.getHeader()});
	}

}
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MetadataEspecializacion } from '../model/match/especializacion';
import { Observable } from 'rxjs';
import { Especializacion } from '../model/dto/especializacion';

@Injectable({
	providedIn: 'root'
})

export class EspecializacionesService extends BaseService<Especializacion, Especializacion, MetadataEspecializacion, MetadataEspecializacion> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'especializacion';
    }
    
    getEspecializacionesToList() : Observable<any> {
        return this.http.get<any>(`${this.getUrl()}/to-options`, { headers: this.getHeader() });
      }
	
}
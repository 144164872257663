import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { tipoPreciosCostos } from '../model/dto/tipo-precios-costos';
import { MetadataTipoPreciosCostos } from '../model/match/metadata-tipo-precio-costo';
import { Search } from '../../shared/components/search/search.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class TipoPrecioCostoService extends BaseService<tipoPreciosCostos, tipoPreciosCostos, MetadataTipoPreciosCostos, MetadataTipoPreciosCostos> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tipo-precios-costos';
	}
	getTipoPreciosCostos(): Observable<tipoPreciosCostos> {
		return this.http.get<tipoPreciosCostos>(`${this.getUrl()}`, { headers: this.getHeader() });
	}
}

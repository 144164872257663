import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GasMeterType } from '../model/dto/gas-meter-type';
import { MetadataGasMeterType } from '../model/match/metadata-gas-meter-type';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatchCreateProveedor } from '../model/match/match-create-proveedor';
import { ResumenReportMantenedoresTabla5 } from '../model/dto/resumen/resumen-report-mantenedores-tabla5';

@Injectable({
  providedIn: 'root'
})
export class GasMeterProviderService extends BaseService<GasMeterType, GasMeterType, MetadataGasMeterType, MetadataGasMeterType> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'gas-meter-provider';
	}
}

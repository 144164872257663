import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataService } from '../model/match/metadata-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Service } from '../model/dto/service';
import { ResumenService } from '../model/dto/resumen/resumen-service';
import { MetadataResumenService } from '../model/match/metadata-resumen-service';
import { Search } from '../../shared/components/search/search.component';
import { MatchAsignarInstaladoraToService } from '../model/match/match-asignar-instaladora-to-service';
import { MatchSendToValidationToService } from '../model/match/match-send-to-validation-to-service';
import { MatchSaveDraftToValidationToService } from '../model/match/match-save-draft-to-validation-to-service';
import { MatchRejectToValidationToService } from '../model/match/match-reject-to-validation-to-service';
import { MatchAsignarCuadrillaToService } from '../model/match/match-asignar-cuadrilla-to-service';
import { ResumenServiceToDetalleService } from '../model/dto/resumen/resumen-service-to-detalle-service';
import { ResumenServiceToValidateService } from '../model/dto/resumen/resumen-service-to-validate-service';
import { ResumenServiceToUpdateCuadrilla } from '../model/dto/resumen/resumen-service-to-update-cuadrilla';
import { MetadataResumenIncidence } from '../model/match/metadata-resumen-incidence';
import { ResumenServiceToDataFailure, ResumenServiceToDataVialidad } from '../model/dto/resumen/resumen-service-to-data-failure';
import { Cuadrilla } from '../model/dto/cuadrilla';
import { MatchMoveToUnfeasibilityToService } from '../model/match/match-move-to-unfeasibility-to-service';
import { MatchMoveToFailureToService } from '../model/match/match-move-to-failure-to-service';
import { MatchApproveToValidationToService } from '../model/match/match-approve-to-validation-to-service';
import { MatchCreateIncidence } from '../model/match/match-create-incidence';
import { MetadataResumenSincronizacionServicio } from '../model/match/metadata-resumen-sincronizacion-servicio';
import { ResumenServicioSeccionClienteSap } from '../model/dto/resumen/resumen-servicio-seccion-cliente-sap';
import { ResumenServicioSeccionCuentaContratoSap } from '../model/dto/resumen/resumen-servicio-seccion-cuenta-contrato-sap';
import { ResumenServicioSeccionObjetoConexionSap } from '../model/dto/resumen/resumen-servicio-seccion-objeto-conexion-sap';
import { ResumenServicioSeccionPuntoConsumoSap } from '../model/dto/resumen/resumen-servicio-seccion-punto-consumo-sap';
import { ResumenServicioSeccionExtensionRolSap } from '../model/dto/resumen/resumen-servicio-seccion-extension-rol-sap';
import { ResumenServicioSeccionOrdenVentaSap } from '../model/dto/resumen/resumen-servicio-seccion-orden-venta-sap';
import { ResumenServicioSeccionErroresSap } from '../model/dto/resumen/resumen-servicio-seccion-errores-sap';
import { ResumenServicioSeccionMoveInSap } from '../model/dto/resumen/resumen-servicio-seccion-move-in-sap';
import { ResumenServicioSeccionPesSap } from '../model/dto/resumen/resumen-servicio-seccion-pes-sap';
import { ServiceTipoDocumento } from '../model/dto/service-tipo-documento';
import { MatchBulkApproveValidacionToService } from '../model/match/match-bulk-approve-validation-to-service';
import { ResumenReportInstalacionesInternas } from '../model/dto/resumen/resumen-report-instalaciones-internas';
import { MetadataResumenSupervisionService } from '../model/match/metadata-resumen-supervision-service';
import { ServiceByFolio } from '../model/dto/resumen/serviceByFolio';
import { StatusTypeResponse } from '../model/dto/resumen/statusTypeResponse';
import { UpdateStatusServicePayload } from '../model/payload/updateStatusServicePayload';

@Injectable({
	providedIn: 'root'
})
export class ServiceService extends BaseService<Service, ResumenService, MetadataService, MetadataResumenService> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'service';
	}

	getAcometidasListToOptions(serviceId: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${serviceId}/acometidas/list-to-options`, { headers: this.getHeader() });
	}

	getMetadataResumenBandejaListSearch(search: Search): Observable<MetadataResumenService> {
		return this.http.get<MetadataResumenService>(`${this.getUrl()}/bandeja/resumen`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataResumenBandejaListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/bandeja/resumen/v2`, { params: search.params, headers: this.getHeader() });
	}

	enviarAValidacion(serviceId: number, matchSendToValidationToService: MatchSendToValidationToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${serviceId}/enviar-a-validacion`, matchSendToValidationToService, { headers: this.getHeader() });
	}

	enviarAAprobacion(serviceId: number, matchApproveToValidationToService: MatchApproveToValidationToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${serviceId}/approve-service/no-change-gas-meter`, matchApproveToValidationToService, { headers: this.getHeader() });
	}

	enviarAAprobacionChangeGasMeterByDefective(serviceId: number, matchApproveToValidationToService: MatchApproveToValidationToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${serviceId}/approve-service/change-gas-meter-by-defective`, matchApproveToValidationToService, { headers: this.getHeader() });
	}

	enviarAAprobacionChangeGasMeterByMistake(serviceId: number, matchApproveToValidationToService: MatchApproveToValidationToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${serviceId}/approve-service/change-gas-meter-by-mistake`, matchApproveToValidationToService, { headers: this.getHeader() });
	}

	guadarBorrador(serviceId: number, matchSaveDraftToValidationToService: MatchSaveDraftToValidationToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${serviceId}/save-draft/no-change-gas-meter`, matchSaveDraftToValidationToService, { headers: this.getHeader() });
	}

	guadarBorradorChangeGasMeterByDefective(serviceId: number, matchSaveDraftToValidationToService: MatchSaveDraftToValidationToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${serviceId}/save-draft/change-gas-meter-by-defective`, matchSaveDraftToValidationToService, { headers: this.getHeader() });
	}

	guadarBorradorChangeGasMeterByMistake(serviceId: number, matchSaveDraftToValidationToService: MatchSaveDraftToValidationToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${serviceId}/save-draft/change-gas-meter-by-mistake`, matchSaveDraftToValidationToService, { headers: this.getHeader() });
	}

	rechazar(serviceId: number, matchRejectToValidationToService: MatchRejectToValidationToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${serviceId}/reject`, matchRejectToValidationToService, { headers: this.getHeader() });
	}

	moverAEnProcesoDeInstalacion(serviceId: number): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${serviceId}/mover-a-en-proceso-de-instalacion`, null, { headers: this.getHeader() });
	}
  setSupervisorToService(serviceId: number,supervisorId: number): Observable<any> {
		return this.http.put<Service>(`${this.getUrl()}/${serviceId}/${supervisorId}/set-supervisor-to-service`, null, { headers: this.getHeader() });
	}

	asignarInstaladora(id: number, matchAsignarInstaladoraToService: MatchAsignarInstaladoraToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${id}/asignar-instaladora`, matchAsignarInstaladoraToService, { headers: this.getHeader() });
	}

	reasignarInstaladora(id: number, matchAsignarInstaladoraToService: MatchAsignarInstaladoraToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${id}/reasignar-instaladora`, matchAsignarInstaladoraToService, { headers: this.getHeader() });
	}

	getDetailService(serviceId: number): Observable<ResumenServiceToDetalleService> {
		return this.http.get<ResumenServiceToDetalleService>(`${this.getUrl()}/${serviceId}/resumen-to-detail-service`, { headers: this.getHeader() });
	}

	getDetailToValidateService(serviceId: number, showSuccessService: boolean = false): Observable<ResumenServiceToValidateService> {
    let params: HttpParams = new HttpParams();

    if (showSuccessService) {
        params = params.append('showSuccessService', 'true');
    }

		return this.http.get<ResumenServiceToValidateService>(`${this.getUrl()}/${serviceId}/resumen-to-validate-service`, { headers: this.getHeader(), params});
	}

	contratoConServiciosEnStatusNoViable(folio: string): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${folio}/tiene_en_status_no_viable`, { headers: this.getHeader() });
	}

	getDetailToUpdateCuadrilla(serviceId: number): Observable<ResumenServiceToUpdateCuadrilla> {
		return this.http.get<ResumenServiceToUpdateCuadrilla>(`${this.getUrl()}/${serviceId}/resumen-to-update-cuadrilla`, { headers: this.getHeader() });
	}

	getCuadrillasDisponiblesParaAsignarAServicio(serviceId: number): Observable<Cuadrilla[]> {
		return this.http.get<Cuadrilla[]>(`${this.getUrl()}/${serviceId}/cuadrillas-availables-to-assign-to-service`, { headers: this.getHeader() });
	}

	getIncidences(serviceId: number, search: Search = null): Observable<MetadataResumenIncidence> {
		if (search != null && search != undefined) {
			return this.http.get<MetadataResumenIncidence>(`${this.getUrl()}/${serviceId}/incidences`, { params: search.params, headers: this.getHeader() });
		} else {
			return this.http.get<MetadataResumenIncidence>(`${this.getUrl()}/${serviceId}/incidences`, { headers: this.getHeader() });
		}
	}

	//SUPERVISION
	getResumenSupervision(serviceId: number, search: Search): Observable<MetadataResumenSupervisionService> {
		if (search != null && search != undefined) {
			return this.http.get<MetadataResumenSupervisionService>(`${this.getUrl()}/${serviceId}/supervisiones`, { params: search.params, headers: this.getHeader() });
		} else {
			return this.http.get<MetadataResumenSupervisionService>(`${this.getUrl()}/${serviceId}/supervisiones`, { headers: this.getHeader() });
		}
	}

	marcarIncidenciaComoNoAtendida(id: number, idIncidencia: number): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}/incidences/${idIncidencia}/marcar-como-no-atendida`, {}, { headers: this.getHeader() });
	}

	marcarIncidenciaComoAtendida(id: number, idIncidencia: number, data: any): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}/incidences/${idIncidencia}/marcar-como-atendida`, data, { headers: this.getHeader() });
	}

	cerrarIncidencia(id: number, idIncidencia: number, data: any): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}/incidences/${idIncidencia}/cerrar-incidencia`, data, { headers: this.getHeader() });
	}

	getDataFailure(serviceRelatedId: number): Observable<ResumenServiceToDataFailure> {
		return this.http.get<ResumenServiceToDataFailure>(`${this.getUrl()}/${serviceRelatedId}/resumen-data-failure`, { headers: this.getHeader() });
	}

	getDataVialidad(serviceRelatedId: number): Observable<ResumenServiceToDataVialidad> {
		return this.http.get<ResumenServiceToDataVialidad>(`${this.getUrl()}/${serviceRelatedId}/resumen-data-vialidad`, { headers: this.getHeader() });
	}

	asignarCuadrilla(id: number, matchAsignarCuadrillaToService: MatchAsignarCuadrillaToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${id}/asignar-cuadrilla`, matchAsignarCuadrillaToService, { headers: this.getHeader() });
	}

	reasignarCuadrilla(id: number, matchAsignarCuadrillaToService: MatchAsignarCuadrillaToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${id}/reasignar-cuadrilla`, matchAsignarCuadrillaToService, { headers: this.getHeader() });
	}

	MoveToUnfeasibilityToService(id: number, matchMoveToUnfeasibilityToService: MatchMoveToUnfeasibilityToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${id}/mover-a-no-viable`, matchMoveToUnfeasibilityToService, { headers: this.getHeader() });
	}

	MoveToFailureToService(id: number, matchMoveToFailureToService: MatchMoveToFailureToService): Observable<Service> {
		return this.http.put<Service>(`${this.getUrl()}/${id}/mover-a-fallido`, matchMoveToFailureToService, { headers: this.getHeader() });
	}

	createIncidence(id: number, matchCreateIncidence: MatchCreateIncidence): Observable<Service> {
		return this.http.post<Service>(`${this.getUrl()}/${id}/incidence`, matchCreateIncidence, { headers: this.getHeader() });
	}

	getCancelarServicio(serviceId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${serviceId}/cancelar`, { headers: this.getHeader() });
	}

	getMetadataResumenListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen/v2`, { params: search.params, headers: this.getHeader() });
	}

	//DOCUMENTOS
	getTiposDocumentos(serviceId: number): Observable<ServiceTipoDocumento[]> {
		return this.http.get<ServiceTipoDocumento[]>(`${this.getUrl()}/${serviceId}/resumen-documentos-service`, { headers: this.getHeader() });
	}

	deleteTipoDocumento(serviceId: number, fileId: number): Observable<any> {
		return this.http.delete<any>(`${this.getUrl()}/${serviceId}/file/${fileId}`, { headers: this.getHeader() });
	}

	//APROBACION MASIVAMENTE DE SERVICIOS
	bulkApproveService(matchBulkApproveValidacionToService: MatchBulkApproveValidacionToService): Observable<Service[]> {
		return this.http.put<Service[]>(`${this.getUrl()}/bulk-approve-service`, matchBulkApproveValidacionToService, { headers: this.getHeader() });
	}

	//SAP ENVIAR
	reenviarASap(id: number) {
		return this.http.post<any>(`${this.getUrl()}/${id}/reenviar-a-sap`, {}, { headers: this.getHeader() });
	}

	//SAP MARCAR COMO CORREGIDO
	marcarComoCorregidoSap(id: number) {
		return this.http.put<any>(`${this.getUrl()}/${id}/marcar-como-corregido`, {}, { headers: this.getHeader() });
	}

	//SAP
	getMetadataResumenSincronizacionListSearch(search: Search): Observable<MetadataResumenSincronizacionServicio> {
		return this.http.get<MetadataResumenSincronizacionServicio>(`${this.getUrl()}/sincronizacion-servicios`, { params: search.params, headers: this.getHeader() });
	}

	//SAP CLIENTE
	getResumenServicioSeccionClienteSap(servicioId: number): Observable<ResumenServicioSeccionClienteSap> {
		return this.http.get<ResumenServicioSeccionClienteSap>(`${this.getUrl()}/${servicioId}/resumen-sincronizacion-cliente`, { headers: this.getHeader() });
	}

	//SAP CUENTA CONTRATO
	getResumenServicioSeccionCuentaContratoSap(servicioId: number): Observable<ResumenServicioSeccionCuentaContratoSap> {
		return this.http.get<ResumenServicioSeccionCuentaContratoSap>(`${this.getUrl()}/${servicioId}/resumen-sincronizacion-cuenta-contrato`, { headers: this.getHeader() });
	}

	//SAP OBJETO CONEXION
	getResumenServicioSeccionObjetoConexionSap(servicioId: number): Observable<ResumenServicioSeccionObjetoConexionSap> {
		return this.http.get<ResumenServicioSeccionObjetoConexionSap>(`${this.getUrl()}/${servicioId}/resumen-sincronizacion-objeto-conexion`, { headers: this.getHeader() });
	}

	//SAP PUNTO CONSUMO
	getResumenServicioSeccionPuntoConsumoSap(servicioId: number): Observable<ResumenServicioSeccionPuntoConsumoSap> {
		return this.http.get<ResumenServicioSeccionPuntoConsumoSap>(`${this.getUrl()}/${servicioId}/resumen-sincronizacion-punto-consumo`, { headers: this.getHeader() });
	}

	//SAP EXTENSION ROL
	getResumenServicioSeccionExtensionRolSap(servicioId: number): Observable<ResumenServicioSeccionExtensionRolSap> {
		return this.http.get<ResumenServicioSeccionExtensionRolSap>(`${this.getUrl()}/${servicioId}/resumen-sincronizacion-extension-rol`, { headers: this.getHeader() });
	}

	//SAP ORDEN DE VENTA
	getResumenServicioSeccionOrdenDeVentaSap(servicioId: number): Observable<ResumenServicioSeccionOrdenVentaSap> {
		return this.http.get<ResumenServicioSeccionOrdenVentaSap>(`${this.getUrl()}/${servicioId}/resumen-sincronizacion-orden-venta`, { headers: this.getHeader() });
	}

	//SAP MOVE IN
	getResumenServicioSeccionMoveInSap(servicioId: number): Observable<ResumenServicioSeccionMoveInSap> {
		return this.http.get<ResumenServicioSeccionMoveInSap>(`${this.getUrl()}/${servicioId}/resumen-sincronizacion-move-in`, { headers: this.getHeader() });
	}

	//SAP PES
	getResumenServicioSeccionPesSap(servicioId: number): Observable<ResumenServicioSeccionPesSap> {
		return this.http.get<ResumenServicioSeccionPesSap>(`${this.getUrl()}/${servicioId}/resumen-sincronizacion-pes`, { headers: this.getHeader() });
	}

	//SAP ERRORES
	getResumenServicioSeccionErroresSap(servicioId: number): Observable<ResumenServicioSeccionErroresSap> {
		return this.http.get<ResumenServicioSeccionErroresSap>(`${this.getUrl()}/${servicioId}/resumen-sincronizacion-errores`, { headers: this.getHeader() });
	}

	//REPORTE LAYOUT INSTALACION INTERNA
	getReportLayoutInstalacionI(regionalId: string, desde: string, hasta: string): Observable<ResumenReportInstalacionesInternas[]> {

		let params = new HttpParams();

		params = params.append("regionalId", regionalId);
		params = params.append("desde", desde);
		params = params.append("hasta", hasta);

		return this.http.get<ResumenReportInstalacionesInternas[]>(`${this.getUrlReports()}/reporte-instalaciones-internas`, { params: params, headers: this.getHeader() });
	}

	editarServicio(id: number, body: any) {
		return this.http.put<any>(`${this.getUrl()}/${id}`, body, { headers: this.getHeader() });
	}

	//********************************************** REPORTE FENIZ II *******************************************

	//********************************************** FII_REPORTE 1: AVANCE COMERCIAL*******************************************
	getReportAvanceComercial(regionalesId: string, municipiosId: string, zonaId: number, proyectoId: number,
		tipoProyectoId: number, elementoPepId: number, statusProyectoId: number, nroPlano: string, desdeFechaCargaProyecto: string,
		hastaFechaCargaProyecto: string): Observable<any[]> {

		let params = new HttpParams();

		if (regionalesId) {
			params = params.append("regionalesId", regionalesId);
		}

		if (municipiosId) {
			params = params.append("municipiosId", municipiosId);
		}

		if (zonaId && zonaId > 0) {
			params = params.append("zonaId", zonaId + "");
		}

		if (proyectoId && proyectoId > 0) {
			params = params.append("proyectoId", proyectoId+ "");
		}

		if (tipoProyectoId && tipoProyectoId > 0) {
			params = params.append("tipoProyectoId", tipoProyectoId + "");
		}

		if (elementoPepId && elementoPepId > 0) {
			params = params.append("elementoPepId", elementoPepId + "");
		}

		if (statusProyectoId && statusProyectoId > 0) {
			params = params.append("statusProyectoId", statusProyectoId + "");
		}

		params = params.append("nroPlano", nroPlano);
		params = params.append("desdeFechaCargaProyecto", desdeFechaCargaProyecto);
		params = params.append("hastaFechaCargaProyecto", hastaFechaCargaProyecto);

		return this.http.get<any[]>(`${this.getUrlReports()}/reporte-avance-comercial`, { params: params, headers: this.getHeader() });
	}

  getByFolio(folio: string): Observable<ServiceByFolio[]> {
		return this.http.get<ServiceByFolio[]>(`${this.getUrl()}/by-folio/${folio}`, { headers: this.getHeader() });
	}

  getStatusType(): Observable<StatusTypeResponse> {
		return this.http.get<StatusTypeResponse>(`${this.getUrl()}/status`, { headers: this.getHeader() });
	}

  updateStatusType(id: number, body: UpdateStatusServicePayload) {
		return this.http.put(`${this.getUrl()}/contract/${id}`, body, { headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { Subdomain } from '../model/dto/subdomain';
import { MetadataSubdomain } from '../model/match/metadata-subdomain';
import { MatchCreateSubdomain } from '../model/match/match-create-subdomain';
import { MatchUpdateSubdomain } from '../model/match/match-update-subdomain';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SubdomainService extends BaseService<Subdomain, Subdomain, MetadataSubdomain, MetadataSubdomain> {


	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'subdomain';
	}

	create(matchCreatesubdomain: MatchCreateSubdomain): Observable<Subdomain> {
		return this.http.post<Subdomain>(this.getUrl(), matchCreatesubdomain, { headers: this.getHeader() });
	}

	update(id: number, MatchUpdateSubdomain: MatchUpdateSubdomain): Observable<Subdomain> {
		return this.http.put<Subdomain>(`${this.getUrl()}/${id}`, MatchUpdateSubdomain, { headers: this.getHeader() });
	}

}
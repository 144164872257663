import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataService } from '../model/match/metadata-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '../../shared/components/search/search.component';
import { ClienteActivo } from '../model/dto/cliente-activo';
import { MetadataClienteActivo } from '../model/match/metadata-cliente-activo';

@Injectable({
	providedIn: 'root'
})
export class ClienteActivoService extends BaseService<ClienteActivo, ClienteActivo, MetadataClienteActivo, MetadataClienteActivo> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'cliente-activo';
	}

	getPointsToMap(longitud: string, latitud: string, tipoCliente: number = null): Observable<any> {
		let params = new HttpParams();

		params = params.append("latitudCenter", latitud);
		params = params.append("longitudCenter", longitud);

		if (tipoCliente) {
			params = params.append("tipoCliente",  "" + tipoCliente);
		}

		return this.http.get<any>(`${this.getUrl()}/map/show`, { params: params, headers: this.getHeader() });
	}

	getAllPointsToMap() {
	  return this.http.get<any>(`${this.getUrl()}/map/show/all`, {headers: this.getHeader()});
  }
}

import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Search} from '../../shared/components/search/search.component';
import {Lead} from '../model/dto/lead';
import {MetadataLead} from '../model/match/metadata-lead';
import {MatchCreateLead} from '../model/match/match-create-lead';
import {MatchUpdateLead} from '../model/match/match-update-lead';
import {MatchDireccionBody} from '../model/match/match-direccion-body';
import {MatchDuplicidadTelEmail} from '../model/match/match-duplicidad-tel-email';

@Injectable({
  providedIn: 'root'
})
export class LeadService extends BaseService<Lead, Lead, MetadataLead, MetadataLead> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getClassUrl(): string {
    return 'lead';
  }

  create(matchCreateLead: MatchCreateLead): Observable<any> {
    return this.http.post<any>(this.getUrl(), matchCreateLead, {headers: this.getHeader()});
  }

  getDetalleLead(leadId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${leadId}/detalle-lead`, {headers: this.getHeader()});
  }

  getHistoricoActividades(leadId: number, search: Search): Observable<any> {
	return this.http.get<any>(`${this.getUrl()}/${leadId}/historico-actividades`, {params: search.params, headers: this.getHeader() });
  }

  update(matchUpdateLead: MatchUpdateLead): Observable<any> {
    return this.http.put<any>(this.getUrl(), matchUpdateLead, {headers: this.getHeader()});
  }

  asignarLead(leadId: number, vendedorId: number, matchUpdateLead: MatchUpdateLead): Observable<any> {
    return this.http.put<any>(`${this.getUrl()}/${leadId}/send-lead-to-seller/${vendedorId}`, matchUpdateLead, {headers: this.getHeader()});
  }

  getMetadataResumenListSearchV2(search: Search): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/resumen/v2`, {params: search.params, headers: this.getHeader()});
  }

  getVendedoresListToOptionsByRegional(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.getUrl()}/${id}/vendedores/list-to-options`, {headers: this.getHeader()});
  }

  editLead(leadId: number, matchCreateLead: MatchCreateLead): Observable<any> {
    return this.http.put<any>(`${this.getUrl()}/${leadId}`, matchCreateLead, {headers: this.getHeader()});
  }

  updateEstadoLead(leadId: number, estatusLead: number, motivo:string): Observable<any> {
    return this.http.put<any>(`${this.getUrl()}/${leadId}/status-lead/${estatusLead}/motivo/${motivo}`,null, {headers: this.getHeader()});
  }

  removeLead(leadId: number): Observable<any> {
    return this.http.delete(`${this.getUrl()}/${leadId}`, {headers: this.getHeader()});
  }

  getAllStates(): Observable<any> {
    return this.http.get(`${this.getUrl()}/states`, {headers: this.getHeader()});
  }

  getAllOrigins(): Observable<any> {
    return this.http.get(`${this.getUrl()}/origins`, {headers: this.getHeader()});
  }

  generateReportLeadsAndUpdateDashboard(emailAEnviar: string, regionalId: string, origenId: string, estadoId: string, municipioId: string,
                                        fechaCreacion: string, fechaCreacionLimite: string, fechaAsignacion: string,
                                        fechaAsignacionLimite: string, fechaUltimaReasignacion: string,
                                        fechaUltimaReasignacionLimite: string, nombreVendedorAsignado: string, instaladoraId: string,
                                        statusLeadId: string, statusClienteId: string, supervisorDeVentaId: string, zonaId: string,
                                        microzonaId: string, nombreRuta: string, fechaValidacionADV: string,
                                        fechaValidacionADVLimite: string): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('emailAEnviar', emailAEnviar);
    params = params.append('regionalId', regionalId);
    params = params.append('origenId', origenId);
    params = params.append('estadoId', estadoId);
    params = params.append('municipioId', municipioId);
    params = params.append('fechaCreacion', fechaCreacion);
    params = params.append('fechaCreacionLimite', fechaCreacionLimite);
    params = params.append('fechaAsignacion', fechaAsignacion);
    params = params.append('fechaAsignacionLimite', fechaAsignacionLimite);
    params = params.append('fechaUltimaReasignacion', fechaUltimaReasignacion);
    params = params.append('fechaUltimaReasignacionLimite', fechaUltimaReasignacionLimite);
    params = params.append('nombreVendedorAsignado', nombreVendedorAsignado);
    params = params.append('instaladoraId', instaladoraId);
    params = params.append('statusLeadId', statusLeadId);
    params = params.append('statusClienteId', statusClienteId);
    params = params.append('supervisorDeVentaId', supervisorDeVentaId);
    params = params.append('zonaId', zonaId);
    params = params.append('microzonaId', microzonaId);
    params = params.append('ruta', nombreRuta);
    params = params.append('fechaValidacionADV', fechaValidacionADV);
    params = params.append('fechaValidacionADVLimite', fechaValidacionADVLimite);
    console.log(params);
    return this.http.get<any[]>(`${this.getUrl()}/reporte-lead-update-dashboard`, {params: params, headers: this.getHeader()});
  }

  getMotivosRechazo(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/v1/motivos-rechazos`, { headers: this.getHeader()});
  }

  findDireccion(matchDireccionBody: MatchDireccionBody) {
    return this.http.post<any>(`${this.getUrl()}/find-lead-direccion`, matchDireccionBody ,{headers: this.getHeader()});
	}
  
  duplicidadTelEmail(matchDuplicidadTelEmail: MatchDuplicidadTelEmail): Observable<any> {    
    return this.http.post<any>(`${this.getUrl()}/duplicidad-tel-email`, matchDuplicidadTelEmail,{headers: this.getHeader()});
	}

}

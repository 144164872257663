import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataStatusActividadNegocio } from '../model/match/metadata-status-actividad-negocio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StatusActividadNegocio } from '../model/dto/status-actividad-negocio';

@Injectable({
  providedIn: 'root'
})
export class StatusActividadNegocioService extends BaseService<StatusActividadNegocio, StatusActividadNegocio, MetadataStatusActividadNegocio, MetadataStatusActividadNegocio> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getClassUrl(): string {
    return 'status-actividad-negocio';
  }
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { TipoInmueble } from '../model/dto/tipo-inmueble';
import { MetadataTipoInmueble } from '../model/match/metadata-tipo-inmueble';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class TipoDeInmuebleService extends BaseService<TipoInmueble, TipoInmueble, MetadataTipoInmueble, MetadataTipoInmueble> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tipo-inmueble';
	}

	getTipoDeInmueble(): Observable<TipoInmueble> {
		return this.http.get<TipoInmueble>(`${this.getUrl()}`, { headers: this.getHeader() });
	}

}

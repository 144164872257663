import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ElementoPep } from '../model/dto/elemento-pep';
import { MetadataElementoPep } from '../model/match/metadata-elemento-pep';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ElementoPEPService extends BaseService<ElementoPep, ElementoPep, MetadataElementoPep, MetadataElementoPep> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'elemento-pep';
	}

	getListToOptions(): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader() });
	}
	
	update(id: number, dto): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}`, dto, {headers: this.getHeader()});
	}

	create(dto): Observable<any> {
		return this.http.post<any>(this.getUrl(), dto, {headers: this.getHeader()});
	}

	//******************************************************* */REPORTE 9: MANTENEDORES***************************************************

	//TABLA 6: ELEMENTOS PEP	
	getReportMantenedoresTabla6(regionalesId: string, statusGeneralId: string): Observable<any[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("statusGeneralId", statusGeneralId);

		return this.http.get<any[]>(`${this.getUrlReports()}/reporte-mantenedores-tabla6`, { params: params, headers: this.getHeader() });
	}
}

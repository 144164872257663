import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Vendedor } from '../model/dto/vendedor';
import { MetadataVendedor } from '../model/match/metadata-vendedor';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '../../shared/components/search/search.component';
import { MatchCreateVendedor } from '../model/match/match-create-vendedor';
import { ResumenVendedor } from '../model/dto/resumen/resumen-vendedor';
import { MetadataResumenVendedor } from '../model/match/metadata-resumen-vendedor';
import { File } from '../model/dto/file';
import { ResumenReportVentasTabla2 } from '../model/dto/resumen/resumen-report-ventas-tabla2';
import { MetadataResumenVendedorToPersistMicrozona } from '../model/match/metadata-resumen-vendedor-to-persist-microzona';
import { MatchDesactivarVendedor } from '../model/match/match-desactivar-vendedor';

@Injectable({
	providedIn: 'root'
})
export class VendedorService extends BaseService<Vendedor, ResumenVendedor, MetadataVendedor, MetadataResumenVendedor> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'vendedor';
	}

	create(matchCreateVendedor: MatchCreateVendedor): Observable<Vendedor> {
		return this.http.post<Vendedor>(this.getUrl(), matchCreateVendedor, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateVendedor: MatchCreateVendedor): Observable<Vendedor> {
		return this.http.put<Vendedor>(`${this.getUrl()}/${id}`, matchUpdateVendedor, { headers: this.getHeader() });
	}	

	getMetadataResumenListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen/v2`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataResumenListObjetivos(vendedorId: number, search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${vendedorId}/objetivos`, { params: search.params, headers: this.getHeader() });
	}

	getRecorridoDetalleVendedor(vendedorId: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${vendedorId}/objetivo/detalle-vendedor`, { headers: this.getHeader() });
	}

	getFechasObjetivos(vendedorId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${vendedorId}/fechas-objetivos`, { headers: this.getHeader() });
	}

	getMetadataVendedoresAvailablesToAssignToAddress(direccionClienteId: number, search: Search): Observable<MetadataResumenVendedor> {
		return this.http.get<MetadataResumenVendedor>(`${this.getUrl()}/vendedores-availables-to-assign-to-address/${direccionClienteId}`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataVendedoresSearch(search: Search): Observable<MetadataVendedor> {
		return this.http.get<MetadataVendedor>(`${this.getUrl()}`, { params: search.params, headers: this.getHeader() });
	}

	getListRutasMantenedor(vendedorId: number, search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${vendedorId}/mantenedor/rutas`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataVendedoresParaAsignarProspecto(page: number = -1, limit: number = -1, clave_ilike: string, nombres_ilike: string, apellidos_ilike: string): Observable<MetadataVendedor> {
		let params = new HttpParams();

		if (clave_ilike) {
			params = params.append("clave_ilike", clave_ilike);
		}

		if (nombres_ilike) {
			params = params.append("nombres_ilike", nombres_ilike);
		}

		if (apellidos_ilike) {
			params = params.append("apellidos_ilike", apellidos_ilike);
		}

		params = params.append("page", page.toString());
		params = params.append("max", limit.toString());
		return this.http.get<MetadataVendedor>(`${this.getUrl()}`, { headers: this.getHeader(), params: params });
	}

	getMetadataVendedores(page: number = -1, limit: number = -1, rfc_ilike: string, nombres_ilike: string, apellidos_ilike: string): Observable<MetadataVendedor> {
		let params = new HttpParams();

		if (rfc_ilike) {
			params = params.append("rfc_ilike", rfc_ilike);
		}

		if (nombres_ilike) {
			params = params.append("nombres_ilike", nombres_ilike);
		}

		if (apellidos_ilike) {
			params = params.append("apellidos_ilike", apellidos_ilike);
		}

		params = params.append("page", page.toString());
		params = params.append("max", limit.toString());
		return this.http.get<MetadataVendedor>(`${this.getUrl()}`, { headers: this.getHeader(), params: params });
	}

	getMetadataVendedoresAll(search: Search, filter: any): Observable<MetadataVendedor> {
		return this.http.post<MetadataVendedor>(`${this.getUrl()}`, filter, { params: search.params, headers: this.getHeader() });
	}

	getFiles(id: number): Observable<File[]> {
		return this.http.get<File[]>(`${this.getUrl()}/${id}/file`, { params: null, headers: this.getHeader() });
	}

	getVendedorEspecializacion(id: number): Observable<File[]> {
		return this.http.get<File[]>(`${this.getUrl()}/${id}/especializacion-to-options`, { params: null, headers: this.getHeader() });
	}
	

	deleteFile(id: number, idVendedor) {
		return this.http.delete(`${this.getUrl()}/${idVendedor}/file/${id}`, { headers: this.getHeader() });
	}

	deleteProfilePicture(id: number) {
		return this.http.delete(`${this.getUrl()}/${id}/profile-picture`, { headers: this.getHeader() });
	}

	getMetadataVendedoresPersistMicrozona(instaladoraId: number, vendedoresIdToExclude: string, search: Search): Observable<MetadataResumenVendedorToPersistMicrozona> {
		return this.http.get<MetadataResumenVendedorToPersistMicrozona>(`${this.getUrl()}/${instaladoraId}/vendedores-resumen-to-persist-microzona/exclude/${vendedoresIdToExclude}`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataVendedoresPersistMicrozonaAddMicrozonaAddMicrozona(instaladoraId: number,vendedoresIdToExclude: string, microzonaId: number, search: Search): Observable<MetadataResumenVendedorToPersistMicrozona> {
		return this.http.get<MetadataResumenVendedorToPersistMicrozona>(`${this.getUrl()}/${instaladoraId}/vendedores-resumen-to-persist-microzona/exclude/${vendedoresIdToExclude}/add/${microzonaId}`, { params: search.params, headers: this.getHeader() });
	}

	getMotivosDesactivacion(): Observable<any> {
		return this.http.get<any[]>(`${this.getUrl()}/motivos-desactivacion`, { headers: this.getHeader() });
	}

	delete(vendedorId: number, matchDesactivarVendedor: MatchDesactivarVendedor): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${vendedorId}/desactivar`, matchDesactivarVendedor, { headers: this.getHeader() });
	}

	reactivar(vendedorId: number): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${vendedorId}/reactivar`, {}, { headers: this.getHeader() });
	}

	getSignature(id: number): Observable<Blob> {
	  return this.http.get(`${this.getUrl()}/${id}/signature`, {responseType: "blob", headers: this.getHeader()});
	}
  	deleteSignature(id: number): Observable<any> {
	  return this.http.delete<any>(`${this.getUrl()}/${id}/signature`, { headers: this.getHeader() });
	}
	//******************************************************* */REPORTE 6: VENDEDORES***************************************************

	//TABLA 2: VENDEDORES
	getReportVentasTabla2(regionalesId: string, instaladorasId: string,
		nombreVendedor: string, claveVendedor: string, statusGeneralId: string, anioId: string): Observable<ResumenReportVentasTabla2[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("nombreVendedor", nombreVendedor);
		params = params.append("claveVendedor", claveVendedor);
		params = params.append("statusGeneralId", statusGeneralId);
		params = params.append("year", anioId);

		return this.http.get<ResumenReportVentasTabla2[]>(`${this.getUrlReports()}/reporte-ventas-tabla2`, { params: params, headers: this.getHeader() });
	}

	getMetadataVendedoresDevice(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen-devices`, { params: search.params, headers: this.getHeader() });
	}

  getVendedor(vendedorId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${vendedorId}`, { headers: this.getHeader() });
  }

  getRutasVendedor(vendedorId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${vendedorId}/rutas`, { headers: this.getHeader() });
  }

  getMapShowAll(vendedorId: number, fechaInicio: string, fechaFin: string): Observable<any> {
	let params = new HttpParams();

	params = params.append("fecha_inicio", fechaInicio);
	params = params.append("fecha_fin", fechaFin);

    return this.http.get<any>(`${this.getUrl()}/${vendedorId}/map/show-all`, { params: params, headers: this.getHeader() });
  }

}

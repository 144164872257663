import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataNegocio } from '../model/match/metadata-negocio';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Negocio } from '../model/dto/negocio';
import { MatchCreateNegocioToEngie } from '../model/match/match-create-negocio-to-engie';
import { MatchCreateNegocioToInstaladora } from '../model/match/match-create-negocio-to-instaladora';
import { ResumenNegocio } from '../model/dto/resumen/resumen-negocio';
import { MetadataResumenActividadNegocio } from '../model/match/metadata-resumen-actividad-negocio';
import { MetadataResumenContratoToDetalleNegocio } from '../model/match/metadata-resumen-contrato-to-detalle-negocio';
import { MetadataResumenWorkflowValidacion } from '../model/match/metadata-resumen-workflow-validacion';
import { MetadataResumenNegocio } from '../model/match/metadata-resumen-negocio';
import { ResumenActividadNegocio } from '../model/dto/resumen/resumen-actividad-negocio';
import { Search } from '../../shared/components/search/search.component';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService<any, any, any, any> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getClassUrl(): string {
    return 'dashboard';
  }

  //CLIENTES
  getClientesRegistrados(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/registrados`, { headers: this.getHeader() });
  }

  getClientesDesactivados(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/desactivados`, { headers: this.getHeader() });
  }

  getClientesSinDirecciones(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/sin-direcciones`, { headers: this.getHeader() });
  }

  getClientesDireccionesTecnicas(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/direcciones-tecnicas`, { headers: this.getHeader() });
  }

  getClientesDireccionesComerciales(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/direcciones-comerciales`, { headers: this.getHeader() });
  }

  getClientesDireccionesPendientesPorAsignarInstaladoras(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/direcciones-pendientes-por-asignar-instaladora`, { headers: this.getHeader() });
  }

  getClientesDireccionesPendientesPorAsignarVendedores(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/direcciones-pendientes-por-asignar-vendedor`, { headers: this.getHeader() });
  }

  getClientesDesactivadosUltimos7Dias(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/desactivados-ultimos-7-dias`, { headers: this.getHeader() });
  }

  getGraficaClientesDesactivadosUltimos7Dias(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/desactivados-ultimos-7-dias-day-by-day`, { headers: this.getHeader() });
  }

  getGraficaDireccionesPendientesAsignarInstaladora(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/direcciones-pendientes-por-asignar-instaladora-day-by-day`, { headers: this.getHeader() });
  }

  getGraficaDireccionesPendientesAsignarVendedor(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/direcciones-pendientes-por-asignar-vendedor-day-by-day`, { headers: this.getHeader() });
  }

  getClientesDistrubucionPorStatus(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/distribucion-por-status`, { headers: this.getHeader() });
  }

  getClientesTop10Municipios(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/top-10-municipios`, { headers: this.getHeader() });
  }

  getClientesTop10Instaladoras(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/clientes/top-10-instaladoras`, { headers: this.getHeader() });
  }

  //NEGOCIOS
  getNegociosAsignados(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/negocios/asignados`, { headers: this.getHeader() });
  }

  getClientesSinNegocios(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/negocios/clientes-sin-negocios`, { headers: this.getHeader() });
  }

  getNegociosDistrubucionPorStatus(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/negocios/distribucion-por-status`, { headers: this.getHeader() });
  }


  getClientesConNegocios(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/negocios/clientes-con-negocios`, { headers: this.getHeader() });
  }

  //CONTRATOS
  getContratosRegistrados(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/contratos/registrados`, { headers: this.getHeader() });
  }

  getClientesConContratos(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/contratos/clientes-con-contratos`, { headers: this.getHeader() });
  }

  getClientesSinContratos(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/contratos/clientes-sin-contratos`, { headers: this.getHeader() });
  }

  getContratosDistrubucionPorStatus(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/contratos/distribucion-por-status`, { headers: this.getHeader() });
  }

  //SERVICIOS
  getServiciosRegistrados(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/servicios/registrados`, { headers: this.getHeader() });
  }

  getClientesConServicios(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/servicios/clientes-con-servicios`, { headers: this.getHeader() });
  }

  getClientesSinServicios(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/servicios/clientes-sin-servicios`, { headers: this.getHeader() });
  }

  getServiciosDistrubucionPorStatus(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/servicios/distribucion-por-status`, { headers: this.getHeader() });
  }

  //MEDIDORES
  getBodegasInstaladoras(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/medidores/bodegas-instaladoras`, { headers: this.getHeader() });
  }

  getBodegasRegionales(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/medidores/bodegas-regionales`, { headers: this.getHeader() });
  }

  getMovInvPorProcesar(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/medidores/inventory-movement-por-procesar`, { headers: this.getHeader() });
  }

  getMovInvProcesados(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/medidores/inventory-movement-procesados`, { headers: this.getHeader() });
  }

  getMovInvRechazados(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/medidores/inventory-movement-rechazados`, { headers: this.getHeader() });
  }

  getMedidoresDistrubucionPorDepositos(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/medidores/distribucion-por-depositos`, { headers: this.getHeader() });
  }

  //OBJETIVOS SUPERVISOR
  getMetasSupervisor(id: number, year:string): Observable<any> {
    let params = new HttpParams();

    params = params.append('year', year);
    return this.http.get<any>(`${this.getUrl()}/objetivo-supervisor/goals/${id}`, {params: params, headers: this.getHeader() });
  }
}

import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Search} from '../../shared/components/search/search.component';
import {Ruta} from '../model/dto/ruta';
import {MetadataRuta} from '../model/match/metadata-ruta';
import {MatchUpdateRuta} from '../model/match/match-update-ruta';
import {MatchCreateRuta} from '../model/match/match-create-ruta';

@Injectable({
	providedIn: 'root'
})
export class RutaService extends BaseService<Ruta, Ruta, MetadataRuta, MetadataRuta> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'ruta';
	}

	crearRuta(matchCreateRuta: MatchCreateRuta): Observable<any> {
		return this.http.post<any>(`${this.getUrl()}`, matchCreateRuta, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateRuta: MatchUpdateRuta): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}`, matchUpdateRuta, { headers: this.getHeader() });
	}

	getMetadataResumenListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen/v2`, { params: search.params, headers: this.getHeader() });
	}

	getRecorridoDetalleRuta(rutaId: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${rutaId}/recorrido/detalle-ruta`, { headers: this.getHeader() });
	}

  getHistoryTraceByRoadId(roadId: number, search: Search): Observable<any> {
    return this.http.get<any[]>(`${this.getUrl()}/v1/${roadId}/history-trace-app`, {params: search.params, headers: this.getHeader()});
  }

  getHistorySellerByRoadId(roadId: number, search: Search): Observable<any> {
    return this.http.get<any[]>(`${this.getUrl()}/v1/${roadId}/history-vendedor`, {params: search.params, headers: this.getHeader()});
  }

	getRecorridoDetalleVendedor(rutaId: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${rutaId}/recorrido/detalle-vendedor`, { headers: this.getHeader() });
	}

	getDetalleRuta(rutaId: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${rutaId}/detalle-ruta`, { headers: this.getHeader() });
	}

	getVendedorDetalleRuta(rutaId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${rutaId}/detalle-ruta/vendedor`, { headers: this.getHeader() });
	}

  getVendedorRutaDetalle(rutaId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${rutaId}`, { headers: this.getHeader() });
  }

	getDetalleRutaInfoMonitoreoOperativo(rutaId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${rutaId}/detalle-ruta-monitoreo/operativo`, { headers: this.getHeader() });
	}

  getDetalleRutaInfoMonitoreoComercial(rutaId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${rutaId}/detalle-ruta-monitoreo/comercial`, { headers: this.getHeader() });
  }

	getMetadataResumenListVendedoresRuta(rutaId: number, search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${rutaId}/list-vendedores`, { params: search.params, headers: this.getHeader() });
	}

	getFechasRutas(rutaId: number, vendedoresIds: any): Observable<any> {
	  let params = new HttpParams();
      params = params.append("vendedoresIds", vendedoresIds);
    return this.http.get<any>(`${this.getUrl()}/${rutaId}/fechas-rutas`, {params: params, headers: this.getHeader() });
	}

  getFechasRutasCrear(vendedoresIds: any): Observable<any> {
    let params = new HttpParams();
    params = params.append("vendedoresIds", vendedoresIds);
    return this.http.get<any>(`${this.getUrl()}/fechas-rutas`, {params: params, headers: this.getHeader() });
  }

  delete(rutaId: number): Observable<any> {
    return this.http.delete<any>(`${this.getUrl()}/${rutaId}`, { headers: this.getHeader() });
  }

  getMetadataResumenListVendedoresRutaTrazabilidad(rutaId: number, vendedorId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${rutaId}/vendedores/${vendedorId}/trazabilidad`, { headers: this.getHeader() });
  }

  getMetadataResumenDetalleVendedorRuta(rutaId: number, vendedorId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${rutaId}/recorrido/detalle-vendedor/${vendedorId}`, { headers: this.getHeader() });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatchUpdatePassword } from '../../../api/model/match/match-update-password';
import { UserService } from '../../../api/rest/user.service';
import { ToastrService } from 'ngx-toastr';

const bannedPasswords: string[] = ["PASSWORD", "CONTRASENA", "CONTRASEÑA"];

const validatePassword = (password: string): boolean => {
 for (const bannedPassword of bannedPasswords) {
    if (password.toUpperCase().includes(bannedPassword)) {
      return false;
    }
  }

  var regexVar = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/;
  var regex = /[!@#$%^&*()\-_=+{}[\]|\\:;"'<>,./?]/;
  if (!regexVar.test(password) || !regex.test(password) || !(password.length>=14 && password.length<=20)) {
    return false
  }

  return true;
}

@Component({
	selector: 'app-cambiar-contrasena',
	templateUrl: './cambiar-contrasena.component.html',
	styleUrls: ['./cambiar-contrasena.component.css']
})
export class CambiarContrasenaComponent implements OnInit {

	@ViewChild("reCaptcha")
	reCaptcha: any;

	showpassword: boolean = false;
	captchaToken: string = null;
	formCambio: FormGroup;
	KEY: string = "6Ldu_3kkAAAAAAgclpiQTgo3NRSndyQHUewxp5Xu"; //6Ldu_3kkAAAAAAgclpiQTgo3NRSndyQHUewxp5Xu
	valida: boolean  = false;

	token: string;
	repiteMsj: string = "Repita la nueva Contraseña";
	mar: string = "#333";
    bannedPasswordsArray: string[] = [...bannedPasswords];

	constructor(
		private formBuilder: FormBuilder,
		private activateRouter: ActivatedRoute,
		private userService: UserService,
		private router: Router,
		private toastr: ToastrService
	) {
	}

  get bannedPasswordsString (): string {
    return this.bannedPasswordsArray.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(', ');
  }

  get invalidPassword (): boolean {
    if (this.formCambio.get('pass').value === '') {
      return false;
    }

    return !validatePassword(this.formCambio.get('pass').value)
  }

	ngOnInit() {
		this.initForms();

		this.activateRouter.params.subscribe( params => {
			this.token = params.token;
		})
	}

	initForms() {
		this.formCambio = this.formBuilder.group({
			pass: this.formBuilder.control(''),
			repetirpass: this.formBuilder.control(''),
			captcha : this.formBuilder.control('', [Validators.required]),
		});

		this.formCambio.controls['pass'].setValidators([
			Validators.required,
			Validators.minLength(14),
			this.validarCaracteres.bind(this.formCambio)
		]);

		this.formCambio.controls['repetirpass'].setValidators([
			Validators.required,
			Validators.minLength(14),
			this.noIguales.bind(this.formCambio),
			this.validarCaracteres.bind(this.formCambio)
		]);
	}

	handleCorrectCaptcha(response) {
		this.captchaToken = response;
	}

	parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
	}

	cambiarPassword() {
		let form: any = this.formCambio.value;

		let payload = this.parseJwt(this.token);
		if (payload.exp > (new Date().getTime() / 1000)) {
			this.userService.cambiarPassword(form.pass, this.token, this.captchaToken).subscribe( () => {
				this.toastr.success("Contraseña cambiada exitosamente", "", {enableHtml: true});

				this.router.navigate(['/login']);
			}, (httpErrorResponse: HttpErrorResponse) =>{
				this.showError(httpErrorResponse.error);
			})
		} else {
			this.showError("Token inválido");
			this.router.navigate(['/login']);
		}
	}

	noIguales(control:FormControl) : {[s:string]:boolean} {
		let formCambio:any = this;

		if (control.value !== formCambio.controls['pass'].value) {
			return {
				noiguales: true
			}
		}

		return null;
	}

	validarCaracteres(control:FormControl): {[ s:string ]:boolean} {
    if (!validatePassword(control.value)) {
      return {
        valida: true
      }
    }

		return null;
	}

	cancelarRecuperacion(){
		this.router.navigate(['/login']);
	}

	onSearchChange(event) {
		if (this.captchaToken) {
			this.reCaptcha.reset();
			this.captchaToken = null;
		}
	}

	sonIguales(): void{
		const form = this.formCambio.value;
		if(form.pass === "" || form.repetirpass === ""){
			return;
		}
		if(form.pass !== form.repetirpass){
			this.repiteMsj = "Contraseñas no son iguales";
			this.mar = "red";
		}else{
			this.repiteMsj = "Repita la nueva Contraseña";
			this.mar = "#333";
		}
	}

	showError(message: string) {
		this.toastr.error(message, "", {enableHtml: true});
	}

	spsd() {
		this.showpassword = this.showpassword == false ? true : false;
	}
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { TipoDocumento } from '../model/dto/tipo-documento';
import { MetadataTipoDocumento } from '../model/match/metadata-tipo-documento';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TipoDocumentoService extends BaseService<TipoDocumento, TipoDocumento, MetadataTipoDocumento, MetadataTipoDocumento> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tipo-documento';
	}

	getTipoDocumentoContrato(): Observable<MetadataTipoDocumento> {
		return this.http.get<MetadataTipoDocumento>(`${this.getUrl()}/contrato`, {headers: this.getHeader()});
	}

	getTipoDocumentoServicio(): Observable<MetadataTipoDocumento> {
		return this.http.get<MetadataTipoDocumento>(`${this.getUrl()}/servicio`, {headers: this.getHeader()});
	}
}
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { NotificacionService } from '../../../api/rest/notificacion.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.css']
})
export class LinkComponent {

  notificacionId: any;

  constructor(public router: Router,
    public ngxPermissionsService: NgxPermissionsService,
    public toastr: ToastrService,
    private notificacionService: NotificacionService,
    private activatedRoute: ActivatedRoute) {

    let url: string = this.activatedRoute.snapshot.queryParamMap.get("url");
    this.notificacionId = this.activatedRoute.snapshot.queryParamMap.get("id");

    let dot = url.indexOf(".");

    if (dot > -1) {
      let id = url.substring(dot + 1);

      url = url.substring(0, dot);

      this.redireccionarWithId(url, id);
      return;
    }

    this.redireccionar(url);
  }

  redireccionar(url: any) {
    this.router.navigate([url]);
  }

  redireccionarWithId(url: any, id: any) {
    this.router.navigate([url], { queryParams: { id: id } });
  }
}

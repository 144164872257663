import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { Domain } from '../model/dto/domain';
import { MetadataDomain } from '../model/match/metadata-domain';
import { ResumenDomain } from '../model/dto/resumen/resumen-domain';
import { MatchCreateDomain } from '../model/match/match-create-domain';
import { Observable } from 'rxjs';
import { MatchUpdateDomain } from '../model/match/match-update-domain';
import { MetadataResumenDomain } from '../model/match/metadata-resumen-domain';
import { Subdomain } from '../model/dto/subdomain';

@Injectable({
	providedIn: 'root'
})
export class DomainService extends BaseService<Domain, ResumenDomain, MetadataDomain, MetadataResumenDomain> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'domain';
	}

	create(matchCreateDomain: MatchCreateDomain): Observable<Domain> {
		return this.http.post<Domain>(this.getUrl(), matchCreateDomain, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateDomain: MatchUpdateDomain): Observable<Domain> {
		return this.http.put<Domain>(`${this.getUrl()}/${id}`, matchUpdateDomain, { headers: this.getHeader() });
	}

	getListFullData(): Observable<ResumenDomain[]> {
		return this.http.get<ResumenDomain[]>(`${this.getUrl()}/full-data`);
	}

	getSubdominios(id: number): Observable<Subdomain[]> {
		return this.http.get<Subdomain[]>(`${this.getUrl()}/${id}/subdomains`, { headers: this.getHeader() });
	}
}
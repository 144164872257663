import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { RoleType } from '../../api/model/dto/role-type';
import { MetadataRoleType } from '../model/match/metadata-role-type';
import { HttpClient } from '@angular/common/http';
@Injectable({
	providedIn: 'root'
})
export class RoleTypeService extends BaseService<RoleType, RoleType, MetadataRoleType, MetadataRoleType> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'role-type';
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ServiceHighReasonMeter } from '../model/dto/service-high-reason-meter';
import { MetadataServiceHighReasonMeter } from '../model/match/metadata-service-high-reason-meter';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ServiceHighReasonMeterService extends BaseService<ServiceHighReasonMeter, ServiceHighReasonMeter, MetadataServiceHighReasonMeter, MetadataServiceHighReasonMeter> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'service-high-reason-meter';
	}

	getByService(serviceId: number): Observable<ServiceHighReasonMeter[]> {
		return this.http.get<ServiceHighReasonMeter[]>(`${this.getUrl()}/by-service/${serviceId}`, { headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Report } from '../model/dto/report';
import { MetadataReport } from '../model/match/metadata-report';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResumenReport } from '../model/dto/resumen/resumen-report';
import {MatchReportCambioEscenario} from '../model/match/match-cambio-escenario'
import { ResumenReportCambiosDeEscenario } from '../model/dto/resumen/resumen-report-cambios-escenario';
@Injectable({
	providedIn: 'root'
})
export class ReportService extends BaseService<Report, Report, MetadataReport, MetadataReport> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'report';
	}

	getResumenReportContratos(): Observable<ResumenReport[]> {
		return this.http.get<ResumenReport[]>(`${this.getUrl()}/contratos`, { headers: this.getHeader() });
	}

	getResumenReportServicios(): Observable<ResumenReport[]> {
		return this.http.get<ResumenReport[]>(`${this.getUrl()}/servicios`, { headers: this.getHeader() });
	}

	getResumenReportExcel(): Observable<ResumenReport[]> {
		return this.http.get<ResumenReport[]>(`${this.getUrl()}/descarga-excel`, { headers: this.getHeader() });
	}


  getReportCambiosDeEscenario(matchReporteCambioEscenario :MatchReportCambioEscenario): Observable<ResumenReportCambiosDeEscenario[]> {
  return  this.http.post<ResumenReportCambiosDeEscenario[]>(`${this.getUrl()}/reporte-cambios-escenario`,matchReporteCambioEscenario ,{ headers: this.getHeader()});
	}


}

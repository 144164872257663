import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '../../shared/components/search/search.component';
import { Zona } from '../model/dto/zona';
import { MetadataZona } from '../model/match/metadata-zona';
import { MatchCreateZona } from '../model/match/match-create-zona';
import { MatchUpdateZona } from '../model/match/match-update-zona';

@Injectable({
	providedIn: 'root'
})
export class ZonaService extends BaseService<Zona, Zona, MetadataZona, MetadataZona> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'zona';
	}

	delete(zonaId: number): Observable<any> {
		return this.http.delete<any>(`${this.getUrl()}/${zonaId}`, { headers: this.getHeader() });
	}

	getMetadataResumenListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen/v2`, { params: search.params, headers: this.getHeader() });
	}

	getZonasPorRegional(regionalId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${regionalId}/zonas-por-regional`, { headers: this.getHeader() });
	}

	getInstaladoraPorZona(zonaId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/instaladora-por-zona`, { headers: this.getHeader() });
	}

	getVendedoraPorInstaladora(instaladoraId: number, regionalId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${instaladoraId}/${regionalId}/vendedores-por-instaladora`, { headers: this.getHeader() });
	}

	getMetadataResumenListVendedores(zonaId: number, search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/list-vendedores`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataResumenVendedores(zonaId: number, search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/vendedores`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataResumenListSupervisores(zonaId: number, search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/list-supervisores`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataResumenListCuadrillas(zonaId: number, search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/list-cuadrillas`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataResumenAsignadasListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen-asignadas/v2`, { params: search.params, headers: this.getHeader() });
	}

	crearZona(matchCreateZona: MatchCreateZona): Observable<any> {
		return this.http.post<any>(`${this.getUrl()}`, matchCreateZona, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateZona: MatchUpdateZona): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}`, matchUpdateZona, { headers: this.getHeader() });
	}

	getListToOptions(): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader() });
	}

	getInstaladorasListToOptions(id: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${id}/instaladoras/list-to-options`, { headers: this.getHeader() });
	}

	getMicrozonasListToOptionsByInstaladora(zonaid: number, instaladoraId: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${zonaid}/microzonas/list-to-options/${instaladoraId}`, { headers: this.getHeader() });
	}

	getSupervisoresporZonaListoToOptions(zonaId: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${zonaId}/supervisores-zona`, { headers: this.getHeader() });
	}

	getZonasParaDibujar(): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/zonas-para-dibujar`, { headers: this.getHeader() });
	}

	getMicrozonasParaDibujar(zonaId: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${zonaId}/microzonas-para-dibujar`, { headers: this.getHeader() });
	}

	getMicrozonasParaDibujarDetalleZona(zonaId: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${zonaId}/detalle-zona/microzonas-to-draw`, { headers: this.getHeader() });
	}

	getDetalleZona(zonaId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/detalle-zona`, { headers: this.getHeader() });
	}

	getInstaladorasDetalleZona(zonaId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/detalle-zona/instaladoras`, { headers: this.getHeader() });
	}

	getListMicrozonasDetalleZona(zonaId: number, search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/detalle-zona/microzonas`, { params: search.params, headers: this.getHeader() });
	}

	getDetalleZonaInfoMonitoreoOperativo(zonaId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/detalle-zona-monitoreo/operativo`, { headers: this.getHeader() });
	}

	getDetalleZonaInfoMonitoreoComercial(zonaId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/detalle-zona-monitoreo/comercial`, { headers: this.getHeader() });
	}


	getPointsLimited(norte, sur, este, oeste, showClientes = true, showAcometidas = true, showAdeudos = true, showCandidatosRecontratacion = true, regionalId): Observable<any> {
		let params = new HttpParams();

		params = params.append("norte", norte);
		params = params.append("sur", sur);
		params = params.append("este", este);
		params = params.append("oeste", oeste);
		params = params.append("clientes_activo", showClientes.toString());
		params = params.append("acometidas", showAcometidas.toString());
		params = params.append("adeudos", showAdeudos.toString());
		params = params.append("candidatos_recontratacion", showCandidatosRecontratacion.toString());
		params = params.append('regional', regionalId);

		return this.http.get<any>(`${this.getUrl()}/map/show/all`, { params: params, headers: this.getHeader() });
	}

	getCounterPoints(zonaId, regionalId): Observable<any> {
		let params = new HttpParams();
		params = params.append('regional', regionalId);
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/map/show/all_counts`, { params: params, headers: this.getHeader() });
	}

	getListMicrozonasZona(zonaId: number, search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/microzonas`, { params: search.params, headers: this.getHeader() });
	}

	getMicrozonasListToOptionsByZona(zonaId: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${zonaId}/microzonas/list-to-options`, { headers: this.getHeader() });
	}

	getListProyectosZona(zonaId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${zonaId}/proyectos`, { headers: this.getHeader() });
	}
}

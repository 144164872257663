import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { TipoCliente } from '../model/dto/tipo-cliente';
import { MetadataTipoCliente } from '../model/match/metadata-tipo-cliente';
import { HttpClient } from '@angular/common/http';
import { MetadataTipoInmueble } from '../model/match/metadata-tipo-inmueble';

@Injectable({
	providedIn: 'root'
})
export class TipoClienteService extends BaseService<TipoCliente, TipoCliente, MetadataTipoCliente, MetadataTipoCliente> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'customer-type';
	}

	getTipoDeCliente(page: number = -1, limit: number = -1): Observable<TipoCliente> {///TutenREST/#!/customer-type/findAll
		return this.http.get<TipoCliente>(`${this.getUrl()}?page=${page}&max=${limit}`, { headers: this.getHeader() });
	}

	getTipoInmuebles(tipoClienteId: number): Observable<MetadataTipoInmueble> {
		return this.http.get<MetadataTipoInmueble>(`${this.getUrl()}/${tipoClienteId}/tipos-inmuebles`, { headers: this.getHeader() });
	}
}
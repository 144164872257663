import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { ApiModule } from './api/api.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { httpInterceptorProviders } from './shared/interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from '../app/office/sesion/login/login.component';
import { RecuperarContrasenaComponent } from '../app/office/sesion/recuperar-contrasena/recuperar-contrasena.component';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AuthGuard } from '../app/office/sesion/auth/auth.guard';
import { ReCaptchaModule } from 'angular2-recaptcha';
import { CambiarContrasenaComponent } from '../app/office/sesion/recuperar-contrasena/cambiar-contrasena.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NotFoundComponent } from './office/sesion/not-found/not-found.component';
import { NotPermissionsComponent } from './office/sesion/not-permissions/not-permissions.component';
import { AuthLoginGuard } from '../app/office/sesion/auth/auth-login.guard';
import { CerrarSesionComponent } from './office/sesion/cerrar-sesion/cerrar-sesion.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { LinkComponent } from './office/sesion/link/link.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VerificarCodigoComponent } from './office/sesion/verificar-codigo/verificar-codigo.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RecuperarContrasenaComponent,
    CambiarContrasenaComponent,
    NotFoundComponent,
    NotPermissionsComponent,
    CerrarSesionComponent,
    LinkComponent,
    VerificarCodigoComponent
  ],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    ApiModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    PanelModule,
    ButtonModule,
    InputTextModule,
    ReCaptchaModule,
    NgxPermissionsModule.forRoot(),
    AngularFontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    httpInterceptorProviders,
    AuthGuard,
    AuthLoginGuard
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { StatusService } from '../model/dto/status-service';
import { MetadataStatusService } from '../model/match/metadata-status-service';

@Injectable({
	providedIn: 'root'
})
export class StatusServiceService extends BaseService<StatusService, StatusService, MetadataStatusService, MetadataStatusService> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'status-service';
	}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { MetadataMaterial } from '../model/match/metadata-material';
//import { MetadataResumenMaterial } from '../model/match/metadata-resumen-material';
//import { ResumenMaterial } from '../model/dto/resumen/resumen-material';
import { MatchCreateMateriales } from '../model/match/match-create-materiales';
import { Observable } from 'rxjs';
import { MatchUpdateMateriales } from '../model/match/match-update-materiales';
import { Material } from '../model/dto/material';

@Injectable({
	providedIn: 'root'
})
export class MaterialService extends BaseService<Material, Material, MetadataMaterial, MetadataMaterial> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'material';
	}

	create(matchCreateMateriales: MatchCreateMateriales): Observable<Material> {
		return this.http.post<Material>(`${this.getUrl()}/`, matchCreateMateriales, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateMateriales: MatchUpdateMateriales): Observable<Material> {
		return this.http.put<Material>(`${this.getUrl()}/${id}`, matchUpdateMateriales, { headers: this.getHeader() });
	}

	delete(id: number): Observable<Material> {
		return this.http.delete<Material>(`${this.getUrl()}/${id}`, { headers: this.getHeader() });
	}
	getMateriales(): Observable<MetadataMaterial> {
		return this.http.get<MetadataMaterial>(`${this.getUrl()}`, { headers: this.getHeader() });
	}
}

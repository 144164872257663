import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { OrigenDatos } from '../model/dto/origen-datos.';
import { MetadataOrigenDatos } from '../model/match/metadata-origen-datos';

@Injectable({
	providedIn: 'root'
})
export class OrigenDatosService extends BaseService<OrigenDatos, OrigenDatos, MetadataOrigenDatos, MetadataOrigenDatos> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'origen-datos';
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataNegocio } from '../model/match/metadata-negocio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Negocio } from '../model/dto/negocio';
import { MatchCreateNegocioToEngie } from '../model/match/match-create-negocio-to-engie';
import { MatchCreateNegocioToInstaladora } from '../model/match/match-create-negocio-to-instaladora';
import { ResumenNegocio } from '../model/dto/resumen/resumen-negocio';
import { MetadataResumenActividadNegocio } from '../model/match/metadata-resumen-actividad-negocio';
import { MetadataResumenContratoToDetalleNegocio } from '../model/match/metadata-resumen-contrato-to-detalle-negocio';
import { MetadataResumenWorkflowValidacion } from '../model/match/metadata-resumen-workflow-validacion';
import { MetadataResumenNegocio } from '../model/match/metadata-resumen-negocio';
import { Search } from '../../shared/components/search/search.component';
import { MetadataEstructuraRegional } from '../model/match/metadata-estructura-regional';
import { MetadataTipoPES } from '../model/match/metadata-tipo-pes';
import { ContratoToCombo } from '../model/dto/contrato-to-combo';
import { MetadataCitygate } from '../model/match/metadata-citygate';
import { MetadataElementoPep } from '../model/match/metadata-elemento-pep';
import { MatchUpdateNegocio } from '../model/match/match-update-negocio';
import { MetadataResumenCliente } from '../model/match/metadata-resumen-cliente';


@Injectable({
  providedIn: 'root'
})
export class NegocioService extends BaseService<Negocio, ResumenNegocio, MetadataNegocio, MetadataResumenNegocio> {

  constructor(protected http: HttpClient) {
    super(http);
  }


  getClassUrl(): string {
    return 'negocio';
  }

  createToEngie(matchCreateNegocioToEngie: MatchCreateNegocioToEngie): Observable<Negocio> {
    return this.http.post<Negocio>(`${this.getUrl()}/to-engie`, matchCreateNegocioToEngie, { headers: this.getHeader() });
  }

  createToInstaladora(matchCreateNegocioToInstaladora: MatchCreateNegocioToInstaladora): Observable<Negocio> {
    return this.http.post<Negocio>(`${this.getUrl()}/to-instaladora`, matchCreateNegocioToInstaladora, { headers: this.getHeader() });
  }

  getDetailNegocio(negocioId: number): Observable<Negocio> {
    return this.http.get<Negocio>(`${this.getUrl()}/${negocioId}`, { headers: this.getHeader() });
  }

  getResumenActividadNegocio(negocioId: number, search: Search): Observable<MetadataResumenActividadNegocio> {
    return this.http.get<MetadataResumenActividadNegocio>(`${this.getUrl()}/${negocioId}/activity`, { params: search.params, headers: this.getHeader() });
  }

  getResumenWorkflowValidacion(negocioId: number, search: Search): Observable<MetadataResumenWorkflowValidacion> {
    return this.http.get<MetadataResumenWorkflowValidacion>(`${this.getUrl()}/${negocioId}/workflow-validacion`, { params: search.params, headers: this.getHeader() });
  }

  getResumenContratoToDetalleNegocio(negocioId: number, search: Search): Observable<MetadataResumenContratoToDetalleNegocio> {
    return this.http.get<MetadataResumenContratoToDetalleNegocio>(`${this.getUrl()}/${negocioId}/contratos`, { params: search.params, headers: this.getHeader() });
  }

  getFolioNegocio(negocioId: number): Observable<Negocio> {
    return this.http.get<Negocio>(`${this.getUrl()}/${negocioId}/folios`, { headers: this.getHeader() });
  }

  getEstructurasRegionales(negocioId: number): Observable<MetadataEstructuraRegional> {
    return this.http.get<MetadataEstructuraRegional>(`${this.getUrl()}/${negocioId}/estructuras-regionales`, { headers: this.getHeader() });
  }

  getTiposPES(negocioId: number): Observable<MetadataTipoPES> {
    return this.http.get<MetadataTipoPES>(`${this.getUrl()}/${negocioId}/tipos-pes`, { headers: this.getHeader() });
  }

  //SUSTITUYE A
  getContratoToCombo(negocioId: number): Observable<ContratoToCombo[]> {
    return this.http.get<ContratoToCombo[]>(`${this.getUrl()}/${negocioId}/contratos-sustituibles`, { headers: this.getHeader() });
  }

  //ELEMENTOPEP
  getElementosPep(negocioId: number): Observable<MetadataElementoPep> {
    return this.http.get<MetadataElementoPep>(`${this.getUrl()}/${negocioId}/elementos-pep`, { headers: this.getHeader() });
  }

  reasignar(id: number, obj: MatchUpdateNegocio, movil: boolean, CENTRAL: boolean): Observable<Negocio> {
    return this.http.put<Negocio>(`${this.getUrl()}/${id}/reasignar${(movil && !CENTRAL) ? "/fenix-ii" : ""}`, obj, { headers: this.getHeader() });
  }

  desvincularNegocio(id: number, obj: MatchUpdateNegocio): Observable<Negocio> {
    return this.http.put<Negocio>(`${this.getUrl()}/${id}/desvincular-negocio`, obj, { headers: this.getHeader() });
  }

  getUsuariosCreadoresDeContratos(id: number, instaladoraId: number, search: Search): Observable <MetadataResumenCliente>{
		return this.http.get<MetadataResumenCliente>(`${this.getUrl()}/${id}/users-creaters-contract/${instaladoraId}`, {params: search.params, headers: this.getHeader()});
  }
  
  getOrigenMovil(negocioId: number): Observable<Negocio> {
    return this.http.get<Negocio>(`${this.getUrl()}/${negocioId}/origen-movil`, { headers: this.getHeader() });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { ActividadNegocio } from '../model/dto/actividad-negocio';
import { ResumenActividadNegocio } from '../model/dto/resumen/resumen-actividad-negocio';
import { Observable } from 'rxjs';
import { MetadataResumenActividadNegocio } from '../model/match/metadata-resumen-actividad-negocio';
import { MetadataActividadNegocio } from '../model/match/metadata-actividad-negocio';
import { MatchCreateActividadNegocio } from '../model/match/match-create-actividad-negocio';
import { MatchUpdateActividadNegocio } from '../model/match/match-update-actividad-negocio';

@Injectable({
	providedIn: 'root'
})
export class ActividadNegocioService extends BaseService<ActividadNegocio, ResumenActividadNegocio, MetadataActividadNegocio, MetadataResumenActividadNegocio> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'actividad-negocio';
	}

	update(id: number, matchUpdateActividadNegocio: MatchUpdateActividadNegocio): Observable<ActividadNegocio> {
		return this.http.put<ActividadNegocio>(`${this.getUrl()}/${id}`, matchUpdateActividadNegocio, {headers: this.getHeader()});
	}

	delete(id: number): Observable<ActividadNegocio> {
		return this.http.delete<ActividadNegocio>(`${this.getUrl()}/${id}`, {headers: this.getHeader()});
	}

	create(matchCreateActividadNegocio: MatchCreateActividadNegocio): Observable<ActividadNegocio> {
		return this.http.post<ActividadNegocio>(this.getUrl(), matchCreateActividadNegocio, {headers: this.getHeader()});
	}
}
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { RangeHoraryService } from '../model/dto/range-horary-service';
import { MetadataRangeHoraryService } from '../model/match/metadata-range-horary-service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RangeHoraryServiceService extends BaseService<RangeHoraryService, RangeHoraryService, MetadataRangeHoraryService, MetadataRangeHoraryService> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getClassUrl(): string {
    return 'range-horary-service';
  }
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Municipio } from '../model/dto/municipio';
import { MetadataMunicipio } from '../model/match/metadata-municipio';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Colonia } from '../model/dto/colonia';
import { ZipCode } from '../model/dto/zip-code';

@Injectable({
	providedIn: 'root'
})
export class MunicipioService extends BaseService<Municipio, Municipio, MetadataMunicipio, MetadataMunicipio> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getZipCode(id: number): Observable<ZipCode[]> {
		return this.http.get<ZipCode[]>(`${this.getUrl()}/${id}/zip-codes`, { headers: this.getHeader() });
	}

	getZipCodesWithRegionalFilter(id: number, comercial = false): Observable<ZipCode[]> {
		let params = new HttpParams();
		if(comercial){
			params = params.append("comercial", "true");
		}

		return this.http.get<ZipCode[]>(`${this.getUrl()}/${id}/zip-codes-with-regional-filter`, { params: params, headers: this.getHeader() });
	}

	getAllMunicipio(): Observable<Municipio[]> {
		return this.http.get<Municipio[]>(`${this.getUrl()}`, { headers: this.getHeader() });
	}

	getMunicipiosDisponibles(): Observable<Municipio[]> {
		return this.http.get<Municipio[]>(`${this.getUrl()}/availables`, { headers: this.getHeader() });
	}

	getClassUrl(): string {
		return 'municipio';
	}

	update(id: number, matchUpdateMunicipio): Observable<Municipio> {
		return this.http.put<Municipio>(`${this.getUrl()}/${id}`, matchUpdateMunicipio, {headers: this.getHeader()});
	}

	delete(id: number): Observable<Municipio> {
		return this.http.delete<Municipio>(`${this.getUrl()}/${id}`, {headers: this.getHeader()});
	}

	create(matchCreateMunicipio): Observable<Municipio> {
		return this.http.post<Municipio>(this.getUrl(), matchCreateMunicipio, {headers: this.getHeader()});
	}

	getMunicipios(): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader() });
	}

}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataService } from '../model/match/metadata-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '../../shared/components/search/search.component';
import { Proyecto } from '../model/dto/proyecto';
import { MetadataProyecto } from '../model/match/metadata-proyecto';
import { MatchCreateZona } from '../model/match/match-create-zona';
import { MatchUpdateZona } from '../model/match/match-update-zona';
import { MatchCreateProyecto } from '../model/match/match-create-proyecto';

@Injectable({
	providedIn: 'root'
})
export class ProyectoService extends BaseService<Proyecto, Proyecto, MetadataProyecto, MetadataProyecto> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'proyecto';
	}

	delete(proyectoId: number): Observable<any> {
		return this.http.delete<any>(`${this.getUrl()}/${proyectoId}`, { headers: this.getHeader() });
	}

	reactivar(proyectoId: number): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${proyectoId}/reactivar`, {}, { headers: this.getHeader() });
	}

	getMetadataResumenListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen/v2`, { params: search.params, headers: this.getHeader() });
	}

	crearProyecto(matchCreateProyecto: MatchCreateProyecto): Observable<any> {
		return this.http.post<any>(`${this.getUrl()}`, matchCreateProyecto, { headers: this.getHeader() });
	}

	getDetalleProyecto(proyectoId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${proyectoId}/detalle-proyecto`, { headers: this.getHeader() });
	}

  editarProyecto(proyectoId: number, matchCreateProyecto: MatchCreateProyecto): Observable<any> {
    return this.http.put<any>(`${this.getUrl()}/${proyectoId}`, matchCreateProyecto, { headers: this.getHeader() });
  }

	getMetadataProyectosCrearZona(search: Search): Observable <any> {
		return this.http.get<any>(`${this.getUrl()}/crear-zona/proyectos`, {params: search.params, headers: this.getHeader()});
	}

	getDetalleGeometria(proyectoId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${proyectoId}/geometria-proyecto`, { headers: this.getHeader() });
	}

	getProyectosListToOptions(): Observable <any> {
		return this.http.get<any>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader()});
	}

	getProyectosReporteListToOptions(): Observable <any> {
		return this.http.get<any>(`${this.getUrl()}/list-to-options/reporte`, { headers: this.getHeader()});
	}

  getTieneRedconRegional(regionalId: number, lng: string, lat: string): Observable <any> {

	  let params = new HttpParams();
	  params = params.append('lng', lng);
	  params = params.append('lat', lat);

    return this.http.get<any>(`${this.getUrl()}/${regionalId}/tiene_red`, { params: params, headers: this.getHeader()});
  }
}

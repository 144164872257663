import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { TipoMedidor } from '../model/dto/tipo-medidor';
import { MetadataTipoMedidor } from '../model/match/metadata-tipo-medidor';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipoMedidorService extends BaseService<TipoMedidor, TipoMedidor, MetadataTipoMedidor, MetadataTipoMedidor> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'gas-meter-type';
	}
}

import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Search} from '../../shared/components/search/search.component';
import {Acometida} from '../model/dto/acometida';
import {MetadataAcometida} from '../model/match/metadata-acometida';
import { MatchAcometidaHistorialUbicacion } from '../model/match/match-acometida-historial-ubicacion';


@Injectable({
  providedIn: 'root'
})
export class AcometidaService extends BaseService<Acometida, Acometida, MetadataAcometida, MetadataAcometida> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getClassUrl(): string {
    return 'acometida';
  }

  getPointsToMap(longitud: string, latitud: string, tipoAcometida: number, tipoCliente: number = null): Observable<any> {
    let params = new HttpParams();

    params = params.append('latitudCenter', latitud);
    params = params.append('longitudCenter', longitud);

    if (tipoAcometida) {
      params = params.append('tipoAcometida', '' + tipoAcometida);
    }

    if (tipoCliente) {
      params = params.append('tipoCliente', '' + tipoCliente);
    }

    return this.http.get<any>(`${this.getUrl()}/map/show`, {params: params, headers: this.getHeader()});
  }

  getAllPointsToMap(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/map/show/all`, {headers: this.getHeader()});
  }

  getMetadataResumenCargaListSearchV2(search: Search): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/resumen-carga-masiva/v2`, {params: search.params, headers: this.getHeader()});
  }

  getMetadataResumenMovilListSearchV2(search: Search): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/resumen-movil/v2`, {params: search.params, headers: this.getHeader()});
  }

  getDetailAcometidaCarga(acometidaId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${acometidaId}/detalle-carga-masiva`, {headers: this.getHeader()});
  }

  getDetailAcometidaMovil(acometidaId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${acometidaId}/detalle-movil`, {headers: this.getHeader()});
  }

  getMetadataRequestAcometida(search: Search): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/resumen-solicitudes-acometidas`, {params: search.params, headers: this.getHeader()});
  }

  getDetailSolicitudAcometida(acometidaId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${acometidaId}/resumen-solicitud-acometida`, {headers: this.getHeader()});
  }

  updateAcceptSolicitud(acometidaId: number): Observable<any> {
    return this.http.put<any>(`${this.getUrl()}/solicitud_acometida/${acometidaId}/accept`, null, {headers: this.getHeader()});
  }

  updateRejectSolicitud(acometidaId: number): Observable<any> {
    return this.http.put<any>(`${this.getUrl()}/solicitud_acometida/${acometidaId}/reject`, null, {headers: this.getHeader()});
  }

  updateBuidSolicitud(acometidaId: number): Observable<any> {
    return this.http.put<any>(`${this.getUrl()}/solicitud_acometida/${acometidaId}/build`, null, {headers: this.getHeader()});
  }

  updateBuildedSolicitud(acometidaId: number): Observable<any> {
    return this.http.put<any>(`${this.getUrl()}/solicitud_acometida/${acometidaId}/builded`, null, {headers: this.getHeader()});
  }

  //******************************************************* FENIZ II *******************************************************

  //**************************************** */FII_REPORTE 8: GENERAL DE ACOMETIDAS ***************************************

  getReportGeneralAcometidas(regionalesId: string, tipoAcometidaId: string, tipoClienteId: string, estadoId: string, municipioId: string,
                             tipoCapturaId: string, capturadorVendedor: string, vendedor: string, zipCodeId: string, coloniaId: string, tipoAcometidaText: string,
                             subtipoAcometida: string, tipoViviendaId: string, sinServicioId: string): Observable<any[]> {

    let params = new HttpParams();

    params = params.append('regionalesId', regionalesId);
    params = params.append('tipoAcometidaId', tipoAcometidaId);
    params = params.append('tipoClienteId', tipoClienteId);
    params = params.append('estadoId', estadoId);
    params = params.append('municipioId', municipioId);

    params = params.append('tipoCapturaId', tipoCapturaId);
    params = params.append('capturadorVendedor', capturadorVendedor);
    params = params.append('vendedor', vendedor);
    params = params.append('zipCodeId', zipCodeId);

    params = params.append('coloniaId', coloniaId);
    params = params.append('tipoAcometidaText', tipoAcometidaText);
    params = params.append('subtipoAcometida', subtipoAcometida);
    params = params.append('tipoViviendaId', tipoViviendaId);
    params = params.append('sinServicioId', sinServicioId);


    return this.http.get<any[]>(`${this.getUrlReports()}/reporte-general-acometidas`, {params: params, headers: this.getHeader()});
  }

  getAllMetadataSolicitudAcometidas(search: Search): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/v1/solicitud`, {params: search.params, headers: this.getHeader()});
  }

  getMetadataSolicitudAcometidaPorId(acometidaId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/v1/${acometidaId}/solicitud-detalle`, {headers: this.getHeader()});
  }

  getAllEstadoSolicitudAcometida(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/v1/estados-solicitud`, {headers: this.getHeader()});
  }

  getAllEstadoCoordenadasSolicitudAcometida(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/v1/estados-coordenadas`, {headers: this.getHeader()});
  }

  getAllMotivoRechazoSolicitudAcometida(): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/v1/motivo-rechazo-solicitud`, {headers: this.getHeader()});
  }

  updateEstadoSolicitudAcometida(acometida: { ids: number[], estado: string, motivoRechazo: string }): Observable<any> {
    return this.http.put<any>(`${this.getUrl()}/v1/estado-solicitud`, acometida, {headers: this.getHeader()});
  }

  updateAcometidaUbicacion(acometidaId: number, ubicacion: MatchAcometidaHistorialUbicacion): Observable<any> {
    return this.http.post<any>(`${this.getUrl()}/v1/modificar-coordenadas-ubicacion/${acometidaId}`, ubicacion, {headers: this.getHeader()})
  }
  
  getUltimaUbicacionAcometida(acometidaId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/v1/ultima-ubicacion/${acometidaId}`, {headers: this.getHeader()})
  }
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataContrato } from '../model/match/metadata-contrato';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CaracteristicasInmueblesResponse, Contrato } from '../model/dto/contrato';
import { ResumenContrato } from '../model/dto/resumen/resumen-contrato';
import { ResumenContratoToDetalleContrato } from '../model/dto/resumen/resumen-contrato-to-detalle-contrato';
import { MetadataResumenWorkflowValidacion } from '../model/match/metadata-resumen-workflow-validacion';
import { MetadataResumenContrato } from '../model/match/metadata-resumen-contrato';
import { Search } from '../../shared/components/search/search.component';
import { MetadataResumenContratoPorValidar } from '../model/match/metadata-resumen-contrato-por-validar';
import { Material } from '../model/dto/material';
import { MatchUpdateDireccion } from '../model/match/match-update-direccion';
import { MatchCancelarContrato } from '../model/match/match-cancelar-contrato';
import { ContratoTipoDocumento } from '../model/dto/contrato-tipo-documento';
import { MetadataResumenSincronizacionContrato } from '../model/match/metadata-resumen-sincronizacion-contrato';
import { ResumenContratoSeccionClienteSap } from '../model/dto/resumen/resumen-contrato-seccion-cliente-sap';
import { ResumenContratoSeccionCuentaContratoSap } from '../model/dto/resumen/resumen-contrato-seccion-cuenta-contrato-sap';
import { ResumenContratoSeccionObjetoConexionSap } from '../model/dto/resumen/resumen-contrato-seccion-objeto-conexion-sap';
import { ResumenContratoSeccionPuntoConsumoSap } from '../model/dto/resumen/resumen-contrato-seccion-punto-consumo-sap';
import { ResumenContratoSeccionOrdenVentaSap } from '../model/dto/resumen/resumen-contrato-seccion-orden-venta-sap';
import { MatchCreateContratoBorrador } from '../model/match/match-create-contrato-borrador';
import { MatchUpdateContratoBorrador } from '../model/match/match-update-contrato-borrador';
import { CreateMaterialForm } from '../model/form/create-material-form';
import { ResumenContratoSeccionExtensionRolSap } from '../model/dto/resumen/resumen-contrato-seccion-extension-rol-sap';
import { ResumenContratoSeccionErroresSap } from '../model/dto/resumen/resumen-contrato-seccion-errores-sap';
import { MatchEnviarCorreccionContrato } from '../model/match/match-enviar-correccion-contrato';
import { ContratoToCombo } from '../model/dto/contrato-to-combo';
import { ResumenReportSeguimientoDeContrato } from '../model/dto/resumen/resumen-report-seguimiento-de-contrato';
import { ResumenReportTipoContrato } from '../model/dto/resumen/resumen-report-tipo-contrato';
import { ResumenReportOfertasComercialesTabla1 } from '../model/dto/resumen/resumen-report-ofertas-comerciales-tabla1';
import { ResumenReportEstatusPorContratoServicioTabla2 } from '../model/dto/resumen/resumen-report-estatus-por-contrato-servicio-tabla2';
import { ResumenReportAgendamientoDeCuadrillaTabla1 } from '../model/dto/resumen/resumen-report-agendamiento-de-cuadrillas-tabla1';
import { ResumenReportVentasTabla1 } from '../model/dto/resumen/resumen-report-ventas-tabla1';
import { ResumenReportEstatusPorContratoServicioTabla1 } from '../model/dto/resumen/resumen-report-estatus-por-contrato-servicio-tabla1';
import { ResumenReportEstatusPorContratoServicioTabla3 } from '../model/dto/resumen/resumen-report-estatus-por-contrato-servicio-tabla3';
import { ResumenReportEstatusPorContratoServicioTabla4 } from '../model/dto/resumen/resumen-report-estatus-por-contrato-servicio-tabla4';
import { ResumenReportEstatusPorContratoServicioTabla5 } from '../model/dto/resumen/resumen-report-estatus-por-contrato-servicio-tabla5';
import { map } from 'rxjs/operators';
import { ContratoResponse } from '../model/dto/contratoResponse';
import { ResumenReportCambiosDeEscenario } from '../model/dto/resumen/resumen-report-cambios-escenario';

@Injectable({
	providedIn: 'root'
})
export class ContratoService extends BaseService<Contrato, ResumenContrato, MetadataContrato, MetadataResumenContrato> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'contrato';
	}

	getMetadataResumenPorValidarListSearch(search: Search): Observable<MetadataResumenContratoPorValidar> {
		return this.http.get<MetadataResumenContratoPorValidar>(`${this.getUrl()}/por-validar`, { params: search.params, headers: this.getHeader() });
	}

	create(matchCreateContratoBorrador: MatchCreateContratoBorrador): Observable<Contrato> {
		return this.http.post<Contrato>(`${this.getUrl()}`, matchCreateContratoBorrador, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateContratoBorrador: MatchUpdateContratoBorrador): Observable<Contrato> {
		return this.http.put<Contrato>(`${this.getUrl()}/${id}`, matchUpdateContratoBorrador, { headers: this.getHeader() });
	}

	getContratoById(contratoId: number): Observable<Contrato> {
		return this.http.get<ContratoResponse>(`${this.getUrl()}/${contratoId}`, { headers: this.getHeader() }).pipe(map(
      contratoResponse => ({...contratoResponse.contrato, isEditable: contratoResponse.isEditable, caracteristicas: contratoResponse.caracteristicas})
    ));
	}

	getAllFilesByID(id: number) {
		return this.http.get<MetadataContrato>(`${this.getUrl()}/${id}/file`, { params: null, headers: this.getHeader() });
	}

	getContratosByFolio(folio: string): Observable<Contrato[]> {
		return this.http.get<Contrato[]>(`${this.getUrl()}/general/folio/${folio}`, { headers: this.getHeader() });
	}

	getResumenContrato(id: number): Observable<Contrato> {
		return this.http.get<Contrato>(`${this.getUrl()}/general/${id}`, { headers: this.getHeader() });
	}

	cambiarEscenario(id: number, body: any): Observable<Contrato> {
		return this.http.put<Contrato>(`${this.getUrl()}/general/${id}`, body, { headers: this.getHeader() });
	}


	deleteFile(id: number, idContrato) {
		return this.http.delete(`${this.getUrl()}/${idContrato}/file/${id}`, { headers: this.getHeader() });
	}

	getResumenWorkflowValidacionContrato(contratoId: number, search: Search): Observable<MetadataResumenWorkflowValidacion> {
		return this.http.get<MetadataResumenWorkflowValidacion>(`${this.getUrl()}/${contratoId}/workflow-validacion`, { params: search.params, headers: this.getHeader() });
	}

	getMateriales(contratoId: number): Observable<Material[]> {
		return this.http.get<Material[]>(`${this.getUrl()}/${contratoId}/materiales`, { headers: this.getHeader() });
	}

	getDocumentos(contratoId: number): Observable<ContratoTipoDocumento[]> {
		return this.http.get<ContratoTipoDocumento[]>(`${this.getUrl()}/${contratoId}/resumen-documentos-contrato`, { headers: this.getHeader() });
	}

	getDetailContrato(contratoId: number): Observable<ResumenContratoToDetalleContrato> {
		return this.http.get<ResumenContratoToDetalleContrato>(`${this.getUrl()}/${contratoId}/resumen-to-detail-contrato-v2`, { headers: this.getHeader() });
	}

	getDocumentosNaAt(contratoId: number): Observable<ResumenContratoToDetalleContrato> {
		return this.http.get<ResumenContratoToDetalleContrato>(`${this.getUrl()}/${contratoId}/doc_integracion_na_at`, { headers: this.getHeader() });
	}

	getDetailContratoV2(contratoId: number): Observable<ResumenContratoToDetalleContrato> {
		return this.http.get<ResumenContratoToDetalleContrato>(`${this.getUrl()}/${contratoId}/resumen-to-detail-contrato-v2`, { headers: this.getHeader() });
	}

	getPermisoParaModificarAcometida(contratoId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${contratoId}/permitir-modificacion-acometida`, { headers: this.getHeader() });
	}

	updateAcometida(id: number, matchUpdateContratoBorrador: MatchUpdateContratoBorrador): Observable<Contrato> {
		return this.http.put<Contrato>(`${this.getUrl()}/${id}/update-acometida`, matchUpdateContratoBorrador, { headers: this.getHeader() });
	}

	getContratoAparatosAConectar(contratoId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${contratoId}/aparatos-a-conectar`, { headers: this.getHeader() });
	}

	getContratoMateriales(contratoId: number): Observable<CreateMaterialForm[]> {
		return this.http.get<CreateMaterialForm[]>(`${this.getUrl()}/${contratoId}/materiales`, { headers: this.getHeader() });
	}

	getResumenDocumentosContrato(contratoID: number) {
		return this.http.get<any>(`${this.getUrl()}/${contratoID}/resumen-documentos-contrato`, { headers: this.getHeader() });
	}

	enviarAValidacion(id: number) {
		return this.http.put<any>(`${this.getUrl()}/${id}/enviar-a-validacion`, {}, { headers: this.getHeader() });
	}

	aprobarContrato(id: number) {
		return this.http.put<any>(`${this.getUrl()}/${id}/aprobar-contrato`, {}, { headers: this.getHeader() });
	}

	enviarACorreccion(id: number, matchEnviarCorreccionContrato: MatchEnviarCorreccionContrato) {
		return this.http.put<any>(`${this.getUrl()}/${id}/enviar-a-correccion`, matchEnviarCorreccionContrato, { headers: this.getHeader() });
	}

	getTiposDocumentos(idContrato: number): Observable<ContratoTipoDocumento[]> {
		return this.http.get<ContratoTipoDocumento[]>(`${this.getUrl()}/${idContrato}/resumen-documentos-contrato`, { headers: this.getHeader() });
	}

	getDocumentosAdeudos(idContrato: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${idContrato}/doc_adeudos`, { headers: this.getHeader() });
	}

	getDeterminantesCuenta(): Observable<any> {
		return this.http.get<any[]>(`${this.getUrl()}/determinantes-cuenta`, { headers: this.getHeader() });
	}

	editaDeterminateCuenta(id: number, matchUpdateContratoBorrador: MatchUpdateContratoBorrador) {
		return this.http.put<any>(`${this.getUrl()}/${id}/edita-determinante-cuenta`, matchUpdateContratoBorrador, { headers: this.getHeader() });
	}

	cancelarContrato(id: number, matchCancelarContrato: MatchCancelarContrato): Observable<Contrato> {
		return this.http.put<Contrato>(`${this.getUrl()}/${id}/cancelar-contrato`, matchCancelarContrato, { headers: this.getHeader() });
	}

	deleteTipoDocumento(idContrato: number, fileID: number): Observable<any> {
		return this.http.delete<any>(`${this.getUrl()}/${idContrato}/file/${fileID}`, { headers: this.getHeader() });
	}

	relacionarContrato(idContrato): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${idContrato}/relacionar-contrato/`, null, { headers: this.getHeader() });
	}

	darDeBajaContrato(idContrato: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${idContrato}/cancelar`, { headers: this.getHeader() });
	}

	/*darDeBajaDetalleContrato(idContrato: number, matchCancelarContrato: MatchCancelarContrato): Observable<any> {
		return this.http.post<any>(`${this.getUrl()}/${idContrato}/cancelar`, matchCancelarContrato, { headers: this.getHeader() });
	}*/

	getMotivosCancelacion(): Observable<any> {
		return this.http.get<any[]>(`${this.getUrl()}/motivos-cancelacion-contrato`, { headers: this.getHeader() });
	}

	cancelarContratoPostValidacion(idContrato: number, option): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${idContrato}/cancelar-contrato-post-validacion/${option}`, {}, { headers: this.getHeader() });
	}

	fileView(idContrato): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/view-file/${idContrato}`, { headers: this.getHeader() });
	}

	updateDireccion(id: number, direccionClienteId: number, matchUpdateDireccion: MatchUpdateDireccion): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}/address/${direccionClienteId}`, matchUpdateDireccion, { headers: this.getHeader() });
	}
	//SUSTITUYE A
	getContratoToCombo(idContrato: number): Observable<ContratoToCombo[]> {
		return this.http.get<ContratoToCombo[]>(`${this.getUrl()}/${idContrato}/contratos-sustituibles`, { headers: this.getHeader() });
	}

	//SAP ENVIAR
	reenviarASap(id: number) {
		return this.http.post<any>(`${this.getUrl()}/${id}/reenviar-a-sap`, {}, { headers: this.getHeader() });
	}

	//SAP
	getMetadataResumenSincronizacionListSearch(search: Search): Observable<MetadataResumenSincronizacionContrato> {
		return this.http.get<MetadataResumenSincronizacionContrato>(`${this.getUrl()}/sincronizacion-contratos`, { params: search.params, headers: this.getHeader() });
	}

	//SAP CLIENTE
	getResumenContratoSeccionClienteSap(contratoId: number): Observable<ResumenContratoSeccionClienteSap> {
		return this.http.get<ResumenContratoSeccionClienteSap>(`${this.getUrl()}/${contratoId}/resumen-sincronizacion-cliente`, { headers: this.getHeader() });
	}

	//SAP CUENTA CONTRATO
	getResumenContratoSeccionCuentaContratoSap(contratoId: number): Observable<ResumenContratoSeccionCuentaContratoSap> {
		return this.http.get<ResumenContratoSeccionCuentaContratoSap>(`${this.getUrl()}/${contratoId}/resumen-sincronizacion-cuenta-contrato`, { headers: this.getHeader() });
	}

	//SAP OBJETO CONEXION
	getResumenContratoSeccionObjetoConexionSap(contratoId: number): Observable<ResumenContratoSeccionObjetoConexionSap> {
		return this.http.get<ResumenContratoSeccionObjetoConexionSap>(`${this.getUrl()}/${contratoId}/resumen-sincronizacion-objeto-conexion`, { headers: this.getHeader() });
	}

	//SAP PUNTO CONSUMO
	getResumenContratoSeccionPuntoConsumoSap(contratoId: number): Observable<ResumenContratoSeccionPuntoConsumoSap> {
		return this.http.get<ResumenContratoSeccionPuntoConsumoSap>(`${this.getUrl()}/${contratoId}/resumen-sincronizacion-punto-consumo`, { headers: this.getHeader() });
	}

	//SAP EXTENSION ROL
	getResumenContratoSeccionExtensionRolSap(contratoId: number): Observable<ResumenContratoSeccionExtensionRolSap> {
		return this.http.get<ResumenContratoSeccionExtensionRolSap>(`${this.getUrl()}/${contratoId}/resumen-sincronizacion-extension-rol`, { headers: this.getHeader() });
	}

	//SAP ORDEN DE VENTA
	getResumenContratoSeccionOrdenDeVentaSap(contratoId: number): Observable<ResumenContratoSeccionOrdenVentaSap> {
		return this.http.get<ResumenContratoSeccionOrdenVentaSap>(`${this.getUrl()}/${contratoId}/resumen-sincronizacion-orden-venta`, { headers: this.getHeader() });
	}

	//SAP ERRORES
	getResumenContratoSeccionErroresSap(contratoId: number): Observable<ResumenContratoSeccionErroresSap> {
		return this.http.get<ResumenContratoSeccionErroresSap>(`${this.getUrl()}/${contratoId}/resumen-sincronizacion-errores`, { headers: this.getHeader() });
	}

	//********************************************** */REPORTE 1: REPORTE SEGUIMIENTO DE CONTRATO*******************************************
	getReportSeguimientoDeContrato(regionalesId: string, instaladorasId: string, desdeFechaFirma: string, hastaFechaFirma: string,
		desdeFechaEnvioValidacionCapturista: string, hastaFechaEnvioValidacionCapturista: string, desdeFechaValidacionADVC: string,
		hastaFechaValidacionADVC: string, desdeFechaPES: string, hastaFechaPES: string, desdeFechaAprobacionADVS: string,
		hastaFechaAprobacionADVS: string, folio: string, statusContratoId: string, statusServiceId: string): Observable<ResumenReportSeguimientoDeContrato[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("desdeFechaFirma", desdeFechaFirma);
		params = params.append("hastaFechaFirma", hastaFechaFirma);
		params = params.append("desdeFechaEnvioValidacionCapturista", desdeFechaEnvioValidacionCapturista);
		params = params.append("hastaFechaEnvioValidacionCapturista", hastaFechaEnvioValidacionCapturista);
		params = params.append("desdeFechaValidacionADVC", desdeFechaValidacionADVC);
		params = params.append("hastaFechaValidacionADVC", hastaFechaValidacionADVC);
		params = params.append("desdeFechaPES", desdeFechaPES);
		params = params.append("hastaFechaPES", hastaFechaPES);

		params = params.append("desdeFechaAprobacionADVS", desdeFechaAprobacionADVS);
		params = params.append("hastaFechaAprobacionADVS", hastaFechaAprobacionADVS);
		params = params.append("folio", folio);
		params = params.append("statusContratoId", statusContratoId);
		params = params.append("statusServiceId", statusServiceId);
		params = params.append("minutes", new Date().getTimezoneOffset() + "");

		return this.http.get<ResumenReportSeguimientoDeContrato[]>(`${this.getUrlReports()}/reporte-seguimiento-de-contrato`, { params: params, headers: this.getHeader() });
	}

	//********************************************** */REPORTE 2: TIPO CONTRATO*******************************************

	getReportTipoContrato(regionalesId: string, instaladorasId: string, folio: string, desdeFechaFirma: string, hastaFechaFirma: string,
		desdeFechaPES: string, hastaFechaPES: string, desdeFechaAprobacionADVS: string, hastaFechaAprobacionADVS: string,
		tipoClienteId: string, tipoInmuebleId: string, tipoPESId: string, naceConGas: number, acometida: number,
		elementoPEP: string, citygateId: string, statusContratoId: string, statusServiceId: string): Observable<ResumenReportTipoContrato[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("folio", folio);
		params = params.append("desdeFechaFirma", desdeFechaFirma);
		params = params.append("hastaFechaFirma", hastaFechaFirma);
		params = params.append("desdeFechaPES", desdeFechaPES);
		params = params.append("hastaFechaPES", hastaFechaPES);
		params = params.append("desdeFechaAprobacionADVS", desdeFechaAprobacionADVS);
		params = params.append("hastaFechaAprobacionADVS", hastaFechaAprobacionADVS);
		params = params.append("tipoClienteId", tipoClienteId);
		params = params.append("tipoInmuebleId", tipoInmuebleId);
		params = params.append("tipoPESId", tipoPESId);
		params = params.append("naceConGas", naceConGas + "");
		params = params.append("acometida", acometida + "");
		params = params.append("elementoPEP", elementoPEP);
		params = params.append("citygateId", citygateId);
		params = params.append("statusContratoId", statusContratoId);
		params = params.append("statusServiceId", statusServiceId);
		params = params.append("minutes", new Date().getTimezoneOffset() + "");

		return this.http.get<ResumenReportTipoContrato[]>(`${this.getUrlReports()}/reporte-tipo-contrato`, { params: params, headers: this.getHeader() });
	}

	//**************************************** */REPORTE 3: ESTATUS POR CONTRATO Y SERVICIO***************************************

	//TABLA 1: CONTRATOS ACUMULADOS
	getReportEstatusPorContratoServicioTabla1(regionalesId: string, instaladorasId: string, folio: string,
		statusContratoId: string): Observable<ResumenReportEstatusPorContratoServicioTabla1[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("folio", folio);
		params = params.append("statusContratoId", statusContratoId);

		return this.http.get<ResumenReportEstatusPorContratoServicioTabla1[]>(`${this.getUrlReports()}/reporte-status-por-contrato-servicio-tabla1`, { params: params, headers: this.getHeader() });
	}

	//TABLA 3: SERVICIOS ACUMULADOS
	getReportEstatusPorContratoServicioTabla3(regionalesId: string, instaladorasId: string, folio: string,
		statusServiceId: string): Observable<ResumenReportEstatusPorContratoServicioTabla3[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("folio", folio);
		params = params.append("statusServiceId", statusServiceId);

		return this.http.get<ResumenReportEstatusPorContratoServicioTabla3[]>(`${this.getUrlReports()}/reporte-status-por-contrato-servicio-tabla3`, { params: params, headers: this.getHeader() });
	}

	//TABLA 4: CONTRATOS POR MES
	getReportEstatusPorContratoServicioTabla4(regionalesId: string, instaladorasId: string, folio: string, desdeFechaEnvioValidacionCapturista: string,
		hastaFechaEnvioValidacionCapturista: string, statusContratoId: string): Observable<ResumenReportEstatusPorContratoServicioTabla4[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("folio", folio);
		params = params.append("desdeFechaEnvioValidacionCapturista", desdeFechaEnvioValidacionCapturista);
		params = params.append("hastaFechaEnvioValidacionCapturista", hastaFechaEnvioValidacionCapturista);
		params = params.append("statusContratoId", statusContratoId);

		return this.http.get<ResumenReportEstatusPorContratoServicioTabla4[]>(`${this.getUrlReports()}/reporte-status-por-contrato-servicio-tabla4`, { params: params, headers: this.getHeader() });
	}

	//TABLA 5: SERVICIOS POR MES
	getReportEstatusPorContratoServicioTabla5(regionalesId: string, instaladorasId: string, folio: string, desdeFechaValidacionADVC: string,
		hastaFechaValidacionADVC: string, desdeFechaAprobacionADVS: string, hastaFechaAprobacionADVS: string, statusServiceId: string): Observable<ResumenReportEstatusPorContratoServicioTabla5[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("folio", folio);
		params = params.append("desdeFechaValidacionADVC", desdeFechaValidacionADVC);
		params = params.append("hastaFechaValidacionADVC", hastaFechaValidacionADVC);
		params = params.append("desdeFechaAprobacionADVS", desdeFechaAprobacionADVS);
		params = params.append("hastaFechaAprobacionADVS", hastaFechaAprobacionADVS);
		params = params.append("statusServiceId", statusServiceId);

		return this.http.get<ResumenReportEstatusPorContratoServicioTabla5[]>(`${this.getUrlReports()}/reporte-status-por-contrato-servicio-tabla5`, { params: params, headers: this.getHeader() });
	}

	//TABLA 2: HISTORIAL DE CONTRATO
	getReportEstatusPorContratoServicioTabla2(regionalesId: string, instaladorasId: string, folio: string, businessPartner: string,
		desdeFechaEnvioValidacionCapturista: string, hastaFechaEnvioValidacionCapturista: string, desdeFechaPES: string, hastaFechaPES: string,
		desdeFechaAprobacionADVS: string, hastaFechaAprobacionADVS: string, tipoClienteId: string,
		statusContratoId: string, statusServiceId: string, sinHistorial: number): Observable<ResumenReportEstatusPorContratoServicioTabla2[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("folio", folio);
		params = params.append("businessPartner", businessPartner);
		params = params.append("desdeFechaEnvioValidacionCapturista", desdeFechaEnvioValidacionCapturista);
		params = params.append("hastaFechaEnvioValidacionCapturista", hastaFechaEnvioValidacionCapturista);
		params = params.append("desdeFechaPES", desdeFechaPES);
		params = params.append("hastaFechaPES", hastaFechaPES);
		params = params.append("desdeFechaAprobacionADVS", desdeFechaAprobacionADVS);
		params = params.append("hastaFechaAprobacionADVS", hastaFechaAprobacionADVS);
		params = params.append("tipoClienteId", tipoClienteId);
		params = params.append("statusContratoId", statusContratoId);
		params = params.append("statusServiceId", statusServiceId);
		params = params.append("sinHistorial", sinHistorial + "");
		params = params.append("minutes", new Date().getTimezoneOffset() + "");

		return this.http.get<ResumenReportEstatusPorContratoServicioTabla2[]>(`${this.getUrlReports()}/reporte-status-por-contrato-servicio-tabla2`, { params: params, headers: this.getHeader() });
	}

	//********************************************** */REPORTE 4: OFERTAS COMERCIALES*******************************************

	//TABLA 1: REPORTE GENERAL
	getReportOfertasComercialesTabla1(regionalesId: string, instaladorasId: string, folio: string, businessPartner: string,
		desdeFechaFirma: string, hastaFechaFirma: string, desdeFechaPES: string, hastaFechaPES: string, desdeFechaValidacionADVC: string,
		hastaFechaValidacionADVC: string, desdeFechaAprobacionADVS: string, hastaFechaAprobacionADVS: string, tipoClienteId: string,
		tipoInmuebleId: string, naceConGas: number, tipoPESId: string, instalacionConGarantia: number, tamanioComercioId: string,
		pagoAtravesDeId: string, formaDePagoId: string, tipoDeFinanciamientoId: string, nombreDelDescuento: string,
		desdeFechaVigenciaDePromocion: string, hastaFechaVigenciaDePromocion: string, costoEspecialId: string,
		nroMaterial: string, precio: string, importeDelDescuento: string, costoFinal: string,
		statusContratoId: string, statusServiceId: string): Observable<ResumenReportOfertasComercialesTabla1[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("folio", folio);
		params = params.append("businessPartner", businessPartner);
		params = params.append("desdeFechaFirma", desdeFechaFirma);
		params = params.append("hastaFechaFirma", hastaFechaFirma);
		params = params.append("desdeFechaPES", desdeFechaPES);
		params = params.append("hastaFechaPES", hastaFechaPES);
		params = params.append("desdeFechaValidacionADVC", desdeFechaValidacionADVC);
		params = params.append("hastaFechaValidacionADVC", hastaFechaValidacionADVC);
		params = params.append("desdeFechaAprobacionADVS", desdeFechaAprobacionADVS);
		params = params.append("hastaFechaAprobacionADVS", hastaFechaAprobacionADVS);
		params = params.append("tipoClienteId", tipoClienteId);
		params = params.append("tipoInmuebleId", tipoInmuebleId);
		params = params.append("naceConGas", naceConGas + "");
		params = params.append("tipoPESId", tipoPESId);
		params = params.append("instalacionConGarantia", instalacionConGarantia + "");
		params = params.append("tamanioComercioId", tamanioComercioId);
		params = params.append("pagoAtravesDeId", pagoAtravesDeId);
		params = params.append("formaDePagoId", formaDePagoId);
		params = params.append("tipoDeFinanciamientoId", tipoDeFinanciamientoId);
		params = params.append("nombreDelDescuento", nombreDelDescuento);
		params = params.append("desdeFechaVigenciaDePromocion", desdeFechaVigenciaDePromocion);
		params = params.append("hastaFechaVigenciaDePromocion", hastaFechaVigenciaDePromocion);
		params = params.append("costoEspecialId", costoEspecialId);
		params = params.append("nroMaterial", nroMaterial);
		params = params.append("precio", precio);
		params = params.append("importeDelDescuento", importeDelDescuento);
		params = params.append("costoFinal", costoFinal);
		params = params.append("statusContratoId", statusContratoId);
		params = params.append("statusServiceId", statusServiceId);
		params = params.append("minutes", new Date().getTimezoneOffset() + "");

		return this.http.get<ResumenReportOfertasComercialesTabla1[]>(`${this.getUrlReports()}/reporte-ofertas-comerciales-tabla1`, { params: params, headers: this.getHeader() });
	}


	//**************************************** */REPORTE 5: GENERAL DE FOLIOS ***************************************

	//TABLA 5: GENERAL DE FOLIOS
	getReportGeneralDeFoliosTabla1(regionalesId: string, instaladorasId: string, folio: string, folio2: string, desdeFechaFirmaContrato: string,
		hastaFechaFirmaContrato: string, statusContratoId: string, tipoPESId: string, tipoClienteId: string, desdeFechaCreacionCliente: string,
		hastaFechaCreacionCliente: string, rfc: string, estadoId: string, municipioId: string, zipCodeId: string, coloniaId: string, streetId: string,
		tipoInmuebleId: string, claveVendedor: string, businessPartner: string, cuentaContrato: string, prioridadId: string,
		desdeFechaValidacionADVC: string, hastaFechaValidacionADVC: string, desdeFechaReasignacion: string, hastaFechaReasignacion: string,
		statusServiceId: string, desdeFechaAprobacionADVS: string, hastaFechaAprobacionADVS: string, serieDelMedidor: string, motivoDeAltaId: string,
		desdeFechaPES: string, hastaFechaPES: string, supervisadoEnCampo, enviarA: string): Observable<any[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("folio", folio);
		params = params.append("folio2", folio2);
		params = params.append("desdeFechaFirmaContrato", desdeFechaFirmaContrato);
		params = params.append("hastaFechaFirmaContrato", hastaFechaFirmaContrato);
		params = params.append("statusContratoId", statusContratoId);
		params = params.append("tipoPESId", tipoPESId);
		params = params.append("tipoClienteId", tipoClienteId);
		params = params.append("desdeFechaCreacionCliente", desdeFechaCreacionCliente);
		params = params.append("hastaFechaCreacionCliente", hastaFechaCreacionCliente);
		params = params.append("rfc", rfc);
		params = params.append("estadoId", estadoId);
		params = params.append("municipioId", municipioId);
		params = params.append("zipCodeId", zipCodeId);
		params = params.append("coloniaId", coloniaId);
		params = params.append("streetId", streetId);
		params = params.append("tipoInmuebleId", tipoInmuebleId);
		params = params.append("claveVendedor", claveVendedor);
		params = params.append("businessPartner", businessPartner);
		params = params.append("cuentaContrato", cuentaContrato);
		params = params.append("prioridadId", prioridadId);
		params = params.append("desdeFechaValidacionADVC", desdeFechaValidacionADVC);
		params = params.append("hastaFechaValidacionADVC", hastaFechaValidacionADVC);
		params = params.append("desdeFechaReasignacion", desdeFechaReasignacion);
		params = params.append("hastaFechaReasignacion", hastaFechaReasignacion);
		params = params.append("statusServiceId", statusServiceId);
		params = params.append("desdeFechaAprobacionADVS", desdeFechaAprobacionADVS);
		params = params.append("hastaFechaAprobacionADVS", hastaFechaAprobacionADVS);
		params = params.append("serieDelMedidor", serieDelMedidor);
		params = params.append("motivoDeAltaId", motivoDeAltaId);
		params = params.append("desdeFechaPES", desdeFechaPES);
		params = params.append("hastaFechaPES", hastaFechaPES);
		params = params.append("supervisadoEnCampo", supervisadoEnCampo);
		params = params.append("enviarA", enviarA);

		params = params.append("minutes", new Date().getTimezoneOffset() + "");


		return this.http.get<any[]>(`${this.getUrl()}/reporte-general-de-folios-tabla1`, { params: params, headers: this.getHeader() });
	}

	//************************************************ */REPORTE 6: VENTAS******************************************************

	//TABLA 1: REPORTE GENERAL
	getReportVentasTabla1(regionalesId: string, instaladorasId: string, folio: string, desdeFechaFirmaContrato: string, hastaFechaFirmaContrato: string,
		desdeFechaPES: string, hastaFechaPES: string, desdeFechaValidacionADVC: string, hastaFechaValidacionADVC: string, desdeFechaAprobacionADVS: string,
		hastaFechaAprobacionADVS: string, tipoClienteId: string, tamanioComercioId: string, desdeFechaCreacionCliente: string, hastaFechaCreacionCliente: string,
		statusClienteId: string, rfc: string, estadoId: string, municipioId: string, zipCodeId: string, coloniaId: string, streetId: string, nroExt: string,
		nroInt: string, edificio: string, manzana: string, lote: string, tipoInmuebleId: string, naceConGas: number, tipoPESId: string, instalacionConGarantia: number,
		claveVendedor: string, statusContratoId: string, statusServiceId: string, sinActividad, origenContratoId: string, zonaId: string,
		microzonaId: string): Observable<ResumenReportVentasTabla1[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("folio", folio);
		params = params.append("desdeFechaFirmaContrato", desdeFechaFirmaContrato);
		params = params.append("hastaFechaFirmaContrato", hastaFechaFirmaContrato);
		params = params.append("desdeFechaPES", desdeFechaPES);
		params = params.append("hastaFechaPES", hastaFechaPES);
		params = params.append("desdeFechaValidacionADVC", desdeFechaValidacionADVC);
		params = params.append("hastaFechaValidacionADVC", hastaFechaValidacionADVC);
		params = params.append("desdeFechaAprobacionADVS", desdeFechaAprobacionADVS);
		params = params.append("hastaFechaAprobacionADVS", hastaFechaAprobacionADVS);
		params = params.append("tipoClienteId", tipoClienteId);
		params = params.append("tamanioComercioId", tamanioComercioId);
		params = params.append("desdeFechaCreacionCliente", desdeFechaCreacionCliente);
		params = params.append("hastaFechaCreacionCliente", hastaFechaCreacionCliente);
		params = params.append("statusClienteId", statusClienteId);
		params = params.append("estadoId", estadoId);
		params = params.append("municipioId", municipioId);
		params = params.append("zipCodeId", zipCodeId);
		params = params.append("coloniaId", coloniaId);
		params = params.append("streetId", streetId);
		params = params.append("nroExt", nroExt);
		params = params.append("nroInt", nroInt);
		params = params.append("edificio", edificio);
		params = params.append("manzana", manzana);
		params = params.append("lote", lote);
		params = params.append("tipoInmuebleId", tipoInmuebleId);
		params = params.append("naceConGas", naceConGas + "");
		params = params.append("tipoPESId", tipoPESId);
		params = params.append("instalacionConGarantia", instalacionConGarantia + "");
		params = params.append("claveVendedor", claveVendedor);
		params = params.append("statusContratoId", statusContratoId);
		params = params.append("statusServiceId", statusServiceId);
		params = params.append("sinActividad", sinActividad + "");
		params = params.append("origenContratoId", origenContratoId);
		params = params.append("zonaId", zonaId);
		params = params.append("microzonaId", microzonaId);

		params = params.append("minutes", new Date().getTimezoneOffset() + "");

		return this.http.get<ResumenReportVentasTabla1[]>(`${this.getUrlReports()}/reporte-ventas-tabla1`, { params: params, headers: this.getHeader() });
	}

	//**************************************** */REPORTE 8: AGENDAMIENTO DE CUADRILLAS***************************************

	//TABLA 1: REPORTE GENERAL
	getReportAgendamientoDeCuadrillaTabla1(regionalesId: string, instaladorasId: string, folio: string, businessPartner: string,
		nombreCuadrilla: string, responsableCuadrilla: string, desdeFechaServicio: string, hastaFechaServicio: string,
		rangoHorarioId: string, statusServiceId: string): Observable<ResumenReportAgendamientoDeCuadrillaTabla1[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("folio", folio);
		params = params.append("businessPartner", businessPartner);
		params = params.append("nombreCuadrilla", nombreCuadrilla);
		params = params.append("responsableCuadrilla", responsableCuadrilla);
		params = params.append("desdeFechaServicio", desdeFechaServicio);
		params = params.append("hastaFechaServicio", hastaFechaServicio);
		params = params.append("rangoHorarioId", rangoHorarioId);
		params = params.append("statusServiceId", statusServiceId);
		params = params.append("minutes", new Date().getTimezoneOffset() + "");

		return this.http.get<ResumenReportAgendamientoDeCuadrillaTabla1[]>(`${this.getUrlReports()}/reporte-agendamiento-de-cuadrillas-tabla1`, { params: params, headers: this.getHeader() });
	}

	//********************************************** */REPORTE 10: CAMBIOS DE ESCENARIO*******************************************

	getReportCambiosDeEscenario(regionalesId: string, sociosComercialesId: string, folio: string, desdeFecha: string, hastaFecha: string
		): Observable<ResumenReportCambiosDeEscenario[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("sociosComercialesId", sociosComercialesId);
		params = params.append("folio", folio);
		params = params.append("desdeFecha", desdeFecha);
		params = params.append("hastaFecha", hastaFecha);
		params = params.append("minutes", new Date().getTimezoneOffset() + "");

		return this.http.get<ResumenReportCambiosDeEscenario[]>(`${this.getUrlReports()}/reporte-cambios-escenario`, { params: params, headers: this.getHeader() });
	}

	//******************************************************** FENIX II **********************************************************

	//********************************** */FII_REPORTE 3: CONTRATOS CAPTURADOS VS ACOMETIDAS SOLICITADAS ******************************

	getReportContratosCapturadosVsAcometidas(regionalesId: string, instaladorasId: string, zonaId: number, statusSolicitudAcometidaId: string,
                                           tipoClienteId: number, estadoId: number, municipioId: number, zipCodeId: number, coloniaId: number, streetId: number,
                                           supervisorDeVentas: string, folio: string, desdeFechaFirmaContrato: string, hastaFechaFirmaContrato: string, desdeFechaPes: string, hastaFechaPes: string,
                                           desdeFechaValidacionADV: string, hastaFechaValidacionADV: string, desdeFechaAprobacionServicioADV: string, hastaFechaAprobacionServicioADV: string, vendedor: string,
                                           desdeFechaCapturaSolicitud: string, hastaFechaCapturaSolicitud: string, desdeFechaRespuestaSolicitud: string, hastaFechaRespuestaSolicitud: string,
                                           desdeFechaConstruccionAcometida: string, hastaFechaConstruccionAcometida: string, desdeFechaAcometidaConstruida: string, hastaFechaAcometidaConstruida: string,
                                           statusClienteId: number): Observable<any[]> {

		let params = new HttpParams();

		if (regionalesId) {
			params = params.append("regionalesId", regionalesId);
		}

		if (instaladorasId) {
			params = params.append("instaladorasId", instaladorasId);
		}

		if (zonaId && zonaId > 0) {
			params = params.append("zonaId", zonaId + "");
		}

		if (statusSolicitudAcometidaId) {
			params = params.append("statusSolicitudAcometidaId", statusSolicitudAcometidaId);
		}

		if (tipoClienteId && tipoClienteId > 0) {
			params = params.append("tipoClienteId", tipoClienteId + "");
		}

		if (estadoId && estadoId > 0) {
			params = params.append("estadoId", estadoId + "");
		}

		if (municipioId && municipioId > 0) {
			params = params.append("municipioId", municipioId + "");
		}

		if (zipCodeId && zipCodeId > 0) {
			params = params.append("zipCodeId", zipCodeId + "");
		}

		if (coloniaId && coloniaId > 0) {
			params = params.append("coloniaId", coloniaId + "");
		}

		if (streetId && streetId > 0) {
			params = params.append("streetId", streetId + "");
		}

		if (statusClienteId && statusClienteId > 0) {
			params = params.append("statusClienteId", statusClienteId + "");
		}

		params = params.append("supervisorDeVentas", supervisorDeVentas);
		params = params.append("folio", folio);
		params = params.append("desdeFechaFirmaContrato", desdeFechaFirmaContrato);
		params = params.append("hastaFechaFirmaContrato", hastaFechaFirmaContrato);
		params = params.append("desdeFechaPes", desdeFechaPes);
		params = params.append("hastaFechaPes", hastaFechaPes);
		params = params.append("desdeFechaValidacionADV", desdeFechaValidacionADV);
		params = params.append("hastaFechaValidacionADV", hastaFechaValidacionADV);
		params = params.append("desdeFechaAprobacionServicioADV", desdeFechaAprobacionServicioADV);
		params = params.append("hastaFechaAprobacionServicioADV", hastaFechaAprobacionServicioADV);
		params = params.append("vendedor", vendedor);
		params = params.append("desdeFechaCapturaSolicitud", desdeFechaCapturaSolicitud);
		params = params.append("hastaFechaCapturaSolicitud", hastaFechaCapturaSolicitud);
		params = params.append("desdeFechaRespuestaSolicitud", desdeFechaRespuestaSolicitud);
		params = params.append("hastaFechaRespuestaSolicitud", hastaFechaRespuestaSolicitud);
		params = params.append("desdeFechaConstruccionAcometida", desdeFechaConstruccionAcometida);
		params = params.append("hastaFechaConstruccionAcometida", hastaFechaConstruccionAcometida);
		params = params.append("desdeFechaAcometidaConstruida", desdeFechaAcometidaConstruida);
		params = params.append("hastaFechaAcometidaConstruida", hastaFechaAcometidaConstruida);

		return this.http.get<any[]>(`${this.getUrlReports()}/reporte-contratos-capturados-vs-acometidas-solicitadas`, { params: params, headers: this.getHeader() });
	}

	reintentarIntegracionFadNaat(id: number) {
		return this.http.put<any>(`${this.getUrl()}/${id}/retry-send-pdf`, {}, { headers: this.getHeader() });
	}

	getcontratosPorDescuento(search: Search, descuentoid: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${descuentoid}/contratos-descuentos`, {params: search.params, headers: this.getHeader()});
  }

	getMotivosTipoContrato(): Observable<any> {
		return this.http.get<any[]>(`${this.getUrl()}/motivos-tipo-contrato`, { headers: this.getHeader() });
	}

  getCaracteristicasInmueblesByIdContract(id: number): Observable<CaracteristicasInmueblesResponse> {
		return this.http.get<CaracteristicasInmueblesResponse>(`${this.getUrl()}/${id}/caracteristicas-inmuebles`, { headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { StatusMedidor } from '../model/dto/status-medidor';
import { MetadataStatusMedidor } from '../model/match/metadata-status-medidor';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StatusMedidorService extends BaseService<StatusMedidor, StatusMedidor, MetadataStatusMedidor, MetadataStatusMedidor> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'gas-meter-status';
	}

	getMetadataStatusMedidorAll():Observable<MetadataStatusMedidor>{
		return this.http.get<MetadataStatusMedidor>(`${this.getUrl()}`, { headers: this.getHeader()});
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { TipoComercio } from '../model/dto/tipo-comercio';
import { MetadataTipoComercio } from '../model/match/metadata-tipo-comercio';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TipoComercioService extends BaseService<TipoComercio, TipoComercio, MetadataTipoComercio, MetadataTipoComercio> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tipo-comercio';
	}

	getMetadataTipoComercioAll(): Observable<TipoComercio> {
		return this.http.get<TipoComercio>(`${this.getUrl()}`, { headers: this.getHeader()});
	}

}

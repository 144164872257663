import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Notificacion } from '../model/dto/notificacion';
import { ResumenNotificacion } from '../model/dto/resumen/resumen-notificacion';
import { MetadataNotificacion } from '../model/match/metadata-notificacion';
import { MetadataResumenNotificacion } from '../model/match/metadata-resumen-notificacion';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService extends BaseService<Notificacion, ResumenNotificacion, MetadataNotificacion, MetadataResumenNotificacion> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getClassUrl(): string {
    return 'notificaciones';
  }

  getResumenNotificacionesNuevas(): Observable<ResumenNotificacion[]> {
		return this.http.get<ResumenNotificacion[]>(`${this.getUrl()}/resumen/nuevas`, {headers: this.getHeader()});
  }
  
  getResumenNotificacionesSinProcesar(): Observable<ResumenNotificacion[]> {
		return this.http.get<ResumenNotificacion[]>(`${this.getUrl()}/resumen/sin-procesar`, {headers: this.getHeader()});
  }

  getCantNotificacionesNuevas(): Observable<number> {
		return this.http.get<number>(`${this.getUrl()}/count/nuevas`, {headers: this.getHeader()});
  }

  validar(dto): Observable<any> {
    return this.http.post<any>(`${this.getUrl()}/buscar-data`, dto, {headers: this.getHeader()});
  }

  enviar(dto): Observable<any> {
    return this.http.post<any>(`${this.getUrl()}/enviar`, dto, {headers: this.getHeader()});
  }
}

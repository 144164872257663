import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { MetadataTipoMotivoDesactivacion } from '../model/match/metadata-tipo-motivo-desactivacion';
import { MatchDesactiveCliente } from '../model/match/match-desactive-cliente';
import { TipoMotivoDesactivacion } from '../model/dto/tipo-motivo-desactivacion';

@Injectable({
	providedIn: 'root'
})
export class TipoMotivoDesactivacionService extends BaseService<TipoMotivoDesactivacion, TipoMotivoDesactivacion, MetadataTipoMotivoDesactivacion, MetadataTipoMotivoDesactivacion> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tipo-motivo-desactivacion';
	}

}
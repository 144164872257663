import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { TipoPersona } from '../model/dto/tipo-persona';
import { MetadataTipoPersona } from '../model/match/metadata-tipo-persona';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TipoPersonaService extends BaseService<TipoPersona, TipoPersona, MetadataTipoPersona, MetadataTipoPersona> {
	
	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tipo-persona';
	}
	getTipoPersona(page: number = -1, limit: number = -1): Observable <TipoPersona> {///TutenREST/#!/customer-type/findAll
		return this.http.get<TipoPersona>(`${this.getUrl()}?page=${page}&max=${limit}`, {headers: this.getHeader()});
	}
}

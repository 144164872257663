import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GasMeterType } from '../model/dto/gas-meter-type';
import { MetadataGasMeterType } from '../model/match/metadata-gas-meter-type';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatchCreateProveedor } from '../model/match/match-create-proveedor';
import { ResumenReportMantenedoresTabla5 } from '../model/dto/resumen/resumen-report-mantenedores-tabla5';

@Injectable({
  providedIn: 'root'
})
export class GasMeterTypeService extends BaseService<GasMeterType, GasMeterType, MetadataGasMeterType, MetadataGasMeterType> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'gas-meter-type';
	}

	create(matchCreateMedidor: MatchCreateProveedor): Observable<GasMeterType> {
		return this.http.post<GasMeterType>(this.getUrl(), matchCreateMedidor, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateMedidor: MatchCreateProveedor): Observable<GasMeterType> {
		return this.http.put<GasMeterType>(`${this.getUrl()}/${id}/update`, matchUpdateMedidor, { headers: this.getHeader() });
	}

	//******************************************************* */REPORTE 9: MANTENEDORES***************************************************

	//TABLA 5: TIPO DE MEDIDOR
	getReportMantenedoresTabla5(tipoMedidorId: string,
		codigo: string, statusGeneralId: string): Observable<ResumenReportMantenedoresTabla5[]> {

		let params = new HttpParams();

		params = params.append("tipoMedidorId", tipoMedidorId);
		params = params.append("codigo", codigo);
		params = params.append("statusGeneralId", statusGeneralId);

		return this.http.get<ResumenReportMantenedoresTabla5[]>(`${this.getUrlReports()}/reporte-mantenedores-tabla5`, { params: params, headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResumenInterfaceValidacionCliente } from '../model/dto/resumen/resumen-interface-validacion-cliente';

@Injectable({
	providedIn: 'root'
})
export class InterfaceService extends BaseService<any, any, any, any> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'interface';
	}

	getValidarClienteDireccion(rfc: string, businessPartner: string, municipio: string, zipCode: string,
		colonia: string, calle: string, nroExterior: string, nroInterior: string, nombre: string,
		nombre2: string, apellidoMaterno: string, apellidoPaterno: string, nombreOrg1: string,
		nombreOrg2: string, nombreOrg3: string, nombreOrg4: string, cuentaContrato: string): Observable<ResumenInterfaceValidacionCliente> {

		let params = new HttpParams();

		params = params.append("rfc", rfc);

		if (businessPartner != null && businessPartner != undefined && businessPartner != "" ) {
			params = params.append("businessPartner", businessPartner);
		}

		if (nombre != null && nombre != undefined && nombre != "") {
			params = params.append("nombre", nombre);
		}

		if (nombre2 != null && nombre2 != undefined && nombre2 != "") {
			params = params.append("nombre2", nombre2);
		}

		if (apellidoPaterno != null && apellidoPaterno != undefined && apellidoPaterno != "") {
			params = params.append("apellidoPaterno", apellidoPaterno);
		}

		if (apellidoMaterno != null && apellidoMaterno != undefined && apellidoMaterno != "") {
			params = params.append("apellidoMaterno", apellidoMaterno);
		}

		if (nombreOrg1 != null && nombreOrg1 != undefined && nombreOrg1 != "") {
			params = params.append("nombreOrg1", nombreOrg1);
		}

		if (nombreOrg2 != null && nombreOrg2 != undefined && nombreOrg2 != "") {
			params = params.append("nombreOrg2", nombreOrg2);
		}

		if (nombreOrg3 != null && nombreOrg3 != undefined && nombreOrg3 != "") {
			params = params.append("nombreOrg3", nombreOrg3);
		}

		if (nombreOrg4 != null && nombreOrg4 != undefined && nombreOrg4 != "") {
			params = params.append("nombreOrg4", nombreOrg4);
		}

		params = params.append("municipio", municipio);
		params = params.append("zipCode", zipCode);
		params = params.append("colonia", colonia);
		params = params.append("calle", calle);
		params = params.append("nroExterior", nroExterior);

		if (nroInterior != null && nroInterior != undefined && nroInterior != "") {
			params = params.append("nroInterior", nroInterior);
		}

    if (cuentaContrato != null && cuentaContrato != undefined && cuentaContrato != "") {
			params = params.append("cuentaContrato", cuentaContrato);
		}

		return this.http.get<ResumenInterfaceValidacionCliente>(`${this.getUrl()}/validar-cliente-direccion`, { params: params, headers: this.getHeader() });
	}

	getGoogleMapApiKey(): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/google-map-api-key`);
	}

	//********************************************** FENIX II: DIRECCIONES *******************************************
	getReverseGeocode(puntos: string): Observable<any> {
		let params = new HttpParams();

		params = params.append("location", puntos);

		return this.http.get<any>(`${this.getUrl()}/reverse-geocode`, { params: params, headers: this.getHeader() });
	}

	getAddressCandidates(singleLine: string): Observable<any> {
		let params = new HttpParams();

		params = params.append("singleLine", singleLine);

		return this.http.get<any>(`${this.getUrl()}/find-address-candidates`, { params: params, headers: this.getHeader() });
	}

	getReverseGeocodeMapBox(puntos: string): Observable<any> {
		let params = new HttpParams();

		params = params.append("location", puntos);

		return this.http.get<any>(`${this.getUrl()}/reverse-geocode-map-box`, { params: params, headers: this.getHeader() });
	}

	getAddressCandidatesMapBox(singleLine: string): Observable<any> {
		let params = new HttpParams();

		params = params.append("singleLine", singleLine);

		return this.http.get<any>(`${this.getUrl()}/find-address-candidates-map-box`, { params: params, headers: this.getHeader() });
	}

	getOptimizedTrips(puntos: string): Observable<any> {
		let params = new HttpParams();

		params = params.append("locations", puntos);

		return this.http.get<any>(`${this.getUrl()}/optimize-trips`, { params: params, headers: this.getHeader() });
	}
}

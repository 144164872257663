import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from './base.service';
import { MetadataRegionales } from '../model/match/metadata-regionales';
import { MetadataResumenRegional } from '../model/match/metadata-resumen-regional';
import { MatchCreateRegionales } from '../model/match/match-create-regionales';
import { Observable } from 'rxjs';
import { MatchUpdateRegionales } from '../model/match/match-update-regionales';
import { Regionales } from '../model/dto/regionales';
import { Municipio } from '../model/dto/municipio';
import { Instaladora } from '../model/dto/instaladora';
import { ResumenReportMatenedoresTabla1 } from '../model/dto/resumen/resumen-report-mantenedores-tabla1';
import { Search } from '../../shared/components/search/search.component';
import { MetadataResumenInstaladoraToPersistZona } from '../model/match/metadata-resumen-instaladora-to-persist-zona';
import { OrigenDatosLead } from '../model/dto/origen-datos-lead';
import { MetadataOrigenDatosLead } from '../model/match/metadata-origen-datos-lead';

@Injectable({
	providedIn: 'root'
})
export class OrigenDatosLeadService extends BaseService<OrigenDatosLead, OrigenDatosLead, MetadataOrigenDatosLead, MetadataOrigenDatosLead> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'origen-datos-lead';
	}

	getListToOptions(): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader() });
	}
}
import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {TipoProyecto} from '../model/dto/tipo-proyecto';
import {MetadataTipoProyecto} from '../model/match/metadata-tipo-proyecto';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipoProyectoService extends BaseService<TipoProyecto, TipoProyecto, MetadataTipoProyecto, MetadataTipoProyecto> {

  constructor(protected http: HttpClient) {
    super(http)
  }

  getClassUrl(): string {
    return 'tipos-proyecto';
  }

  getProyectosEtapaListToOptions(): Observable <any> {
    return this.http.get<any>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader()});
  }

}

import { Injectable } from '@angular/core';
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { SupervisionService } from '../model/dto/supervision-service';
import { MetadataSupervisionService } from '../model/match/metadata-supervision-service';
import { MatchCreateSupervisionService } from '../model/match/match-create-supervision-service';
import { ResumenSupervisionService } from '../model/dto/resumen/resumen-supervision-service';
import { MetadataResumenSupervisionService } from '../model/match/metadata-resumen-supervision-service';

@Injectable({
	providedIn: 'root'
})
export class SupervisionServiceService extends BaseService<SupervisionService, ResumenSupervisionService, MetadataSupervisionService, MetadataResumenSupervisionService> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'supervision-service';
	}

	update(id: number, data): Observable<SupervisionService> {
	 	return this.http.put<SupervisionService>(`${this.getUrl()}/${id}`, data, {headers: this.getHeader()});
	}

	// delete(id: number): Observable<ActividadNegocio> {
	// 	return this.http.delete<ActividadNegocio>(`${this.getUrl()}/${id}`, {headers: this.getHeader()});
	// }

	create(matchCreateSupervisionService: MatchCreateSupervisionService): Observable<SupervisionService> {
		return this.http.post<SupervisionService>(this.getUrl(), matchCreateSupervisionService, {headers: this.getHeader()});
	}

	getDocumentos(supervisionId: number): Observable<any>{
		return this.http.get<any>(`${this.getUrl()}/${supervisionId}/documentos`, { headers: this.getHeader() });
	}
	
	deleteFile(idSupervison:number, fileId: number) {
		return this.http.delete(`${this.getUrl()}/${idSupervison}/documentos/${fileId}`, { headers: this.getHeader() });
	}
}
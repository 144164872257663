import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '../../shared/components/search/search.component';
import { Recurso } from '../model/dto/recurso';
import { MetadataRecurso } from '../model/match/metadata-recurso';
import { MatchCreateRecurso } from '../model/match/match-create-recurso';
import { MatchUpdateRecurso } from '../model/match/match-update-recurso';
@Injectable({
	providedIn: 'root'
})
export class RecursoService extends BaseService<Recurso, Recurso, MetadataRecurso, MetadataRecurso> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'recurso';
	}

	create(matchCreateRecurso: MatchCreateRecurso): Observable<any> {
		return this.http.post<any>(this.getUrl(), matchCreateRecurso, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateRecurso: MatchUpdateRecurso): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}`, matchUpdateRecurso, { headers: this.getHeader() });
	}

	delete(recursoId: number): Observable<any> {
		return this.http.delete<any>(`${this.getUrl()}/${recursoId}`, {headers: this.getHeader()});
	}

	getMetadataResumenListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen/v2`, { params: search.params, headers: this.getHeader() });
	}

  getRegionalesRecurso(recursoId: Search): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${recursoId}/regionales`, {headers: this.getHeader() });
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Login } from '../../../api/model/match/login';
import { UserService } from '../../../api/rest/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxPermissionsService } from 'ngx-permissions';
import { InternetConnectionService } from '../../../shared/service/internet-connection.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	formLogin: FormGroup;
	showpassword: boolean = false;

	constructor(private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private router: Router,
		private userService: UserService,
		private ngxPermissionsService: NgxPermissionsService,
		private internetConnectionService: InternetConnectionService) {

		this.initForms();

		window.addEventListener("offline", function () {
			internetConnectionService.connected = false;
		});

		window.addEventListener("online", function () {
			internetConnectionService.connected = true;
		});
	}

	ngOnInit() {
		if (localStorage.getItem("token_expired")) {
			localStorage.clear();

			this.toastr.error("El token ha expirado o ha iniciado sesión desde otro dispositivo . Por favor inicie sesión nuevamente.");
		}
	}

	initForms(): void {
		this.formLogin = this.formBuilder.group({
			login: this.formBuilder.control('', [Validators.required]),
			pass: this.formBuilder.control('', [Validators.required])
		});
	}

	onEnter(event) {
		if (event.keyCode === 13) {
			this.onClickLogin();
		}
	}

	onClickLogin() {
		let login: Login = new Login();
		login.login = this.formLogin.value.login;
		login.password = this.formLogin.value.pass;

		this.userService.login(login).subscribe((data: any) => {
			if(data.isNewUser) {
					this.router.navigate([`/cambiar-contrasena/${data.token}`]);
				return;
			}

			if(data.twoStepAuthentication) {
		        localStorage.setItem('twoStepAuthenticationToken', data.token);
		        location.href = "verificar-codigo";
		        return;
		    }

			localStorage.setItem('isLoggedin', 'true');
			localStorage.setItem('eTag', data.token);
			localStorage.setItem('userId', data.id);
			localStorage.setItem('roleTypeId', data.roleTypeId);
			localStorage.setItem('instaladoraId', data.instaladoraId);
			localStorage.setItem('regionalId', data.regionalId);
			localStorage.setItem('vendedorId', data.vendedorId);
			localStorage.setItem('firstName', data.firstName);
			localStorage.setItem('lastName', data.lastName);
			localStorage.setItem('permissions', data.permissions);
			localStorage.setItem('refreshToken', data.refreshToken);
			localStorage.setItem('tokenReporte', data.tokenReporte);
			localStorage.setItem('refreshBefore', data.refreshBefore);
			localStorage.setItem('isSuperAdmin', data.isSuperAdmin);
			localStorage.setItem('isVendedor', data.isVendedor);
			localStorage.setItem('isJefeDeCuadrilla', data.isJefeDeCuadrilla);
			localStorage.setItem('nombreRegional', data.nombreRegional);
			localStorage.setItem('nombreInstaladora', data.nombreInstaladora);
			localStorage.setItem('nombreRoles', data.nombreRoles);
			localStorage.setItem('nombreRoleType', data.nombreRoleType);
			localStorage.setItem('nombreCuadrilla', data.nombreCuadrilla);
			localStorage.setItem('isSupervisorDeVentas', data.isSupervisorDeVentas);
			localStorage.setItem('isJefeVentas', data.isJefeVentas);
			localStorage.setItem('isProyectosEstrategicos', data.isProyectosEstrategicos);
			localStorage.setItem('objetivoId', data.objetivoId);

			if (data.permissions != null && data.permissions != undefined && data.permissions != '') {
				this.ngxPermissionsService.loadPermissions(data.permissions.split(","));
			}

			location.href = "office/home/dashboard";
		}, (httpErrorResponse: HttpErrorResponse) => {
			if (httpErrorResponse instanceof TypeError) {
				return;
			}

			if (httpErrorResponse.status == 502) {
				this.toastr.success("Reconectando a la red", "", { enableHtml: true });

				return;
			}

			if (httpErrorResponse.status == 404) {
				this.toastr.success("Reconectando a la red", "", { enableHtml: true });

				return;
			}

			this.toastr.error(httpErrorResponse.error);
		});
	}
	spsd() {
		this.showpassword = this.showpassword == false ? true : false;
	}
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Base } from '../model/dto/base';
import { ResumenBase } from '../model/dto/resumen/resumen-base';
import { BaseMetadata } from '../model/match/base-metadata';
import { Search } from '../../shared/components/search/search.component';

@Injectable({
	providedIn: 'root'
})
export abstract class BaseService<E extends Base, T extends ResumenBase, Z extends BaseMetadata<E>, X extends BaseMetadata<T>> {

	static readonly API_V1 = environment.API_BASE_URL + "";
	static readonly API_V1_REPORTS = environment.API_BASE_URL_REPORTS + "";

	constructor(protected http: HttpClient) {

	}

	getBaseUrl(): string {
		return BaseService.API_V1;
	}

	getBaseUrlReports(): string {
		return BaseService.API_V1_REPORTS;
	}

	getUrl(): string {
		return `${this.getBaseUrl()}${this.getClassUrl()}`;
	}

	getUrlReports(): string {
		return `${this.getBaseUrlReports()}${this.getClassUrl()}`;
	}
	
	abstract getClassUrl(): string;

	getMetadataList(page: number = -1, limit: number = -1, order = -1, field = "id"): Observable<Z> {
		let params = new HttpParams();

		params = params.append("page", page.toString());
		params = params.append("max", limit.toString());
		params = params.append("field", field.toString());
		params = params.append("order", order.toString());

		return this.http.get<Z>(`${this.getUrl()}`, { params: params, headers: this.getHeader() });
	}

	getMetadataListSearch(search: Search): Observable<Z> {
		return this.http.get<Z>(`${this.getUrl()}`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataResumenList(page: number = -1, limit: number = -1, order = -1, field = "id"): Observable<X> {
		let params = new HttpParams();

		params = params.append("page", page.toString());
		params = params.append("max", limit.toString());
		params = params.append("field", field.toString());
		params = params.append("order", order.toString());

		return this.http.get<X>(`${this.getUrl()}/resumen`, { params: params, headers: this.getHeader() });
	}

	getMetadataResumenListSearch(search: Search): Observable<X> {
		return this.http.get<X>(`${this.getUrl()}/resumen`, { params: search.params, headers: this.getHeader() });
	}

	getList(): Observable<E[]> {
		return this.http.get<E[]>(`${this.getUrl()}`, { headers: this.getHeader() });
	}

	getResumenList(): Observable<T[]> {
		return this.http.get<T[]>(`${this.getUrl()}/resumen`, { headers: this.getHeader() });
	}

	getById(id: number): Observable<E> {
		return this.http.get<E>(`${this.getUrl()}/${id}`, { headers: this.getHeader() });
	}

	protected getHeader(): HttpHeaders {
		return new HttpHeaders({
			'jwt': localStorage.getItem("eTag")
		});
	}

  protected getHeaderBlod(): HttpHeaders {
    return new HttpHeaders({
      'jwt': localStorage.getItem("eTag"),
      'responseType': 'blod' as 'json'
    });
  }
}

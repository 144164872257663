import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from '../app/office/sesion/login/login.component';
import { NotFoundComponent } from '../app/office/sesion/not-found/not-found.component';
import { RecuperarContrasenaComponent } from '../app/office/sesion/recuperar-contrasena/recuperar-contrasena.component';
import { AuthGuard } from '../app/office/sesion/auth/auth.guard';
import { CambiarContrasenaComponent } from '../app/office/sesion/recuperar-contrasena/cambiar-contrasena.component';
import { NotPermissionsComponent } from './office/sesion/not-permissions/not-permissions.component';
import { AuthLoginGuard } from '../app/office/sesion/auth/auth-login.guard';
import { CerrarSesionComponent } from './office/sesion/cerrar-sesion/cerrar-sesion.component';
import { LinkComponent } from '../app/office/sesion/link/link.component';
import { VerificarCodigoComponent } from './office/sesion/verificar-codigo/verificar-codigo.component';

const routes: Routes = [
	{ path: 'link', component: LinkComponent },
	{ path: 'office', loadChildren: './office/office.module#OfficeModule', canActivate: [AuthGuard]},
	{ path: 'login', component: LoginComponent, canActivate: [AuthLoginGuard]},
	{ path: 'recuperar-contrasena', component: RecuperarContrasenaComponent },
  { path: 'verificar-codigo', component: VerificarCodigoComponent },
	{ path: 'cambiar-contrasena/:token', component: CambiarContrasenaComponent },
	{ path: 'cerrar-sesion', component: CerrarSesionComponent },
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'not-permission',component: NotPermissionsComponent },
	{ path: '**',component: NotFoundComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { Descuento } from '../model/dto/descuento';
import { MatchCreateDescuentoPromociones } from '../model/match/match-create-descuento-promociones';
import { MatchUpdateDescuentoPromociones } from '../model/match/match-update-descuento-promociones';
import { MatchBusquedaDescuento } from '../model/match/match-busqueda-descuento';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MetadataDescuento } from '../model/match/metadata-tipo-descuento';
import { Regionales } from '../model/dto/regionales';
import { ResumenDescuento } from '../model/dto/resumen/resumen-descuento';
import { MetadataResumenDescuento } from '../model/match/metadata-resumen-descuento';
import { ResumenReportOfertasComercialesTabla4 } from '../model/dto/resumen/resumen-report-ofertas-comerciales-tabla4';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class DescuentoService extends BaseService<Descuento, ResumenDescuento, MetadataDescuento, MetadataResumenDescuento> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'descuento';
	}

	create(matchCreateDescuento: MatchCreateDescuentoPromociones): Observable<Descuento> {
		return this.http.post<Descuento>(`${this.getUrl()}/`, matchCreateDescuento, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateDescuento: MatchUpdateDescuentoPromociones): Observable<Descuento> {
		return this.http.put<Descuento>(`${this.getUrl()}/update/${id}`, matchUpdateDescuento, { headers: this.getHeader() });
	}

	delete(id: number): Observable<Descuento> {
		return this.http.put<Descuento>(`${this.getUrl()}/delete/${id}`, null, { headers: this.getHeader() });
	}

	getDescuentoByID(id: number): Observable<Descuento[]> {
		return this.http.get<Descuento[]>(`${this.getUrl()}/${id}`, { headers: this.getHeader() });
	}

	getDescuentoToContrato(negocioId: number, matchBusquedaDescuento: MatchBusquedaDescuento): Observable<Descuento[]> {
		return this.http.post<Descuento[]>(`${this.getUrl()}/descuento-to-contrato/${negocioId}`, matchBusquedaDescuento, { headers: this.getHeader() });
	}

	getRegionales(id:number): Observable <{value: Regionales[], amount: {id: number, amount: number}[]}>{
		return this.http.get<[Regionales, number][]>(`${this.getUrl()}/${id}/regionales`, {headers: this.getHeader()})
    .pipe(map(response => {
      const value: Regionales[] = response.map(regional => ({...regional[0]}));
      const amount: {id: number, amount: number}[]  = response.map(regional => ({id: regional[0].id, amount: regional[1]}));

      return {value, amount};
    }))
	}

	//********************************************** */REPORTE 4: OFERTAS COMERCIALES*******************************************

	//TABLA 4: DESCUENTOS Y PROMOCIONES
	getReportOfertasComercialesTabla4(regionalesId: string, nombreDelDescuento: string, descripcionDescuento: string, tipoDePrecioId: string, cantidadPrecio: string,
		materialDescuento: string, tipoDeServicioId: string,nombrePresupuesto: string, formaDePagoId: string,tipoPESId: string,
		tipoClienteId: string, desdeFechaInicioDescuento: string, hastaFechaInicioDescuento: string, desdeFechaFinDescuento: string,
		hastaFechaFinDescuento: string, statusId: string):
		Observable<ResumenReportOfertasComercialesTabla4[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("nombreDelDescuento", nombreDelDescuento);
		params = params.append("descripcionDescuento", descripcionDescuento);
		params = params.append("tipoDePrecioId", tipoDePrecioId);
		params = params.append("cantidadPrecio", cantidadPrecio);
		params = params.append("materialDescuento", materialDescuento);
		params = params.append("tipoDeServicioId", tipoDeServicioId);
		params = params.append("nombrePresupuesto", nombrePresupuesto);
		params = params.append("formaDePagoId", formaDePagoId);
		params = params.append("tipoPESId", tipoPESId);
		params = params.append("tipoClienteId", tipoClienteId);
		params = params.append("desdeFechaInicioDescuento", desdeFechaInicioDescuento);
		params = params.append("hastaFechaInicioDescuento", hastaFechaInicioDescuento);
		params = params.append("desdeFechaFinDescuento", desdeFechaFinDescuento);
		params = params.append("hastaFechaFinDescuento", hastaFechaFinDescuento);
		params = params.append("statusId", statusId);

		return this.http.get<ResumenReportOfertasComercialesTabla4[]>(`${this.getUrlReports()}/reporte-ofertas-comerciales-tabla4`, { params: params, headers: this.getHeader() });
	}
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import {  Router } from '@angular/router';
import { UserService } from '../../../api/rest/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-verificar-codigo',
  templateUrl: './verificar-codigo.component.html',
  styleUrls: ['./verificar-codigo.component.css']
})
export class VerificarCodigoComponent implements OnInit {
  private countTry: number = 0;
  public KEY: string = "6Ldu_3kkAAAAAAgclpiQTgo3NRSndyQHUewxp5Xu"; //6LfdPosUAAAAAM37RyPYupIpLEu-FZlJKpGN8qDC
  @ViewChild("reCaptcha")
	reCaptcha: any;

	formCambio: FormGroup;

	token: string;

	constructor(
		private formBuilder: FormBuilder,
		private userService: UserService,
    private ngxPermissionsService: NgxPermissionsService,
		private router: Router,
		private toastr: ToastrService
	) {
	}

	ngOnInit() {
		this.initForms();
    this.token = localStorage.getItem('twoStepAuthenticationToken');
	}

	initForms() {
		this.formCambio = this.formBuilder.group({
			code: this.formBuilder.control(''),
      captcha : this.formBuilder.control(''),
		});

		this.formCambio.controls['code'].setValidators([
			Validators.required,
      Validators.maxLength(6),
			Validators.minLength(6)
		]);

    this.formCambio.controls['captcha'].setValidators([
			Validators.required
		]);
	}


  enviarCodigo() {
    let form: any = this.formCambio.value;

		this.userService.validateCode(form.code, this.token, form.captcha).subscribe((data: any) => {
			localStorage.setItem('isLoggedin', 'true');
			localStorage.setItem('eTag', data.token);
			localStorage.setItem('userId', data.id);
			localStorage.setItem('roleTypeId', data.roleTypeId);
			localStorage.setItem('instaladoraId', data.instaladoraId);
			localStorage.setItem('regionalId', data.regionalId);
			localStorage.setItem('vendedorId', data.vendedorId);
			localStorage.setItem('firstName', data.firstName);
			localStorage.setItem('lastName', data.lastName);
			localStorage.setItem('permissions', data.permissions);
			localStorage.setItem('refreshToken', data.refreshToken);
			localStorage.setItem('tokenReporte', data.tokenReporte);
			localStorage.setItem('refreshBefore', data.refreshBefore);
			localStorage.setItem('isSuperAdmin', data.isSuperAdmin);
			localStorage.setItem('isVendedor', data.isVendedor);
			localStorage.setItem('isJefeDeCuadrilla', data.isJefeDeCuadrilla);
			localStorage.setItem('nombreRegional', data.nombreRegional);
			localStorage.setItem('nombreInstaladora', data.nombreInstaladora);
			localStorage.setItem('nombreRoles', data.nombreRoles);
			localStorage.setItem('nombreRoleType', data.nombreRoleType);
			localStorage.setItem('nombreCuadrilla', data.nombreCuadrilla);
			localStorage.setItem('isSupervisorDeVentas', data.isSupervisorDeVentas);
			localStorage.setItem('isJefeVentas', data.isJefeVentas);
			localStorage.setItem('isProyectosEstrategicos', data.isProyectosEstrategicos);
			localStorage.setItem('objetivoId', data.objetivoId);
      		localStorage.removeItem('twoStepAuthenticationToken');

			if (data.permissions != null && data.permissions != undefined && data.permissions != '') {
				this.ngxPermissionsService.loadPermissions(data.permissions.split(","));
			}

			location.href = "office/home/dashboard";
		}, (httpErrorResponse: HttpErrorResponse) => {
			this.countTry += 1;
			localStorage.setItem('countTry', this.countTry.toString());

			if(this.countTry >= 3 || (httpErrorResponse.status === 401 && httpErrorResponse.error !== 'El código ingresado es incorrecto a yo ha sido utilizado')) {
				this.cancelarLogin();
			}

			if (httpErrorResponse instanceof TypeError) {
				return;
			}

			if (httpErrorResponse.status == 502) {
				this.toastr.success("Reconectando a la red", "", { enableHtml: true });

				return;
			}

			if (httpErrorResponse.status == 404) {
				this.toastr.success("Reconectando a la red", "", { enableHtml: true });

				return;
			}

			this.toastr.error(httpErrorResponse.error);
		});
	}

	cancelarLogin(){
    this.reCaptcha.reset();
		this.router.navigate(['/login']);
	}

	showError(message: string) {
		this.toastr.error(message, "", {enableHtml: true});
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataServiceFeasibility } from '../model/match/metadata-service-feasibility';
import { HttpClient } from '@angular/common/http';
import { ServiceFeasibility } from '../model/dto/service-feasibility';

@Injectable({
	providedIn: 'root'
})
export class ServiceFeasibilityService extends BaseService<ServiceFeasibility, ServiceFeasibility, MetadataServiceFeasibility, MetadataServiceFeasibility> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'service-feasibility';
	}

}

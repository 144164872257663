import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataGasMeter } from '../model/match/metadata-gas-meter';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GasMeter } from '../model/dto/gas-meter';
import { Search } from '../../shared/components/search/search.component';
import { MetadataResumenGasMeterToAssign } from '../model/match/metadata-resumen-gas-meter-to-assign';
import { MatchUpdateGasMeter } from '../model/match/match-update-gas-meter';
import { Deposit } from '../model/dto/deposit';
import { ResumenGasMeter } from '../model/dto/resumen/resumen-gas-meter';
import { MetadataResumenGasMeter } from '../model/match/metadata-resumen-gas-meter';
import { ResumenReportMedidores } from '../model/dto/resumen/resumen-report-medidores';
import { GasMeterBySceneryChangePayload, GasMeterSendReusablePayload, ResumenMedidorBySerialNumber, ResumenMedidorCambioEscenario } from '../model/dto/resumen/resumen-medidor-by-serial-number';
import { ResumenClassForReading, ResumenReasonForReading } from '../model/dto/resumen/resumen-motivo-lectura';

@Injectable({
	providedIn: 'root'
})
export class GasMeterService extends BaseService<GasMeter, ResumenGasMeter, MetadataGasMeter, MetadataResumenGasMeter> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getMetadataResumenGasMeterToAssignListSearch(search: Search, regionalId: number = null, instaladoraId: number = null): Observable<MetadataResumenGasMeterToAssign> {

		let urlAdm = "";
		let preAdm = "";

		if (regionalId != null && regionalId != undefined) {
			urlAdm = "/" + regionalId;

			if (instaladoraId != null && instaladoraId != undefined) {
				urlAdm += "/" + instaladoraId;
			}
		}

		if (urlAdm != "") {
			preAdm = "/admin";
		}

		return this.http.get<MetadataResumenGasMeterToAssign>(`${this.getUrl()}${preAdm}/resumen-to-assign${urlAdm}`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataResumenGasMeterToAssignToService(serviceId: number, search: Search, showSuccessService: boolean = false): Observable<MetadataGasMeter> {
    let params: HttpParams = search.params;

    if (showSuccessService) {
        params = params.append('showSuccessService', 'true');
    }
		return this.http.get<MetadataGasMeter>(`${this.getUrl()}/resumen-availables-to-assign-to-service/${serviceId}`, { params, headers: this.getHeader() });
	}

	getClassUrl(): string {
		return 'gas-meter';
	}

	getStatusGasMeter(id:number):Observable<MetadataGasMeter>{
		return this.http.get<MetadataGasMeter>(`${this.getUrl()}/${id}/detail`, { params: null, headers: this.getHeader() });
	}

	update(id:number, matchUpdateGasMeter: MatchUpdateGasMeter):Observable<GasMeter>{
		return this.http.put<GasMeter>(`${this.getUrl()}/${id}`, matchUpdateGasMeter, { headers: this.getHeader() } );
	}

	moverANuevo(gasMeterId: number): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${gasMeterId}/mover-a-nuevo`, {}, { headers: this.getHeader() });
	}

	moverAReciclado(gasMeterId: number): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${gasMeterId}/mover-a-reciclado`, {}, { headers: this.getHeader() });
	}

	moverADefectuoso(gasMeterId: number): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${gasMeterId}/mover-a-defectuoso`, {}, { headers: this.getHeader() });
	}

	moverADisponible(gasMeterId: number, instaladora: number): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${gasMeterId}/mover-a-disponible/${instaladora}`, {}, { headers: this.getHeader() });
	}

	getDetalleCliente(clienteId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${clienteId}/detalle-cliente`, { params: null, headers: this.getHeader() });
	}

	getDireccionMedidorDetalleCliente(clienteId: number, origen:string,contrato: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${clienteId}/detalle-medidores-demontados/${origen}/contrato/${contrato}`, { params: null, headers: this.getHeader() });
	}

	getListaMedidoresDesmontados(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/lista-medidores-desmontados`, { params: search.params, headers: this.getHeader() });
	}

	getMedidor(medidorId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${medidorId}/detalle-medidor`, { params: null, headers: this.getHeader() });
	}

	//********************************************** */REPORTE 7: MEDIDORES********************************************************

	getReportMedidores(regionalesId: string, instaladorasId: string, codigoDeBarra: string, numeroDeSerie: string,
		desdeFechaCargaMedidor: string, hastaFechaCargaMedidor: string,desdeFechaPES: string, hastaFechaPES: string,
		tipoDeMedidor: string, materialDeMedicion: string, materialDeAlmacen: string, almacen: string, deposito: string,
		folio: string,fabricanteId: string, enviarA: string, origenMedidor:string, statusMedidorSapId: string, statusMedidorAprobacionId: string): Observable<ResumenReportMedidores[]> {

		let params = new HttpParams();

		params = params.append("regionalesId", regionalesId);
		params = params.append("instaladorasId", instaladorasId);
		params = params.append("codigoDeBarra", codigoDeBarra);
		params = params.append("numeroDeSerie", numeroDeSerie);
		params = params.append("desdeFechaCargaMedidor", desdeFechaCargaMedidor);
		params = params.append("hastaFechaCargaMedidor", hastaFechaCargaMedidor);
		params = params.append("desdeFechaPES", desdeFechaPES);
		params = params.append("hastaFechaPES", hastaFechaPES);
		params = params.append("tipoDeMedidor", tipoDeMedidor);
		params = params.append("materialDeMedicion", materialDeMedicion);
		params = params.append("materialDeAlmacen", materialDeAlmacen);

		params = params.append("almacen", almacen);
		params = params.append("deposito", deposito);
		params = params.append("folio", folio);
		params = params.append("fabricanteId", fabricanteId);
		params = params.append("enviarA", enviarA);
		params = params.append("origenMedidor", origenMedidor);
		params = params.append("statusMedidorSapId", statusMedidorSapId);
		params = params.append("statusMedidorAprobacionId", statusMedidorAprobacionId);

		params = params.append("minutes", new Date().getTimezoneOffset() + "");

		return this.http.get<ResumenReportMedidores[]>(`${this.getUrl()}/reporte-medidores-general`, { params: params, headers: this.getHeader() });
	}

	/************* REPORTE DESMONTADOS*********************/

	generateReporteDesmontajes(enviarA: string, regionalId: string, desdefechaDesmontajeSistema: string, hastafechaDesmontajeSistema: string, desdefechaDesmontajeCampo: string, hastafechaDesmontajeCampo: string, desdefechaEstatusMedidor: string, hastafechaEstatusMedidor: string, desdefechaEstatusSap: string, hastafechaEstatusSap: string, instaladorasIds: string, statusMedidor: string): Observable<any[]> {
		let params = new HttpParams();
		params = params.append('enviarA', enviarA);
		params = params.append('regional', regionalId);
		params = params.append('desdefechaDesmontajeSistema', desdefechaDesmontajeSistema);
		params = params.append('hastafechaDesmontajeSistema', hastafechaDesmontajeSistema);
		params = params.append('desdefechaDesmontajeCampo', desdefechaDesmontajeCampo);
		params = params.append('hastafechaDesmontajeCampo', hastafechaDesmontajeCampo);
		params = params.append('desdefechaEstatusMedidor', desdefechaEstatusMedidor);
		params = params.append('hastafechaEstatusMedidor', hastafechaEstatusMedidor);
		params = params.append('desdefechaStatusSAP', desdefechaEstatusSap);
		params = params.append('hastafechaStatusSAP', hastafechaEstatusSap);
		params = params.append('instaladorasId', instaladorasIds);
		params = params.append('estatusMedidor', statusMedidor);
		console.log(params);

		return this.http.get<any[]>(`${this.getUrl()}/reporte-desmontados-general`, {params: params, headers: this.getHeader()});
  	}




	/************* REPORTE CLIENTES ACTIVOS*********************/

	generateReporteClientesActivos(enviarA: string, regionalId: string, desdefechaProspecto: string, hastafechaProspecto: string, desdefechaEstatusSap: string, hastafechaEstatusSap: string, desdefechaValidacionContratoADV: string, hastafechaValidacionContratoADV: string, desdefechaValidacionServicioADV:string, hastafechaValidacionServicioADV: string, estatuscliente: string, estatusContratos: string, estatusServicio: string, municipioIds: string, codPostal: string, desdefechaPesSap: string, hastafechaPesSap: string): Observable<any[]> {
		let params = new HttpParams();
		params = params.append('enviarA', enviarA);
		params = params.append('regional', regionalId);
		params = params.append('desdefechaProspecto', desdefechaProspecto);
		params = params.append('hastafechaProspecto', hastafechaProspecto);
		params = params.append('desdefechaStatusSAP', desdefechaEstatusSap);
		params = params.append('hastafechaStatusSAP', hastafechaEstatusSap);
		params = params.append('desdefechaValidacionContratoADV', desdefechaValidacionContratoADV);
		params = params.append('hastafechaValidacionContratoADV', hastafechaValidacionContratoADV);
		params = params.append('desdefechaValidacionServicioADV', desdefechaValidacionServicioADV);
		params = params.append('hastafechaValidacionServicioADV', hastafechaValidacionServicioADV);
		params = params.append('estatuscliente', estatuscliente);
		params = params.append('estatusContratos', estatusContratos);
		params = params.append('estatusServicio', estatusServicio);
		params = params.append('municipio', municipioIds);
		params = params.append('codPostal', codPostal);
		params = params.append('desdefechaPesSAP', desdefechaPesSap);
		params = params.append('hastafechaPesSAP', hastafechaPesSap);
		console.log(params);

		return this.http.get<any[]>(`${this.getUrl()}/reporte-clientes-activos-general`, {params: params, headers: this.getHeader()});
  	}

    getMedidorBySerialNumner(serialNumner: string): Observable<ResumenMedidorBySerialNumber[]> {
      let params = new HttpParams();
      params = params.append('serieNumber', serialNumner);

      return this.http.get<ResumenMedidorBySerialNumber[]>(`${this.getUrl()}/general/serie-number`, { params, headers: this.getHeader() });
    }

    getMedidorCambioEscenario(id: number): Observable<ResumenMedidorCambioEscenario> {
      return this.http.get<ResumenMedidorCambioEscenario>(`${this.getUrl()}/cambio-de-escenario-resumen/${id}`, { params: null, headers: this.getHeader() });
    }

	getMotivosDesmontajes(): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/motivo-desmontaje`, { params: null, headers: this.getHeader() });
	  }

    getReasonForReading(): Observable<ResumenReasonForReading> {
      return this.http.get<ResumenReasonForReading>(`${this.getUrl()}/motivo-lectura`, { params: null, headers: this.getHeader() });
    }

    getClassForReading(): Observable<ResumenClassForReading> {
      return this.http.get<ResumenClassForReading>(`${this.getUrl()}/clase-lectura`, { params: null, headers: this.getHeader() });
    }

    putMedidorCambioEscenario(id: number, payload: GasMeterBySceneryChangePayload): Observable<void> {
      return this.http.put<void>(`${this.getUrl()}/cambio-de-escenario-resumen/${id}`, payload, { params: null, headers: this.getHeader() });
    }

		putMedidorEnvioReutilizable(id: number, payload: GasMeterSendReusablePayload): Observable<void> {
			return this.http.put<void>(`${this.getUrl()}/envio-bandeja-reutilizable/${id}`, payload, { params: null, headers: this.getHeader() });
		}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Search } from '../../shared/components/search/search.component';
import { Observable } from 'rxjs';
import { MatchUpdateRegimenFiscal } from '../model/match/match-update-regimen-fiscal';
import { MatchCreateRegimenFiscal } from '../model/match/match-create-regimen-fiscal';

@Injectable({
	providedIn: 'root'
})
export class RegimenFiscalService extends BaseService<any, any, any, any> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'regimen_fiscal';
	}

	getListToOptions(): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader()});
	}

	getTiposRegimen(): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/tipos-regimen`, { headers: this.getHeader()});
	}

	getMetadataResumenListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen/v2`, { params: search.params, headers: this.getHeader() });
	}

	create(matchCreateRegimenFiscal: MatchCreateRegimenFiscal): Observable<any> {
		return this.http.post<any>(this.getUrl(), matchCreateRegimenFiscal, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateRegimenFiscal: MatchUpdateRegimenFiscal): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}`, matchUpdateRegimenFiscal, { headers: this.getHeader() });
	}

	delete(regFiscalId: number): Observable<any> {
		return this.http.delete<any>(`${this.getUrl()}/${regFiscalId}`, {headers: this.getHeader()});
	}

	getUsosCfdis(regFiscalId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${regFiscalId}/regimenes-fiscales-cfdi`, {headers: this.getHeader() });
	}
}

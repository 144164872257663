import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { MetadataServiceMeasuredPressure } from '../model/match/metadata-service-measured-pressure';
import { ServiceMeasuredPressure } from '../model/dto/service-measured-pressure';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ServiceMeasuredPressureService extends BaseService<ServiceMeasuredPressure, ServiceMeasuredPressure, MetadataServiceMeasuredPressure, MetadataServiceMeasuredPressure> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'service-measured-pressure';
	}

	getByService(serviceId: number): Observable<ServiceMeasuredPressure[]> {
		return this.http.get<ServiceMeasuredPressure[]>(`${this.getUrl()}/by-service/${serviceId}`, { headers: this.getHeader() });
	}
}

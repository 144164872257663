import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataService } from '../model/match/metadata-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '../../shared/components/search/search.component';
import { RiesgoMicrozona } from '../model/dto/riesgo-microzona';
import { MatchCreateZona } from '../model/match/match-create-zona';
import { MetadataRiesgoMicrozona } from '../model/match/metadata-riesgo-microzona';

@Injectable({
	providedIn: 'root'
})
export class RiesgoMicrozonaService extends BaseService<RiesgoMicrozona, RiesgoMicrozona, MetadataRiesgoMicrozona, MetadataRiesgoMicrozona> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'riesgo-microzona';
	}

	getListToOptions(): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader() });
	}

}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Estado } from '../model/dto/estado';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MetadataEstado } from '../model/match/metadata-estado';
import { Municipio } from '../model/dto/municipio';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EstadoService extends BaseService<Estado, Estado, MetadataEstado, MetadataEstado> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'estado';
	}

	getMunicipios(id: number): Observable<Municipio[]> {
		return this.http.get<Municipio[]>(`${this.getUrl()}/${id}/municipios`, { headers: this.getHeader() });
	}

	getMunicipiosWithRegionalFilter(id: number, comercial=false): Observable<Municipio[]> {
		let params = new HttpParams();
		if(comercial){
			params = params.append("comercial", "true");
		}
		return this.http.get<Municipio[]>(`${this.getUrl()}/${id}/municipios-with-regional-filter`, { params: params, headers: this.getHeader() });
	}

	getEstadosWithRegionalFilter(comercial=false): Observable<Estado[]> {
		let params = new HttpParams();
		if(comercial){
			params = params.append("comercial", "true");
		}
		return this.http.get<Estado[]>(`${this.getUrl()}/get-all-with-regional-filter`, {params: params, headers: this.getHeader() });
	}	

	getEstadosWithRegionalFilterToCentral(regionalId: number): Observable<Estado[]> {
		return this.http.get<Estado[]>(`${this.getUrl()}/get-all-with-regional-filter-to-central/${regionalId}`, { headers: this.getHeader() });
	}

  getMunicipiosWithRegional(regionalId: number): Observable<Municipio[]> {
    return this.http.get<Municipio[]>(`${this.getUrl()}/${regionalId}/municipios-with-regional`, { headers: this.getHeader() });
  }
}

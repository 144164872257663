import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { TipoVivienda } from '../model/dto/tipo-vivienda';
import { MetadataTipoVivienda } from '../model/match/metadata-tipo-vivienda';

@Injectable({
  providedIn: 'root'
})
export class TipoViviendaService extends BaseService<TipoVivienda, TipoVivienda, MetadataTipoVivienda, MetadataTipoVivienda> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getClassUrl(): string {
    return 'tipo-vivienda';
  }

  getListToOptions(): Observable <any> {
    return this.http.get<any>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader()});
  }

}

import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import { Search } from '../../shared/components/search/search.component';


@Injectable({
  providedIn: 'root'
})
export class MedidorReutilizableService extends BaseService<any, any, any, any> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getClassUrl(): string {
    return 'medidores';
  }

  getMetadataResumenMedidoresReutilizables(search: Search): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/reutilizable/v1`, {params: search.params, headers: this.getHeader()});
  }

  getMedidoresEnBodega(search: Search): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/lista-medidores-en-bodega`, {params: search.params, headers: this.getHeader()});
  }

  getDetailsMedidorReutilizable(id: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/reutilizable/v1/${id}/details`, {headers: this.getHeader()});
  }

  getTiposInspeccionFisica(): Observable<any[]> {
    return this.http.get<any[]>(`${this.getUrl()}/v1/inspeccion/fisica`, {headers: this.getHeader()});
  }

  getPruebasFuncionamiento(): Observable<any[]> {
    return this.http.get<any[]>(`${this.getUrl()}/v1/prueba-funcionamiento`, {headers: this.getHeader()});
  }

  actualizarEstadoMedidorReutilizable(medidor: {medidoresIds: number[], estatusId: any, estatusAnteriorId?: any}) {
    return this.http.put<any>(`${this.getUrl()}/v1/estatus`, medidor, {headers: this.getHeader()});
  }

  actualizarMedidorReutilizable2(id: number, body: any) {
    return this.http.put<any>(`${this.getUrl()}/reutilizable/v1/${id}`, body, {headers: this.getHeader()});
  }

  actualizarLecturaInicialFenix(id: number) {
    return this.http.put<any>(`${this.getUrl()}/reutilizable/v1/${id}/lectura-inicial`, {}, {headers: this.getHeader()});
  }

  getTiposInspeccionesMedidor(gasMeterId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${gasMeterId}/tipoInspeccionMedidor`, {headers: this.getHeader() });
  }

  getPruebaFMedidor(gasMeterId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${gasMeterId}/prueba-funcionamiento-medidor`, {headers: this.getHeader() });
  }

  rechazarMedidor(medidorId: number, estatusMedidorAnterior: number) {
    return this.http.put<any>(`${this.getUrl()}/rechazar-medidor/${medidorId}/${estatusMedidorAnterior}`, {}, { headers: this.getHeader() }); 
  }

  aprobarMedidor(medidorId: number) {
    return this.http.put<any>(`${this.getUrl()}/aprobar-medidor/${medidorId}`, {}, { headers: this.getHeader() }); 
  }

  apruebaMedidorMasivamente(medidor: { medidoresIds: number[], estatusAprobacionId: any }) {
    return this.http.put<any>(`${this.getUrl()}/aprobar-medidor-masivo`, medidor, { headers: this.getHeader() });
  }

  rechazaMedidorMasivamente(medidor: { medidoresIds: number[], estatusAprobacionId: any, estatusAnteriorId?: any }) {
    return this.http.put<any>(`${this.getUrl()}/rechazar-medidor-masivo`, medidor, { headers: this.getHeader() });
  }
  

  //Reporte excel de medidores reutilizables

  getReportMedidoresR(tipoInspeccionId: string, codigoDeBarra: string, numeroDeSerie: string,
		fabricanteId: string, tipoDeMedidor: string,materialDeMedicion: string, materialDeAlmacen: string,
    motivoDesmontaje: string, lecturaFinalSap: string, desdefechaDesmontajeCampo: string, hastafechaDesmontajeCampo: string,
    desdefechaDesmontajeSistema: string, hastafechaDesmontajeSistema: string, lecturaInicialFenix: string, enviarA: string): Observable<any[]> {

		let params = new HttpParams();

		params = params.append("tipoInspeccionId", tipoInspeccionId);
		params = params.append("codigoDeBarra", codigoDeBarra);
		params = params.append("numeroDeSerie", numeroDeSerie);
		params = params.append("desdefechaDesmontajeCampo", desdefechaDesmontajeCampo);
		params = params.append("hastafechaDesmontajeCampo", hastafechaDesmontajeCampo);
		params = params.append("desdefechaDesmontajeSistema", desdefechaDesmontajeSistema);
		params = params.append("hastafechaDesmontajeSistema", hastafechaDesmontajeSistema);
		params = params.append("tipoDeMedidor", tipoDeMedidor);
		params = params.append("materialDeMedicion", materialDeMedicion);
		params = params.append("materialDeAlmacen", materialDeAlmacen);
		params = params.append("motivoDesmontaje", motivoDesmontaje);
		params = params.append("lecturaFinalSap", lecturaFinalSap);
		params = params.append("lecturaInicialFenix", lecturaInicialFenix);
		params = params.append("fabricanteId", fabricanteId);
		params = params.append("enviarA", enviarA);


		return this.http.get<any[]>(`${this.getUrl()}/reporte-medidores-reutilizables-general`, { params: params, headers: this.getHeader() });
	}

  //BODEGA MEDIDORES REUTILIZABLES

  getReportMedidoresB(tipoInspeccionId: string, codigoDeBarra: string, numeroDeSerie: string,
		fabricanteId: string, tipoDeMedidor: string,materialDeMedicion: string, nombreMotivoBaja: string,
    motivoDesmontaje: string, lecturaFinalSap: string, desdefechaEstatusMedidor: string, hastafechaEstatusMedidor: string,
    desdefechaInspeccionF: string, hastafechaInspeccionF: string, lecturaInicialFenix: string, statusMedidorId: string, enviarA: string): Observable<any[]> {

		let params = new HttpParams();

		params = params.append("tipoInspeccionId", tipoInspeccionId);
		params = params.append("codigoDeBarra", codigoDeBarra);
		params = params.append("numeroDeSerie", numeroDeSerie);
		params = params.append("desdefechaEstatusMedidor", desdefechaEstatusMedidor);
		params = params.append("hastafechaEstatusMedidor", hastafechaEstatusMedidor);
		params = params.append("desdefechaInspeccionF", desdefechaInspeccionF);
		params = params.append("hastafechaInspeccionF", hastafechaInspeccionF);
		params = params.append("tipoDeMedidor", tipoDeMedidor);
		params = params.append("materialDeMedicion", materialDeMedicion);
		params = params.append("nombreMotivoBaja", nombreMotivoBaja);
		params = params.append("motivoDesmontaje", motivoDesmontaje);
		params = params.append("lecturaFinalSap", lecturaFinalSap);
		params = params.append("lecturaInicialFenix", lecturaInicialFenix);
		params = params.append("fabricanteId", fabricanteId);
    params = params.append("statusMedidorId", statusMedidorId);
		params = params.append("enviarA", enviarA);


		return this.http.get<any[]>(`${this.getUrl()}/reporte-bodega-medidores-reutilizables-general`, { params: params, headers: this.getHeader() });
	}
}

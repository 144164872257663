import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { MetodoPago } from '../model/dto/metodo-pago';
import { MetadataMetodoPago } from '../model/match/metadata-metodo-pago';
import { HttpClient } from '@angular/common/http';
import { MetadataFormaPago } from '../model/match/metadata-forma-pago';

@Injectable({
	providedIn: 'root'
})
export class MetodoPagoService extends BaseService<MetodoPago, MetodoPago, MetadataMetodoPago, MetadataMetodoPago> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'metodo-pago';
	}

	getMetodoPago(): Observable<MetodoPago> {//pagoATravezDe
		return this.http.get<MetodoPago>(`${this.getUrl()}`, { headers: this.getHeader() });
	}

	getFormasDePago(formaDePagoId: number): Observable<MetadataFormaPago> {
		return this.http.get<MetadataFormaPago>(`${this.getUrl()}/${formaDePagoId}/formas-de-pago`, { headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Tratamiento } from '../model/dto/tratamiento';
import { MetadataTratamiento } from '../model/match/metadata-tratamiento';

@Injectable({
	providedIn: 'root'
})
export class TratamientoService extends BaseService<Tratamiento, Tratamiento, MetadataTratamiento, MetadataTratamiento> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tratamiento';
	}

	getTratamientos(id: number): Observable<MetadataTratamiento> {
		return this.http.get<MetadataTratamiento>(`${this.getUrl()}/by-tipo-persona/${id}`, { headers: this.getHeader() });
	}
}
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ObjetioSupervisor } from '../model/dto/objetivo-supervisor';
import { MetadataObjetioSupervisor } from '../model/match/metadata-tipo-objetivo-supervisor';
import { MatchCreateObjetivoSupervisor } from '../model/match/match-create-objetivo-supervisor';
import { MatchUpdateObjetivoSupervisor } from '../model/match/match-update-objetivo-supervisor';
import { Search } from '../../shared/components/search/search.component';

@Injectable({
	providedIn: 'root'
})
export class ObjetivoSupervisorService extends BaseService<ObjetioSupervisor, ObjetioSupervisor, MetadataObjetioSupervisor, MetadataObjetioSupervisor> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'objetivo-supervisor';
	}

	create(matchCreateObjetivoSupervisor: MatchCreateObjetivoSupervisor): Observable<ObjetioSupervisor> {
		return this.http.post<ObjetioSupervisor>(`${this.getUrl()}/`, matchCreateObjetivoSupervisor, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateObjetivoSupervisor: MatchUpdateObjetivoSupervisor): Observable<ObjetioSupervisor> {
		return this.http.put<ObjetioSupervisor>(`${this.getUrl()}/${id}`, matchUpdateObjetivoSupervisor, { headers: this.getHeader() });
	}

	getDetailByObjective(id: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/assign_objectives_from_supervisor/${id}`, { headers: this.getHeader() });
	}

	getDetailBySupervisor(): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/assign_objectives_by_sup`, { headers: this.getHeader() });
	}
  getMetadataResumenListSearch(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen`, { params: search.params, headers: this.getHeader() });
	}

  delete(id: number): Observable<any> {
		return this.http.delete<any>(`${this.getUrl()}/${id}/delete`, {headers: this.getHeader()});
	}
  validate(){
    return this.http.get<any>(`${this.getUrl()}/validate-date`, { headers: this.getHeader() });
  }
  getSupervisoresVentasListToOptions(): Observable<any[]> {
	return this.http.get<any[]>(`${this.getUrl()}/supervisores-ventas/list-to-options`, { headers: this.getHeader() });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { TipoServicio } from '../model/dto/tipo-servicio';
import { MetadataTipoServicio } from '../model/match/metadata-tipo-servicio';

@Injectable({
	providedIn: 'root'
})
export class TipoServicioService extends BaseService<TipoServicio, TipoServicio, MetadataTipoServicio, MetadataTipoServicio> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tipo-servicio';
	}

}
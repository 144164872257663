import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { MetadataRol } from '../model/match/metadata-rol';
import { Observable } from 'rxjs';
import { Rol } from '../model/dto/rol';
import { MatchCreateRol } from '../model/match/match-create-rol';
import { MatchUpdateRol } from '../model/match/match-update-rol';
import { Permission } from '../model/dto/permission';

@Injectable({
	providedIn: 'root'
})
export class RolService extends BaseService<Rol, Rol, MetadataRol, MetadataRol> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'role';
	}

	create(matchCreateRol: MatchCreateRol): Observable<Rol> {
		return this.http.post<Rol>(this.getUrl(), matchCreateRol, { headers: this.getHeader() });
	}

	update(id: number, matchUpdateRol: MatchUpdateRol): Observable<Rol> {
		return this.http.put<Rol>(`${this.getUrl()}/${id}`, matchUpdateRol, { headers: this.getHeader() });
	}

	getPermissionsToTree(id: number): Observable<Permission[]> {
		return this.http.get<Permission[]>(`${this.getUrl()}/${id}/permissions-to-tree`, { headers: this.getHeader() });
	}

	getAvailablesByRoleType(roleTypeId: number): Observable<Rol[]> {
		return this.http.get<Rol[]>(`${this.getUrl()}/availables-by-role-type/${roleTypeId}`, {headers: this.getHeader()});
	}

}
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BaseService} from './base.service';
import {Cliente} from '../model/dto/cliente';
import {ResumenCliente} from '../model/dto/resumen/resumen-cliente';
import {ResumenDetailCliente} from '../model/dto/resumen/resumen-detail-cliente';
import {ResumenDireccionClienteToCliente} from '../model/dto/resumen/resumen-direccion-cliente-to-cliente';
import {Observable} from 'rxjs';
import {MetadataResumenCliente} from '../model/match/metadata-resumen-cliente';
import {MetadataCliente} from '../model/match/metadata-cliente';
import {MatchCreateCliente} from '../model/match/match-create-cliente';
import {MatchUpdateDireccion} from '../model/match/match-update-direccion';
import {MatchUpdateCliente} from '../model/match/match-update-cliente';
import {MatchCreateDireccion} from '../model/match/match-create-direccion';
import {Search} from '../../shared/components/search/search.component';
import {MatchDesactiveCliente} from '../model/match/match-desactive-cliente';
import {ResumenNegocio} from '../model/dto/resumen/resumen-negocio';
import {MatchUpdateDatosValidacion} from '../model/match/match-update-datos-validacion';
import {ResumenNegocioToDetailCliente} from '../model/dto/resumen/resumen-negocio-to-detail-cliente';
import {ResumenContratoToDetailCliente} from '../model/dto/resumen/resumen-contrato-to-detail-cliente';
import {ResumenServiceToDetailCliente} from '../model/dto/resumen/resumen-service-to-detail-cliente';
import {ResumenValidacionRfc} from '../model/dto/resumen/resumen-validacion-rfc';
import {MatchCodeValidationInfo} from '../model/match/match-code-validation-info';

@Injectable({
	providedIn: 'root'
})
export class ClienteService extends BaseService<Cliente, ResumenCliente, MetadataCliente, MetadataResumenCliente> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'cliente';
	}

	update(id: number, matchUpdateCliente: MatchUpdateCliente): Observable<Cliente> {
		return this.http.put<Cliente>(`${this.getUrl()}/${id}`, matchUpdateCliente, {headers: this.getHeader()});
	}

	updateNoBP(id: number, matchUpdateCliente: MatchUpdateCliente): Observable<Cliente> {
		return this.http.put<Cliente>(`${this.getUrl()}/${id}/admin-ventas-no-bp`, matchUpdateCliente, {headers: this.getHeader()});
	}

	asignarInstaladoraADireccion(id: number, direccionId: number, instaladoraId: number): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}/address/${direccionId}/assign-instaladora/${instaladoraId}`, null, {headers: this.getHeader()});
	}

	getResumenWithoutNegocioVigente(search: Search): Observable<MetadataResumenCliente> {
		return this.http.get<MetadataResumenCliente>(`${this.getUrl()}/resumen/without-negocio-vigente`, {params: search.params, headers: this.getHeader()});
	}

	desactivarCliente(id: number, matchUpdateCliente: MatchDesactiveCliente): Observable<Cliente> {
		return this.http.put<Cliente>(`${this.getUrl()}/${id}/desactivate`, matchUpdateCliente, {headers: this.getHeader()});
	}

	activarCliente(id: number): Observable<any> {
		return this.http.put<Cliente>(`${this.getUrl()}/${id}/activar-cliente`, null, {headers: this.getHeader()});
	}

	updateDireccion(id: number,direccionClienteId: number, matchUpdateDireccion: MatchUpdateDireccion): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}/address/${direccionClienteId}`, matchUpdateDireccion, {headers: this.getHeader()});
	}

	updateDeposito(id: number,direccionClienteId: number, matchUpdateDireccion: MatchUpdateDireccion): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}/address-deposit/${direccionClienteId}`, matchUpdateDireccion, {headers: this.getHeader()});
	}

	//EN CONTRATO
	updateDireccionToContrato(id: number,direccionClienteId: number, matchUpdateDireccion: MatchUpdateDireccion): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/admin-ventas/${id}/address/${direccionClienteId}`, matchUpdateDireccion, {headers: this.getHeader()});
	}

	//EN CONTRATO
	updateDatosValidacionToContrato(id: number,direccionClienteId: number, matchUpdateDatosValidacion: MatchUpdateDatosValidacion): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/admin-ventas/${id}/datos-validacion/${direccionClienteId}`, matchUpdateDatosValidacion, {headers: this.getHeader()});
	}

	deleteDireccion(id: number,direccionClienteId: number): Observable<Cliente> {
		return this.http.delete<Cliente>(`${this.getUrl()}/${id}/address/${direccionClienteId}`, {headers: this.getHeader()});
	}

	getDireccionesClienteResumenToCliente(clienteId: number): Observable<ResumenDireccionClienteToCliente[]> {
		return this.http.get<ResumenDireccionClienteToCliente[]>(`${this.getUrl()}/${clienteId}/addresses`, {headers: this.getHeader()});
	}

	getDireccionesClienteResumenToClienteTecnicas(clienteId: number): Observable<ResumenDireccionClienteToCliente[]> {
		return this.http.get<ResumenDireccionClienteToCliente[]>(`${this.getUrl()}/${clienteId}/addresses-tecnicas`, {headers: this.getHeader()});
	}

	getAddressesTecnicasAvailablesToAssignInstaladora(clienteId: number): Observable<ResumenDireccionClienteToCliente[]> {
		return this.http.get<ResumenDireccionClienteToCliente[]>(`${this.getUrl()}/${clienteId}/addresses-tecnicas-availables-to-assign-instaladora`, {headers: this.getHeader()});
	}

	getAddressesTecnicasAvailablesToAssignVendedor(clienteId: number): Observable<ResumenDireccionClienteToCliente[]> {
		return this.http.get<ResumenDireccionClienteToCliente[]>(`${this.getUrl()}/${clienteId}/addresses-tecnicas-availables-to-assign-vendedor`, {headers: this.getHeader()});
	}

	getDireccionesClienteResumenToClienteComerciales(clienteId: number): Observable<ResumenDireccionClienteToCliente[]> {
		return this.http.get<ResumenDireccionClienteToCliente[]>(`${this.getUrl()}/${clienteId}/addresses-comerciales`, {headers: this.getHeader()});
	}

	getResumenDetailCliente(clienteId: number): Observable<ResumenDetailCliente> {
		return this.http.get<ResumenDetailCliente>(`${this.getUrl()}/${clienteId}/resumen-detail`, {headers: this.getHeader()});
	}

	createDireccion(clienteId: number, matchCreateDireccion: MatchCreateDireccion): Observable<any> {
		return this.http.post<any>(`${this.getUrl()}/${clienteId}/addresses`, matchCreateDireccion, {headers: this.getHeader()});
	}

	create(matchCreateCliente: MatchCreateCliente): Observable<Cliente> {
		return this.http.post<Cliente>(this.getUrl(), matchCreateCliente, {headers: this.getHeader()});
	}

	getAvailablesToAssignInstaladora(search: Search): Observable<MetadataResumenCliente> {
		return this.http.get<MetadataResumenCliente>(`${this.getUrl()}/resumen/availables-to-assign-instaladora`, {params: search.params, headers: this.getHeader()});
	}

	getNegocioSinVendedorAsociado(clienteId: number): Observable<ResumenNegocio[]> {
		return this.http.get<ResumenNegocio[]>(`${this.getUrl()}/${clienteId}/negocios-sin-vendedor-asociado`, {headers: this.getHeader()});
	}

	getResumenDetailDeNegocioEnCliente(clienteId: number, negocioId: number): Observable<ResumenNegocioToDetailCliente> {
		return this.http.get<ResumenNegocioToDetailCliente>(`${this.getUrl()}/${clienteId}/resumen-detail-negocio/${negocioId}`, {headers: this.getHeader()});
	}

	getResumenDetailDeContratoEnCliente(clienteId: number, contratoId: number): Observable<ResumenContratoToDetailCliente> {
		return this.http.get<ResumenContratoToDetailCliente>(`${this.getUrl()}/${clienteId}/resumen-detail-contrato/${contratoId}`, {headers: this.getHeader()});
	}

	getResumenDetailDeServicioEnCliente(clienteId: number, serviceId: number): Observable<ResumenServiceToDetailCliente> {
		return this.http.get<ResumenServiceToDetailCliente>(`${this.getUrl()}/${clienteId}/resumen-detail-service/${serviceId}`, {headers: this.getHeader()});
	}

	getGarantiaInfo(id: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${id}/garantia-info`, { headers: this.getHeader() });
	}

  validateRFC(rfc: string, clientId: number = null): Observable<ResumenValidacionRfc> {
    const request = {id: clientId, rfc: rfc};
    return this.http.post<ResumenValidacionRfc>(`${this.getUrl()}/validate-cliente-rfc`, request, {headers: this.getHeader()});
  }

	generateCode(matchCodeValidationInfo: MatchCodeValidationInfo, captcha: string): Observable<any> {
		let headers = new HttpHeaders({
      'jwt': localStorage.getItem("eTag"),
			'captcha': captcha
		});
		return this.http.post(`${this.getUrl()}/generate-code`, matchCodeValidationInfo, {headers: headers, responseType: 'text'});
	}

	validateCode(matchCodeValidationInfo: MatchCodeValidationInfo): Observable<any> {
		return this.http.post(`${this.getUrl()}/validate-code`, matchCodeValidationInfo, {headers: this.getHeader()});
	}

	getMetadataDireccionesCliente(id: number, search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/direcciones/${id}`, { params: search.params, headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import { MetadataPermission } from '../../api/model/match/metadata-permission';
import { MetadataResumenPermission } from '../../api/model/match/metadata-resumen-permission';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Permission } from '../../api/model/dto/permission';
import { BaseService } from './base.service';
import { ResumenPermission } from '../model/dto/resumen/resumen-permission';
import { Rol } from '../model/dto/rol';
@Injectable({
	providedIn: 'root'
})
export class PermissionService extends BaseService<Permission, ResumenPermission, MetadataPermission, MetadataResumenPermission>{

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'permission';
	}

	getRolesPermissionResumenToPermission(permissionId: number): Observable<Rol[]> {
		return this.http.get<Rol[]>(`${this.getUrl()}/${permissionId}/roles`, {headers: this.getHeader()});
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { TipoDireccion } from '../model/dto/tipo-direccion';
import { MetadataTipoDireccion } from '../model/match/metadata-tipo-direccion';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class TipoDireccionService extends BaseService<TipoDireccion, TipoDireccion, MetadataTipoDireccion, MetadataTipoDireccion> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'address-type';
	}
}

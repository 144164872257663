import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { FormaPago } from '../model/dto/forma-pago';
import { MetadataFormaPago } from '../model/match/metadata-forma-pago';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class FormaPagoService extends BaseService<FormaPago, FormaPago, MetadataFormaPago, MetadataFormaPago> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'forma-pago';
	}
	getFormaPago(): Observable<FormaPago> {
		return this.http.get<FormaPago>(`${this.getUrl()}`, { headers: this.getHeader() });
	}
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataService } from '../model/match/metadata-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '../../shared/components/search/search.component';
import { Microzona } from '../model/dto/microzona';
import { MetadataMicrozona } from '../model/match/metadata-microzona';
import { MatchCreateMicrozona } from '../model/match/match-create-microzona';
import { MatchUpdateMicrozona } from '../model/match/match-update-microzona';
import { MetadataResumenVendedorToPersistRuta } from '../model/match/metadata-resumen-vendedor-to-persist-ruta';

@Injectable({
	providedIn: 'root'
})
export class MicrozonaService extends BaseService<Microzona, Microzona, MetadataMicrozona, MetadataMicrozona> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'microzona';
	}

	update(id: number, matchUpdateMicrozona: MatchUpdateMicrozona): Observable<any> {
		return this.http.put<any>(`${this.getUrl()}/${id}`, matchUpdateMicrozona, { headers: this.getHeader() });
	}

	delete(microzonaId: number): Observable<any> {
		return this.http.delete<any>(`${this.getUrl()}/${microzonaId}`, { headers: this.getHeader() });
	}

	getListToOptions(): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader() });
	}

	getRutasParaDibujar(microzonaId: number): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/${microzonaId}/rutas-para-dibujar`, { headers: this.getHeader() });
	}

	getMetadataVendedoresPersistRuta(microzonaId: number, search: Search): Observable<MetadataResumenVendedorToPersistRuta> {
		return this.http.get<MetadataResumenVendedorToPersistRuta>(`${this.getUrl()}/${microzonaId}/vendedores-resumen-to-persist-ruta/exclude/-`, { params: search.params, headers: this.getHeader() });
	}

	getMetadataResumenListSearchV2(search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/resumen/v2`, { params: search.params, headers: this.getHeader() });
	}

	crearMicrozona(matchCreateMicrozona: MatchCreateMicrozona): Observable<any> {
		return this.http.post<any>(`${this.getUrl()}`, matchCreateMicrozona, { headers: this.getHeader() });
	}

	getDetalleMicrozona(microzonaId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${microzonaId}/detalle-microzona`, { headers: this.getHeader() });
	}

	getDetalleMicrozonaResumenToCreateRuta(microzonaId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${microzonaId}/detalle-microzona/resumen-to-create-ruta`, { headers: this.getHeader() });
	}

	getVendedoresDetalleMicrozona(microzonaId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${microzonaId}/detalle-microzona/vendedores`, { headers: this.getHeader() });
	}

	getDetalleMicrozonaInfoMonitoreoOperativo(microzonaId: number): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${microzonaId}/detalle-microzona-monitoreo/operativo`, { headers: this.getHeader() });
	}

  getDetalleMicrozonaInfoMonitoreoComercial(microzonaId: number): Observable<any> {
    return this.http.get<any>(`${this.getUrl()}/${microzonaId}/detalle-microzona-monitoreo/comercial`, { headers: this.getHeader() });
  }

	getMetadataResumenListVendedoresMicrozona(microzonaId: number, search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${microzonaId}/list-vendedores`, { params: search.params, headers: this.getHeader() });
	}

	getCounterPoints(microzonaId, regionalId): Observable<any> {
		let params = new HttpParams();

		params = params.append('regional', regionalId);
		return this.http.get<any>(`${this.getUrl()}/${microzonaId}/map/show/all_counts`, {params: params, headers: this.getHeader() });
	}

	getHistorialMicrozonaListSearch(microzonaId: number, search: Search): Observable<any> {
		return this.http.get<any>(`${this.getUrl()}/${microzonaId}/historial-ediciones`, { params: search.params, headers: this.getHeader() });
	}
}

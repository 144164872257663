import { Injectable } from '@angular/core';
import { MetadataPermissionGroup } from '../../api/model/match/metadata-permission-group';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PermissionGroup } from '../../api/model/dto/permission-group';
import { BaseService } from './base.service';
import { Permission } from '../model/dto/permission';
import { ResumenPermissionToBuildTree } from '../model/dto/resumen/resumen-permission-to-build-tree';

@Injectable({
	providedIn: 'root'
})
export class PermissionGroupService extends BaseService<PermissionGroup, PermissionGroup, MetadataPermissionGroup, MetadataPermissionGroup> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'permission-group';
	}

	getAvailablesByRoleType(roleTypeId: number): Observable<PermissionGroup[]> {
		return this.http.get<PermissionGroup[]>(`${this.getUrl()}/availables-by-role-type/${roleTypeId}`, {headers: this.getHeader()});
	}
	  
	getPermissionsToBuildTreeByRoleType(id: number, roleTypeId: number): Observable<ResumenPermissionToBuildTree> {
		return this.http.get<ResumenPermissionToBuildTree>(`${this.getUrl()}/${id}/permissions-to-build-tree-by-role-type/${roleTypeId}`, {headers: this.getHeader()});
	}
}
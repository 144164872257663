import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SolicitudAcometida } from '../model/dto/solicitud-acometida';
import { MetadataSolicitudAcometida } from '../model/match/metadata-solicitud-acometida';

@Injectable({
  providedIn: 'root'
})
export class StatusSolicitudAcometidaService extends BaseService<SolicitudAcometida, SolicitudAcometida, MetadataSolicitudAcometida, MetadataSolicitudAcometida> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'status-solicitud-acometida';
	}

	getListToOptions(): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader() });
	}
}

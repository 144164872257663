import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Citygate } from '../model/dto/citygate';
import { MetadataCitygate } from '../model/match/metadata-citygate';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CitygateService extends BaseService<Citygate, Citygate, MetadataCitygate, MetadataCitygate> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'citygate';
	}

}

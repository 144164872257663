import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Status } from '../model/dto/status';
import { MetadataStatus } from '../model/match/metadata-status';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatusService extends BaseService<Status, Status, MetadataStatus, MetadataStatus> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getClassUrl(): string {
    return 'status';
  }
}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MetadataService } from '../model/match/metadata-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Search } from '../../shared/components/search/search.component';
import { Adeudo } from '../model/dto/adeudo';
import { MetadataAdeudo } from '../model/match/metadata-adeudo';

@Injectable({
	providedIn: 'root'
})
export class AdeudoService extends BaseService<Adeudo, Adeudo, MetadataAdeudo, MetadataAdeudo> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'adeudo';
	}

	getPointsToMap(longitud: string, latitud: string): Observable<any> {
		let params = new HttpParams();

		params = params.append("latitudCenter", latitud);
		params = params.append("longitudCenter", longitud);

		return this.http.get<any>(`${this.getUrl()}/map/show`, { params: params, headers: this.getHeader() });
	}

	getAllPointsToMap(): Observable<any> {
	  return this.http.get<any>(`${this.getUrl()}/map/show/all`, {headers: this.getHeader()});
  }
}

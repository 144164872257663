import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TipoAcometida } from '../model/dto/tipo-acometida';
import { MetadataTipoAcometida } from '../model/match/metadata-tipo-acometida';

@Injectable({
  providedIn: 'root'
})
export class TipoAcometidaService extends BaseService<TipoAcometida, TipoAcometida, MetadataTipoAcometida, MetadataTipoAcometida> {

	constructor(protected http: HttpClient) {
		super(http);
	}

	getClassUrl(): string {
		return 'tipo-acometida';
	}

	getListToOptions(): Observable<any[]> {
		return this.http.get<any[]>(`${this.getUrl()}/list-to-options`, { headers: this.getHeader() });
	}
}
